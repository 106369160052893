import { Button, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useGroupsContext } from '../../../context/GroupsContext';
import { useToastContext } from '../../../context/ToastContext';
import { addIdeasToGroup } from '../../../utils/api';
import styles from './MultiAddToGroupDialog.module.css';

interface MultiAddToGroupDialogProps {
  selectedIdeaIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function MultiAddToGroupDialog({ selectedIdeaIds, isOpen, onClose, onSubmit }: MultiAddToGroupDialogProps) {
  const { addToast } = useToastContext();
  const { groups, fetchGroups } = useGroupsContext();

  const [ideaIds, setIdeaIds] = useState<string[]>([]);

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedGroup = groups.find((g) => g.id === selectedGroupId);

  useEffect(() => {
    if (isOpen) {
      // Reset selected group ID and idea IDs when opening the dialog
      setSelectedGroupId(null);
      setIdeaIds(selectedIdeaIds);
    }
  }, [isOpen]);

  const addSelectedIdeasToGroup = async () => {
    if (!selectedGroup) return;

    setIsSubmitting(true);

    try {
      await addIdeasToGroup(selectedGroup.id, ideaIds);
      await fetchGroups();
      addToast({
        kind: 'success',
        title: <>{ideaIds.length} {ideaIds.length === 1 ? 'idea' : 'ideas'} have been added to group <em>{selectedGroup.name}</em></>,
      });
      onSubmit();
      onClose();
    } catch {
      addToast({
        kind: 'error',
        title: <>Failed to add {ideaIds.length} {ideaIds.length === 1 ? 'idea' : 'ideas'} to group <em>{selectedGroup.name}</em></>,
        message: 'Something went wrong, please try again later'
      });
    }

    setIsSubmitting(false);
  };

  if (!groups) return null;

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.multiAddToGroupDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title={`Add ${ideaIds.length} ${ideaIds.length === 1 ? 'idea' : 'ideas'} to a group`} />
      <ModalBody>
        <RadioButtonGroup
          name="tag-radio-group"
          onChange={(groupId: string) => setSelectedGroupId(groupId)}
          orientation="vertical"
          valueSelected={selectedGroupId || undefined}
        >
          {groups.map((group) => (
            <RadioButton
              key={group.id}
              labelText={group.name}
              value={group.id}
              id={group.id}
            />
          ))}
        </RadioButtonGroup>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" disabled={isSubmitting} onClick={onClose}>Cancel</Button>
        <div className={styles.submitContainer}>
          {isSubmitting ? (
            <InlineLoading
              className={styles.submitLoading}
              description={ideaIds.length === 1 ? 'Adding idea to group...' : `Adding ${ideaIds.length} ideas to group...`}
            />
          ) : (
            <Button
              kind="primary"
              onClick={addSelectedIdeasToGroup}
              disabled={selectedGroupId === null}
            >
              {ideaIds.length === 1 ? 'Add idea to group' : `Add ${ideaIds.length} ideas to group`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default MultiAddToGroupDialog;
