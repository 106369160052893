type ManagedTagSet = {
  key: string;
  label: string;
  prefix: string;
  removePrefix: boolean;
};

type NewManagedTagSet = ManagedTagSet & {
  tags: string[];
  items: ManagedTagItem[];
};

export type ManagedTagItem = {
  key: string;
  label: string;
};

export const MANAGED_TAG_SETS: ManagedTagSet[] = [
  { key: 'blocker', label: 'Blocker Tags', prefix: 'Blocker',  removePrefix: false },
  { key: 'market',  label: 'Market Tags',  prefix: 'Market: ', removePrefix: true },
  { key: 'type',    label: 'Type Tags',    prefix: 'Type: ',   removePrefix: true },
  { key: 'source',  label: 'Source Tags',  prefix: 'Source: ', removePrefix: true },
];

export const FINAL_BIG_10_TAG: string = 'Final Big 10';

export const filterManagedTags = (managedTags: string[], prefix: string) => managedTags.filter((tag) => tag.startsWith(prefix));

const createTagItem = (managedTagSet: ManagedTagSet, tag: string): ManagedTagItem => ({
  key: tag,
  label: managedTagSet.removePrefix
    ? tag.replace(managedTagSet.prefix, '')
    : tag,
});

export const createItemList = (
  tags: string[],
  managedTagSet: ManagedTagSet
) => tags.map((tag) => createTagItem(managedTagSet, tag));

export const createNewTagList = (previousTags: string[], newSelectedTags: string[], allTags: string[]) => {
  const tagList = previousTags.filter((tag) => !allTags.includes(tag));
  return tagList.concat(newSelectedTags);
};

export const getFilteredTagSet = (managedTags: string[]): NewManagedTagSet[] => MANAGED_TAG_SETS.map((managedTagSet) => {
  const tagSet: NewManagedTagSet = {
    ...managedTagSet,
    tags: [],
    items: [],
  };
  tagSet.tags = filterManagedTags(managedTags, managedTagSet.prefix);
  tagSet.items = createItemList(tagSet.tags, managedTagSet);
  return tagSet;
});
