import { Close } from '@carbon/icons-react';
import { Button, InlineLoading } from 'carbon-components-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useGroupsContext } from '../../../context/GroupsContext';
import { useToastContext } from '../../../context/ToastContext';
import { useUserContext } from '../../../context/UserContext';
import { Group } from '../../../models/group';
import { removeGroupMember } from '../../../utils/api';
import { formatDate } from '../../../utils/dates';
import styles from './GroupsTable.module.css';

function GroupsTable() {
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const { isLoadingGroups, isLoadingGroupsSuccess, groups, fetchGroups } = useGroupsContext();

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [isLeaveGroupDialogOpen, setIsLeaveGroupDialogOpen] = useState(false);
  const selectedGroup = useMemo(() => groups.find((group) => group.id === selectedGroupId), [groups, selectedGroupId]);

  if (isLoadingGroups) {
    return <InlineLoading description="Loading groups..." />;
  }

  if (!isLoadingGroupsSuccess) {
    return <InlineLoading description="Failed to load groups" status="error" />;
  }

  if (groups.length === 0) {
    return <p>You are not a member of any groups</p>;
  }

  const checkIfLastAdmin = (group: Group) => {
    if (!group) return true;

    const isLastAdminInGroup = ((group?.is_admin && group?.admin_count === 1) || false);

    return isLastAdminInGroup;
  };

  const onLeaveGroup = async () => {
    if (!selectedGroupId || !user) return;

    try {
      await removeGroupMember(selectedGroupId, user.email);
      await fetchGroups();
    } catch (error) {
      addToast({
        kind: 'error',
        title: 'Failed leaving group',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isLeaveGroupDialogOpen}
        onClose={() => setIsLeaveGroupDialogOpen(false)}
        onSubmit={onLeaveGroup}
        message={`Are you sure you want to leave '${selectedGroup?.name}'?`}
        submitButtonText="Leave Group"
      />
      <table className={styles.groupsTable}>
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Creator</th>
            <th>Creation Date</th>
            <th>Ideas</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => (
            <tr key={group.id}>
              <td>
                <Link to={`/groups/${group.id}`}>
                  {group.name}
                </Link>
              </td>
              <td>{group.creator}</td>
              <td>{formatDate(group.creation_date)}</td>
              <td className={styles.ideaCount}>{group.idea_count}</td>
              <td>
                <span className={checkIfLastAdmin(group) ? 'bx--tooltip__trigger bx--btn--icon-only--right disabledButton' : ''} aria-label="Cannot remove last admin">
                  <Button
                    className={styles.leaveGroupBtn}
                    onClick={() => { setSelectedGroupId(group.id); setIsLeaveGroupDialogOpen(true); }}
                    size="sm"
                    kind="ghost"
                    renderIcon={Close}
                    iconDescription="Leave Group"
                    hasIconOnly
                    disabled={checkIfLastAdmin(group)}
                    tooltipPosition="right"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default GroupsTable;
