const MONTH_NAMES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function getMonthName(monthIndex: number): string {
  return MONTH_NAMES_SHORT[monthIndex];
}

export function formatDate(oldDateStr: string): string {
  let includeDay = true;

  if (/^\d\d\d\d-\d\d$/.test(oldDateStr)) {
    includeDay = false;
  }

  const date = new Date(oldDateStr);

  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();

  if (includeDay) {
    const day = date.getDate();
    return `${day} ${month} ${year}`;
  }
  return `${month} ${year}`;
}
export function formatTime(oldTimeStr: string): string {
  const time = new Date(oldTimeStr);
  const newTime = time.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

  return newTime;
}
export function formatDateTime(oldDateStr: string): string {
  return `${formatDate(oldDateStr)} ${formatTime(oldDateStr)}`;
}

export function formatTimeWithTimeZone(oldDateStr: string) : string {
  return new Date(oldDateStr).toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });
}

export function compareDateTimeToCurrent(oldDateStr: string): boolean {
  return new Date() < new Date(formatDateTime(oldDateStr));
}

export function formatDateRange(startDate: string, endDate: string): string {
  // Single month
  if (startDate === endDate) {
    return formatDate(startDate);
  }

  // Full year
  const startYear = startDate.split('-')[0];

  if (startDate === `${startYear}-01` && endDate === `${startYear}-12`) {
    return startYear;
  }

  // Otherwise
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export function startOfMonth(yearMonth: string): string {
  return `${yearMonth}-01`;
}

export function startOfNextMonth(yearMonth: string): string {
  const [yearStr, monthStr] = yearMonth.split('-');
  let year = parseInt(yearStr, 10);
  let month = parseInt(monthStr, 10);

  if (month === 12) {
    month = 1;
    year += 1;
  } else {
    month += 1;
  }

  const nextMonthStr = month.toString().padStart(2, '0');
  return `${year}-${nextMonthStr}-01`;
}

export function currentYearMonth(): string {
  return new Date().toISOString().substring(0, 7);
}
