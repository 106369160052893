import { Editor, EditorConfig, DataApiMixin, ElementApiMixin, attachToForm } from '@ckeditor/ckeditor5-core';
import { getDataFromElement } from '@ckeditor/ckeditor5-utils';

import CarbonEditorUI from './CarbonEditorUI';

interface CarbonEditorConfig extends EditorConfig {
  uiElement: HTMLElement;
}

// Extending the Editor class, which brings the base editor API.
export default class CarbonEditor extends DataApiMixin(ElementApiMixin(Editor)) {
  sourceElement: HTMLElement;

  ui: CarbonEditorUI;

  constructor(element: HTMLElement, config: CarbonEditorConfig) {
    super(config);

    // Remember the element the editor is created with.
    this.sourceElement = element;

    // Create the ("main") root element of the model tree.
    this.model.document.createRoot();

    // The UI layer of the editor.
    this.ui = new CarbonEditorUI(this);

    // When editor#element is a textarea inside a form element,
    // the content of this textarea will be updated on form submit.
    attachToForm(this);
  }

  destroy() {
    // When destroyed, the editor sets the output of editor#getData() into editor#element...
    // TODO: Figure out what this does
    // this.updateSourceElement();

    // ...and destroys the UI.
    this.ui.destroy();

    return super.destroy();
  }

  static create(element: HTMLElement, config: CarbonEditorConfig) {
    return new Promise<Editor>((resolve) => {
      const editor = new this(element, config);

      resolve(
        editor.initPlugins()
        // Initialize the UI first. See the CarbonEditorUI class to learn more.
          .then(() => editor.ui.init(element))
        // Fill the editable with the initial data.
          .then(() => editor.data.init(getDataFromElement(element)))
        // Fire the `editor#ready` event that announce the editor is complete and ready to use.
          .then(() => editor.fire('ready'))
          .then(() => editor)
      );
    });
  }
}
