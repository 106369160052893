import { createContext, useContext, useMemo, useState } from 'react';
import ToastContainer from '../components/ToastContainer/ToastContainer';
import { Toast } from '../models/toast';

interface ToastContextInterface {
  addToast: (toast: Toast) => void;
}

const ToastContext = createContext<ToastContextInterface | null>(null);

export function useToastContext() {
  return useContext(ToastContext)!;
}

interface ToastProviderProps {
  children: React.ReactNode
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (toast: Toast) => {
    const toastId = new Date().valueOf();

    setToasts((prevToasts) => [...prevToasts, {
      ...toast,
      id: toastId
    }]);

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toastId));
    }, 5000);
  };

  const toastContext: ToastContextInterface = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={toastContext}>
      {children}
      <ToastContainer toasts={toasts} />
    </ToastContext.Provider>
  );
}

export default ToastContext;
