import { useEffect, useState } from 'react';
import { Button, InlineLoading } from 'carbon-components-react';
import { Checkmark, Close } from '@carbon/icons-react';
import { useGroupsContext } from '../../../../context/GroupsContext';
import { useUserContext } from '../../../../context/UserContext';
import styles from './GroupMemberRequestsTable.module.css';
import { addGroupMember, getGroupMemberRequests, rejectGroupMemberRequest } from '../../../../utils/api';
import { GroupMember, GroupMemberRequest } from '../../../../models/groupMember';
import { useToastContext } from '../../../../context/ToastContext';

interface GroupMemberRequestRowProps {
  groupId: string;
  groupMember: GroupMemberRequest;
  onApprove: (newGroupMember: GroupMember) => void;
  onReject: () => void;
}

function GroupMemberRequestRow({ groupId, groupMember, onApprove, onReject }: GroupMemberRequestRowProps) {
  const { addToast } = useToastContext();
  const [isLoading, setIsLoading] = useState(false);

  const approve = async () => {
    const newGroupMember: GroupMember = {
      email: groupMember.email.trim().toLowerCase(),
      group_admin: false
    };

    setIsLoading(true);
    try {
      await addGroupMember(groupId, newGroupMember);
      onApprove(newGroupMember);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to approve group member request',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsLoading(false);
  };

  const reject = async () => {
    setIsLoading(true);
    try {
      await rejectGroupMemberRequest(groupId, groupMember.email);
      onReject();
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to reject group member request',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsLoading(false);
  };

  return (
    <tr key={groupMember.email}>
      <td>{groupMember.email}</td>
      <td>
        <Button
          onClick={() => approve()}
          hasIconOnly
          iconDescription="Approve Request"
          renderIcon={Checkmark}
          kind="ghost"
          size="sm"
          disabled={isLoading}
        />
      </td>
      <td>
        <Button
          onClick={() => reject()}
          hasIconOnly
          iconDescription="Reject Request"
          renderIcon={Close}
          kind="ghost"
          size="sm"
          disabled={isLoading}
        />
      </td>
    </tr>
  );
}

interface GroupMemberRequestsTableProps {
  onApprove: (newMember: GroupMember) => void;
}

function GroupMemberRequestsTable({ onApprove }: GroupMemberRequestsTableProps) {
  const { user } = useUserContext();
  const { currentGroup, decrementMemberRequestCount } = useGroupsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [groupMemberRequests, setGroupMemberRequests] = useState<GroupMemberRequest[]>([]);

  useEffect(() => {
    if (!currentGroup) return;

    const fetchMemberRequests = async () => {
      setIsLoading(true);
      const { data } = await getGroupMemberRequests(currentGroup.id);
      setGroupMemberRequests(data);
      setIsLoading(false);
    };

    fetchMemberRequests();
  }, [currentGroup?.id]);

  if (!user) return null;
  if (!currentGroup) return null;

  if (isLoading) {
    return <InlineLoading description="Loading group member requests..." />;
  }

  if (groupMemberRequests.length === 0) {
    return <p>There are no group member requests</p>;
  }

  const removeRequest = (email: string) => {
    setGroupMemberRequests((prevRequests) => prevRequests.filter((request) => request.email !== email));
    decrementMemberRequestCount();
  };

  return (
    <table className={styles.membersTable}>
      <thead>
        <tr>
          <th>User</th>
          <th>Approve</th>
          <th>Reject</th>
        </tr>
      </thead>
      <tbody>
        {groupMemberRequests.map((groupMember) => (
          <GroupMemberRequestRow
            groupId={currentGroup.id}
            groupMember={groupMember}
            onApprove={(newGroupMember) => { removeRequest(groupMember.email); onApprove(newGroupMember); }}
            onReject={() => removeRequest(groupMember.email)}
          />
        ))}
      </tbody>
    </table>
  );
}

export default GroupMemberRequestsTable;
