import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { ThumbsUpFilled, ThumbsUp } from '@carbon/icons-react';
import { ComposedModal, DataTable, DataTableCell, ModalBody, ModalHeader, Table, TableBody, TableCarbonProps, TableCell, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent, TableToolbarSearch, TooltipDefinition } from 'carbon-components-react';
import styles from './Voters.module.css';
import { IdeaVote } from '../../models/idea';
import { formatDate } from '../../utils/dates';

interface VotersProps {
  isOpen: boolean;
  onClose: () => void;
  voteDetails: any;
}

interface DataTableVote extends IdeaVote {
  id: string;
  date: string;
  cells?: DataTableCell[];
}

interface DataProps {
  rows: DataTableVote[];
  headers: { key: string; header: string; }[];
  getTableProps(): TableCarbonProps;
  getHeaderProps: any;
  getRowProps: any;
  onInputChange: () => void;
}

const headerData = [
  {
    key: 'user_name',
    header: 'Created by',
  },
  {
    key: 'user_email',
    header: 'Email',
  },
  {
    key: 'date',
    header: 'Voted On',
  },
];

const modalHeaders = ['Total Votes', 'IBM Votes', 'Non IBM Votes'];

function Voters({ isOpen, onClose, voteDetails }: VotersProps) {
  const [voters, setVoters] = useState<IdeaVote[]>(voteDetails.voters);
  const [voterContext, setVoterContext] = useState(modalHeaders[0]);

  const updateVoters = () => {
    if (voterContext === modalHeaders[1]) {
      const filteredVotes = voteDetails.voters.filter((vote: IdeaVote) => vote.ibm_employee);
      setVoters(filteredVotes);
    } else if (voterContext === modalHeaders[2]) {
      const filteredVotes = voteDetails.voters.filter((vote: IdeaVote) => !vote.ibm_employee);
      setVoters(filteredVotes);
    } else {
      setVoters(voteDetails.voters);
    }
  };

  useEffect(() => {
    updateVoters();
  }, [voterContext, voteDetails.voters]);

  const onAllVotesClick = () => {
    setVoterContext(modalHeaders[0]);
  };

  const onIBMVotesClick = () => {
    setVoterContext(modalHeaders[1]);
  };

  const onNonIBMVotesClick = () => {
    setVoterContext(modalHeaders[2]);
  };

  const formatVotes = (votes: IdeaVote[]) : DataTableVote[] => {
    const formattedVotes = [];
    for (const vote of votes) {
      const formattedVote = { ...vote, date: formatDate(vote.vote_created_at) };
      formattedVotes.push(formattedVote);
    }
    return formattedVotes;
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.Voters}
      open={isOpen}
      onClose={() => onClose()}
      size="lg"
    >
      <ModalHeader title="Votes" />
      <ModalBody>
        <div className={styles.voteDetails}>
          <div className={`${styles.totalVotes} ${voterContext === modalHeaders[0] ? styles.activeBtn : styles.votersBtns}`}>
            <button
              type="button"
              className={styles.votersBtns}
              onClick={onAllVotesClick}
            >
              <h5>{voteDetails.voteCount}</h5>
              <h5>{modalHeaders[0]}</h5>
            </button>
          </div>
          <div className={`${styles.ibmVotes} ${voterContext === modalHeaders[1] ? styles.activeBtn : styles.votersBtns}`}>
            <button
              type="button"
              className={styles.votersBtns}
              onClick={onIBMVotesClick}
            >
              <h5>{voteDetails.ibmVoteCount}</h5>
              <h5>{modalHeaders[1]}</h5>
            </button>
          </div>
          <div className={`${styles.nonIBMVotes} ${voterContext === modalHeaders[2] ? styles.activeBtn : styles.votersBtns}`}>
            <button
              type="button"
              className={styles.votersBtns}
              onClick={onNonIBMVotesClick}
            >
              <h5>{voteDetails.nonIBMVoteCount}</h5>
              <h5>{modalHeaders[2]}</h5>
            </button>
          </div>
          {voteDetails.initialVotes > 0 && (
            <TooltipDefinition
              align="center"
              tooltipText={`This idea was imported and has an initial vote count of ${voteDetails.initialVotes} votes`}
            >
              <div className={styles.initialVotes}>
                <h5>{voteDetails.initialVotes}</h5>
                <h5>Initial Votes</h5>
              </div>
            </TooltipDefinition>
          )}
          <div className={`${styles.votersBtns}`}>
            {voteDetails.isVoted ? (
              <button
                type="button"
                className={`${styles.votersBtns} ${styles.isVoted}`}
                onClick={voteDetails.onUnvoteClick}
                disabled={voteDetails.isVoting}
              >
                <h5>Vote</h5><ThumbsUpFilled aria-label="Votes" />
              </button>
            ) : (
              <button
                type="button"
                className={`${styles.votersBtns} ${styles.isNotVoted}`}
                onClick={voteDetails.onVoteClick}
                disabled={voteDetails.isVoting}
              >
                <h5>Vote</h5><ThumbsUp aria-label="Votes" />
              </button>
            )}
          </div>
        </div>
        <div>
          {/*
          // @ts-ignore */}
          <DataTable rows={formatVotes(voters)} headers={headerData} isSortable>
            {({ rows, headers, getTableProps, getHeaderProps, getRowProps, onInputChange }: DataProps) => (
              <>
                <TableToolbar>
                  <TableToolbarContent>
                    <TableToolbarSearch onChange={onInputChange} />
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells && row.cells.map((cell: DataTableCell) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </DataTable>
        </div>
      </ModalBody>
    </ComposedModal>,
    document.body
  );
}

export default Voters;
