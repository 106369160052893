export enum Visibility {
  PUBLIC = 'Visible to all portal users',
  CREATOR_AND_EMPLOYEES = 'Visible to creator and employees',
  CREATOR = 'Visible to creator',
  EMPLOYEES = 'Visible to employees',
  NOT_VISIBLE = 'Not visible in portals',
  NOT_SPECIFIED = 'NO VISIBILITY SPECIFIED'
}

export function getVisibilityText(visibility: string, requesterEmail: string | null, isRequesterCreator: boolean) {
  if (visibility === Visibility.PUBLIC) return null;

  if (visibility === Visibility.CREATOR || visibility === Visibility.CREATOR_AND_EMPLOYEES) {
    if (requesterEmail && /[@.]ibm.com$/.test(requesterEmail)) {
      // The creator is an IBMer
      return 'This idea is visible only to IBM';
    }

    if (isRequesterCreator) {
      // The creator is you
      return 'This idea is visible only to you and IBM';
    }

    // The creator is someone else
    return 'This idea is visible only to the creator and IBM';
  }

  return 'This idea is visible only to IBM';
}
