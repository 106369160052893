import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Shell from './components/Shell/Shell';
import { ToastProvider } from './context/ToastContext';
import { GroupsProvider } from './context/GroupsContext';
import Group from './pages/Group/Group';
import Groups from './pages/Groups/Groups';
import MyIdeas from './pages/MyIdeas/MyIdeas';
import NewIdea from './pages/NewIdea/NewIdea';
import SearchResults from './pages/SearchResults/SearchResults';
import GroupIdeas from './pages/Group/GroupIdeas/GroupIdeas';
import GroupMembers from './pages/Group/GroupMembers/GroupMembers';
import GroupTags from './pages/Group/GroupTags/GroupTags';
import Product from './pages/Product/Product';
import NotFound from './pages/NotFound/NotFound';
import { CustomFieldsProvider } from './context/CustomFieldsContext';
import { FlashProvider } from './context/FlashContext';
import { UserProvider } from './context/UserContext';
import { WindowSizeProvider } from './context/WindowSizeContext';
import { ProductsProvider } from './context/ProductsContext';
import { CommentProvider } from './context/CommentContext';
import { SubscribeProvider } from './context/SubscribeContext';
import FollowedProductsConfig from './pages/FollowedProductsConfig/FollowedProductsConfig';
import PageLayout from './components/PageLayout/PageLayout';
import FollowedProducts from './pages/FollowedProducts/FollowedProducts';
import ProductCategory from './pages/ProductCategory/ProductCategory';
import IssueTrackerRoutes from './features/issueTracker/IssueTrackerRoutes';
import RegisterCompanies from './pages/RegisterCompanies/RegisterCompanies';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SettingsMenu from './pages/Settings/SettingsMenu';
import SettingsPage from './pages/Settings/SettingsPage/SettingsPage';
import SingleIdea from './pages/SingleIdea/SingleIdea';

function CombinedProvider({ children }: { children: React.ReactNode }) {
  return (
    <WindowSizeProvider>
      <UserProvider>
        <ToastProvider>
          <FlashProvider>
            <ProductsProvider>
              <GroupsProvider>
                <CustomFieldsProvider>
                  <CommentProvider>
                    <SubscribeProvider>
                      {children}
                    </SubscribeProvider>
                  </CommentProvider>
                </CustomFieldsProvider>
              </GroupsProvider>
            </ProductsProvider>
          </FlashProvider>
        </ToastProvider>
      </UserProvider>
    </WindowSizeProvider>
  );
}

function App() {
  return (
    <CombinedProvider>
      <Router>
        <ScrollToTop />
        <Shell>
          <Routes>
            <Route path="/issue-tracker/*" element={<IssueTrackerRoutes />} />
            <Route path="" element={<PageLayout />}>
              <Route path="/" element={<MyIdeas interaction="raised" key="interaction" />} />
              <Route path="/votes" element={<MyIdeas interaction="voted" key="voted" />} />
              <Route path="/comments" element={<MyIdeas interaction="commented" key="commented" />} />
              <Route path="/subscriptions" element={<MyIdeas interaction="subscribed" key="subscribed" />} />
              <Route path="/products" element={<FollowedProducts />} />
              <Route path="/products/:productId" element={<Product />} />
              <Route path="/products/:productId/categories/:categoryId" element={<ProductCategory />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/groups/:groupId" element={<Group />}>
                <Route path="" element={<GroupIdeas />} />
                <Route path="members" element={<GroupMembers />} />
                <Route path="tags" element={<GroupTags />} />
              </Route>
              <Route path="/settings" element={<SettingsMenu />}>
                <Route path="" element={<SettingsPage />} />
                <Route path="products" element={<FollowedProductsConfig />} />
              </Route>
              <Route path="/search" element={<SearchResults />} />
              <Route path="/register-companies" element={<RegisterCompanies />} />
              <Route path="/new-idea" element={<NewIdea />} />
              <Route path="/ideas/:ideaId" element={<SingleIdea />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Shell>
      </Router>
    </CombinedProvider>
  );
}

export default App;
