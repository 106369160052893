import { Close } from '@carbon/icons-react';
import { TooltipIcon } from 'carbon-components-react';
import styles from './Tag.module.css';

interface TagProps {
  name: string;
  colour: string;
  onClick?: () => void;
  onRemove?: () => void;
  disableRemove?: boolean;
  removeTooltip?: string;
}

function Tag({ name, colour, onClick, onRemove, disableRemove = false, removeTooltip = 'Remove tag' }: TagProps) {
  return (
    <div className={styles.tag} style={{ backgroundColor: colour }}>
      {onClick ? (
        <button
          type="button"
          className={styles.name}
          onClick={() => onClick()}
        >
          {name}
        </button>
      ) : (
        <span className={styles.name}>
          {name}
        </span>
      )}
      {onRemove && (
        <TooltipIcon
          className={styles.removeBtn}
          tooltipText={removeTooltip}
          direction="top"
          onClick={() => onRemove()}
          disabled={disableRemove}
        >
          <Close />
        </TooltipIcon>
      )}
    </div>
  );
}

export default Tag;
