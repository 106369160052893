import { useEffect, useMemo, useState } from 'react';
import Tag from '../../Tag/Tag';
import { useGroupsContext } from '../../../context/GroupsContext';
import { IdeasListingFilters } from '../ListingFilters/ListingFilters';
import styles from './FilteringBy.module.css';
import { IdeaProduct, IdeaProductCategory } from '../../../models/product';

interface CategoryItem {
  id: string;
  name: string;
  idea_count: number;
}

interface FilteringByProps {
  products: IdeaProduct[];
  categories: CategoryItem[];
  filters: IdeasListingFilters,
  setFilters: (fn: (prevFilters: IdeasListingFilters) => IdeasListingFilters) => void;
}

function FilteringBy({ products, categories, filters, setFilters }: FilteringByProps) {
  const { currentGroupTags } = useGroupsContext();
  const [selectedProducts, setSelectedProducts] = useState<IdeaProduct[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<IdeaProductCategory[]>([]);

  useEffect(() => {
    if (!filters.productIds) {
      // If there is no product filter, clear the selected products
      setSelectedProducts([]);
      return;
    }

    // For each filtered product ID
    const newProducts = filters.productIds.map((productId) => {
      // Try and find the product in the list of products
      const product = products.find((p) => p.id === productId);
      if (product) return product;

      // If the product was not found, create a fake product so that the filter still works
      return { id: productId, name: `Product ID: ${productId}`, idea_count: 0, prefix: '', categories: [] };
    });

    setSelectedProducts(newProducts);
  }, [products, filters.productIds]);

  useEffect(() => {
    if (!filters.categoryIds) {
      // If there is no category filter, clear the selected categories
      setSelectedCategories([]);
      return;
    }

    // For each filtered category ID
    const newCategory = filters.categoryIds.map((categoryId) => {
      // Try and find the category in the list of categories
      const category = categories.find((p) => p.id === categoryId);
      if (category) return category;

      // If the category was not found, create a fake category so that the filter still works
      return { id: categoryId, name: `Category ID: ${categoryId}`, idea_count: 0, prefix: '', categories: [] };
    });

    setSelectedCategories(newCategory);
  }, [categories, filters.categoryIds]);

  const filterTags = useMemo(
    () => {
      if (!filters.tagIds) return [];
      return currentGroupTags.filter((tag) => filters.tagIds.includes(tag.id));
    },
    [currentGroupTags, filters.tagIds]
  );

  const removeFilterProduct = (productId: string) => {
    const newProductIds = filters.productIds.filter((pId) => pId !== productId);
    setFilters((prevFilters) => ({
      ...prevFilters,
      productIds: newProductIds
    }));
  };

  const removeFilterTag = (tagId: string) => {
    const newFilterIds = (filters.tagIds.filter((tId) => tId !== tagId));
    setFilters((prevFilters) => ({
      ...prevFilters,
      tagIds: newFilterIds
    }));
  };

  const removeFilterCategory = (categoryId: string) => {
    const newFilterIds = (filters.categoryIds.filter((cId) => cId !== categoryId));
    setFilters((prevFilters) => ({
      ...prevFilters,
      categoryIds: newFilterIds
    }));
  };

  const removeFilterIBMManagedTag = (tag: string) => {
    const newFilters = filters.ibmWideManagedTags.filter((t) => t !== tag);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ibmWideManagedTags: newFilters
    }));
  };

  const removeFilterStatus = (status: string) => {
    const newFilters = filters.statuses.filter((s) => s !== status);
    setFilters((prevFilters) => ({
      ...prevFilters,
      statuses: newFilters
    }));
  };

  const currentFilters: React.ReactNode[] = [];

  if (selectedProducts.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Product:</span>
        <div className={styles.filterTypeTags}>
          {selectedProducts.map((product) => (
            <Tag
              key={product.id}
              name={product.name}
              colour="#EEE"
              onRemove={() => removeFilterProduct(product.id)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (selectedCategories.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Categories:</span>
        <div className={styles.filterTypeTags}>
          {selectedCategories.map((category) => (
            <Tag
              key={category.id}
              name={`${category.product_name} » ${category.name}`}
              colour="#EEE"
              onRemove={() => removeFilterCategory(category.id)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (filterTags.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Tags:</span>
        <div className={styles.filterTypeTags}>
          {filterTags.map((tag) => (
            <Tag
              key={tag.id}
              name={tag.name}
              colour={tag.colour}
              onRemove={() => removeFilterTag(tag.id)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (filters.ibmWideManagedTags.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Managed Tags:</span>
        <div className={styles.filterTypeTags}>
          {filters.ibmWideManagedTags.map((tag) => (
            <Tag
              key={tag}
              name={tag}
              colour="#EEE"
              onRemove={() => removeFilterIBMManagedTag(tag)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (filters.statuses.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Status:</span>
        <div className={styles.filterTypeTags}>
          {filters.statuses.map((status) => (
            <Tag
              key={status}
              name={status}
              colour="#EEE"
              onRemove={() => removeFilterStatus(status)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (currentFilters.length === 0) return null;

  return (
    <div className={styles.FilteringBy}>
      <span className={styles.filtersLabel}>Filters</span>
      <div className={styles.filters}>
        {currentFilters}
      </div>
    </div>
  );
}

export default FilteringBy;
