import { useCallback, useMemo, useRef, useState } from 'react';
import { Search, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'carbon-components-react';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { Add } from '@carbon/icons-react';
import { IBMCompany } from '../../models/company';
import { getCompanies, registerCompanyInAha } from '../../utils/api';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useUserContext } from '../../context/UserContext';
import ButtonSpinner from '../../components/ButtonSpinner/ButtonSpinner';
import { useToastContext } from '../../context/ToastContext';
import styles from './RegisterCompanies.module.css';

interface CompanyRowProps {
  company: IBMCompany,
  onRegister: (company: IBMCompany) => void
}

function isCompanyEqual(a: IBMCompany, b: IBMCompany): boolean {
  return a.ibm_company_id_type === b.ibm_company_id_type && a.ibm_company_id === b.ibm_company_id;
}

function CompanyRow({ company, onRegister }: CompanyRowProps) {
  const { addToast } = useToastContext();
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const registerInAha = async () => {
    setIsRegistering(true);

    try {
      const { data } = await registerCompanyInAha(company);
      onRegister(data);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to register company',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsRegistering(false);
  };

  return (
    <TableRow>
      <TableCell className={styles.colCompanyType}>{company.ibm_company_id_type}</TableCell>
      <TableCell className={styles.colCompanyID}>{company.ibm_company_id}</TableCell>
      <TableCell>{company.company_name}</TableCell>
      <TableCell className={styles.colRegister}>
        {company.aha_company_id ? (
          <p>Registered</p>
        ) : (
          <ButtonSpinner
            icon={Add}
            isLoading={isRegistering}
            loadingText="Registering in Aha!..."
            onClick={registerInAha}
            kind="tertiary"
          >
            Register in Aha!
          </ButtonSpinner>
        )}
      </TableCell>
    </TableRow>
  );
}

function RegisterCompanies() {
  const { isIBMEmployee } = useUserContext();
  const [companyInput, setCompanyInput] = useState<string>('');
  const [companies, setCompanies] = useState<IBMCompany[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const companyInputAbortController = useRef<AbortController | null>(null);
  const { addToast } = useToastContext();

  const handleCompanyInputChange = useCallback(async (inputText: string) => {
    if (companyInputAbortController.current) {
      companyInputAbortController.current.abort();
    }

    if (inputText === '') {
      setCompanies(null);
      setIsLoading(false);
      return;
    }

    companyInputAbortController.current = new AbortController();

    try {
      setIsLoading(true);
      const { data } = await getCompanies(inputText, companyInputAbortController.current);
      setCompanies(data);
      companyInputAbortController.current = null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        addToast({
          kind: 'error',
          title: 'Failed to find company',
          message: 'Something went wrong, please try again later'
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedHandleChange = useMemo(
    () => debounce((inputText) => handleCompanyInputChange(inputText), 300),
    [handleCompanyInputChange]
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setCompanyInput(e.target.value);
      debouncedHandleChange(e.target.value);
    },
    [debouncedHandleChange]
  );

  const updateCompany = (newCompany: IBMCompany) => {
    setCompanies((prevCompanies) => prevCompanies && prevCompanies.map(
      (c) => (isCompanyEqual(c, newCompany) ? newCompany : c)
    ));
  };

  if (!isIBMEmployee) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.RegisterCompanies}>
      <h2>Register Companies in Aha!</h2>
      <p>Use this page to add a company to the Aha! registry of companies.</p>
      <div className={styles.companyInputField}>
        <Search
          className={styles.companyInput}
          id="company"
          labelText=""
          placeholder="Search for a company..."
          value={companyInput}
          onChange={handleChange}
          size="sm"
        />
      </div>

      {isLoading ? (
        <LoadingSpinner text="Searching for companies..." />
      ) : (
        (companies !== null) && (
          companies.length === 0 ? (
            <p>No companies were found that match the search query.</p>
          ) : (
            <Table className={styles.companyTable}>
              <TableHead>
                <TableRow>
                  <TableHeader className={styles.colCompanyType}>Company Type</TableHeader>
                  <TableHeader className={styles.colCompanyID}>Company ID</TableHeader>
                  <TableHeader>Company Name</TableHeader>
                  <TableHeader className={styles.colRegister}>Register in Aha!</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company) => (
                  <CompanyRow
                    key={`${company.ibm_company_id_type}-${company.ibm_company_id}`}
                    company={company}
                    onRegister={updateCompany}
                  />
                ))}
              </TableBody>
            </Table>
          )
        )
      )}
    </div>
  );
}

export default RegisterCompanies;
