import { createContext, useContext, useEffect, useState } from 'react';

interface WindowSizeContextInterface {
  width: number;
  height: number;
}

const WindowSizeContext = createContext<WindowSizeContextInterface | null>(null);

export function useWindowSizeContext() {
  return useContext(WindowSizeContext)!;
}

function getWindowSize(): WindowSizeContextInterface {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

interface WindowSizeProviderProps {
  children: React.ReactNode
}

export function WindowSizeProvider({ children }: WindowSizeProviderProps) {
  const [size, setSize] = useState<WindowSizeContextInterface>(getWindowSize());

  useEffect(() => {
    const onResize = () => {
      setSize(getWindowSize());
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={size}>
      {children}
    </WindowSizeContext.Provider>
  );
}

export default WindowSizeContext;
