import { ArrowLeft } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

function NotFound() {
  return (
    <div className={styles.notFound}>
      <h2>Page Not Found</h2>
      <p>
        The page you were looking for does not exist
      </p>
      <Button as={Link} to="/" size="sm" className={styles.backBtn}><ArrowLeft /> Back to My Ideas</Button>
    </div>
  );
}

export default NotFound;
