import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { queryParamStrToArray } from '../../../utils/queryParams';
import { IssueTrackerListingFilters } from '../models/issueTracker';
import { useIssueTrackerContext } from './IssueTrackerContext';

interface IssueTrackerFiltersContextInterface {
  filters: IssueTrackerListingFilters;
  setFilters: (fn: (prevFilters: IssueTrackerListingFilters) => IssueTrackerListingFilters) => void;
}

const IssueTrackerFiltersContext = createContext<IssueTrackerFiltersContextInterface | null>(null);

export function useIssueTrackerFiltersContext() {
  return useContext(IssueTrackerFiltersContext)!;
}

interface IssueTrackerFiltersProps {
  children: React.ReactNode;
}

export function IssueTrackerFiltersProvider({ children }: IssueTrackerFiltersProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentProduct } = useIssueTrackerContext();
  const location = useLocation();

  const [filters, setFilters] = useState<IssueTrackerListingFilters>({
    outlooks: queryParamStrToArray(searchParams.get('outlook')),
    classificationThemes: queryParamStrToArray(searchParams.get('classificationtheme')),
    ibmWideManagedTags: queryParamStrToArray(searchParams.get('managedtags')),
    status: searchParams.get('status') || 'Open',
    big10: searchParams.get('big10'),
    productIds: queryParamStrToArray(searchParams.get('product')),
    categoryIds: queryParamStrToArray(searchParams.get('category')),
    approvedFrom: searchParams.get('approvedfrom'),
    approvedTo: searchParams.get('approvedto'),
  });

  // Clear product filters when changing product
  useEffect(() => {
    if (filters.productIds.length !== 0 || filters.categoryIds.length !== 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        productIds: [],
        categoryIds: []
      }));
    }
  }, [currentProduct?.id]);

  // If the filters change or the URL changes, update the URL query params
  useEffect(() => {
    const currentSearch = searchParams.toString().replaceAll('%2C', ',');

    const updateParam = (key: string, value: string | null) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    };

    updateParam('outlook', filters.outlooks.join(','));
    updateParam('classificationtheme', filters.classificationThemes.join(','));
    updateParam('managedtags', filters.ibmWideManagedTags.join(','));
    updateParam('status', filters.status === 'Open' ? null : filters.status);
    updateParam('big10', filters.big10);
    updateParam('product', filters.productIds.join(','));
    updateParam('category', filters.categoryIds.join(','));
    updateParam('approvedfrom', filters.approvedFrom);
    updateParam('approvedto', filters.approvedTo);

    const newSearch = searchParams.toString().replaceAll('%2C', ',');

    if (currentSearch !== newSearch) {
      navigate({ search: newSearch }, { replace: true });
    }
  }, [filters, location]);

  const issueTrackerFiltersContext = useMemo(() => ({
    filters,
    setFilters,
  }), [
    filters,
    setFilters,
  ]);

  return (
    <IssueTrackerFiltersContext.Provider value={issueTrackerFiltersContext}>
      {children}
    </IssueTrackerFiltersContext.Provider>
  );
}

export default IssueTrackerFiltersContext;
