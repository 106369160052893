import { ThumbsUpFilled, ThumbsUp, Information } from '@carbon/icons-react';
import { useState } from 'react';
import styles from './Votes.module.css';
import { Idea } from '../../models/idea';
import Voters from './Voters';
import useVotes from '../../hooks/useVotes';
import { useUserContext } from '../../context/UserContext';

interface VotesProps {
  idea: Idea;
  className?: string;
}

function Votes({ idea, className }: VotesProps) {
  const voteDetails = useVotes({ idea });
  const [isVotersDialogOpen, setIsVotersDialogOpen] = useState(false);
  const { isIBMEmployee } = useUserContext();

  return (
    <div>
      {voteDetails.isVoted ? (
        <button
          type="button"
          className={`${styles.voteBtn} ${styles.isVoted} ${className}`}
          onClick={voteDetails.onUnvoteClick}
          disabled={voteDetails.isVoting}
        >
          <ThumbsUpFilled aria-label="Votes" /> {voteDetails.voteCount}
        </button>
      ) : (
        <button
          type="button"
          className={`${styles.voteBtn} ${styles.isNotVoted} ${className}`}
          onClick={voteDetails.onVoteClick}
          disabled={voteDetails.isVoting}
        >
          <ThumbsUp aria-label="Votes" /> {voteDetails.voteCount}
        </button>
      )}
      {isIBMEmployee && (
      <>
        <button
          type="button"
          className={`${styles.voteInfo}`}
          onClick={() => setIsVotersDialogOpen(true)}
        >
          <Information aria-label="Voter information" />
        </button>
        <Voters
          isOpen={isVotersDialogOpen}
          voteDetails={voteDetails}
          onClose={() => setIsVotersDialogOpen(false)}
        />
      </>

      )}
    </div>
  );
}

export default Votes;
