import { Routes, Route } from 'react-router-dom';
import IssueTrackerFiltersContainer from './components/IssueTrackerFiltersContainer/IssueTrackerFiltersContainer';
import IssueTracker from './pages/IssueTracker';
import IssueTrackerBigTen from './pages/IssueTrackerBigTen/IssueTrackerBigTen';
import IssueTrackerHome from './pages/IssueTrackerHome/IssueTrackerHome';
import InclusionSummary from './pages/IssueTrackerParent/InclusionSummary';
import IssueTrackerParent from './pages/IssueTrackerParent/IssueTrackerParent';
import OutlookSummary from './pages/IssueTrackerParent/OutlookSummary';
import IssueTrackerProduct from './pages/IssueTrackerProduct/IssueTrackerProduct';

function IssueTrackerRoutes() {
  return (
    <Routes>
      <Route element={<IssueTracker />}>
        <Route path=":parentSlug">
          <Route element={<IssueTrackerParent />}>
            <Route path="" element={<OutlookSummary />} />
            <Route path="inclusion" element={<InclusionSummary />} />
          </Route>
          <Route element={<IssueTrackerFiltersContainer />}>
            <Route path="the-big-10" element={<IssueTrackerBigTen />} />
            <Route path=":productSlug" element={<IssueTrackerProduct />} />
            <Route path=":productSlug/:listingType" element={<IssueTrackerProduct />} />
          </Route>
        </Route>
        <Route path="" element={<IssueTrackerHome />} />
      </Route>
    </Routes>
  );
}

export default IssueTrackerRoutes;
