import { Dropdown } from 'carbon-components-react';
import { BIG_10_ITEMS } from '../../../data/big10';
import { IssueTrackerSummaryFilters } from '../../../models/issueTracker';
import styles from './IssueTrackerSummaryFilterBar.module.css';
import MonthRangeInput from '../../../../../components/MonthRangeInput/MonthRangeInput';
import { currentYearMonth } from '../../../../../utils/dates';
import ManagedTagFilters from '../../../../../components/ManagedTagFilters/ManagedTagFilters';

interface IssueTrackerSummaryFilterBarProps {
  filters: IssueTrackerSummaryFilters;
  setFilters: (fn: (prevFilters: IssueTrackerSummaryFilters) => IssueTrackerSummaryFilters) => void;
  showManagedTags?: boolean;
  showBig10?: boolean;
  showApprovedDate?: boolean;
}

function IssueTrackerSummaryFilterBar({ filters, setFilters, showManagedTags = false, showBig10 = false, showApprovedDate = false }: IssueTrackerSummaryFilterBarProps) {
  const selectedBig10Item = BIG_10_ITEMS.find((item) => item.value === filters.big10);
  const currentFilters: React.ReactNode[] = [];

  if (showManagedTags) {
    currentFilters.push(<ManagedTagFilters filters={filters} setFilters={setFilters} />);
  }

  if (showBig10) {
    currentFilters.push(
      <div>
        <Dropdown
          id="the-big-10"
          label="The Big 10"
          titleText="The Big 10"
          hideLabel
          light
          size="sm"
          items={BIG_10_ITEMS}
          selectedItem={selectedBig10Item}
          itemToString={(item) => item.label}
          onChange={({ selectedItem }) => {
            setFilters((prevFilters) => ({ ...prevFilters, big10: selectedItem ? selectedItem.value : null }));
          }}
        />
      </div>
    );
  }

  if (showApprovedDate) {
    currentFilters.push(
      <MonthRangeInput
        minDate="2022-10"
        maxDate={currentYearMonth()}
        startDate={filters.approvedFrom}
        endDate={filters.approvedTo}
        onRangeChange={(startDate, endDate) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            approvedFrom: startDate,
            approvedTo: endDate
          }));
        }}
      />
    );
  }

  if (showManagedTags) {
    currentFilters.push(<ManagedTagFilters filters={filters} setFilters={setFilters} finalBig10 />);
  }

  if (currentFilters.length === 0) return null;

  return (
    <div className={styles.IssueTrackerSummaryFilterBar}>
      {currentFilters}
    </div>
  );
}

export default IssueTrackerSummaryFilterBar;
