import { Checkbox, MultiSelect } from 'carbon-components-react';
import { useCustomFieldsContext } from '../../context/CustomFieldsContext';
import { getFilteredTagSet, createNewTagList, FINAL_BIG_10_TAG } from '../../utils/managedTags';

interface ManagedTagFilter { ibmWideManagedTags: string[]; }
interface ManagedTagFiltersProps<T> {
  filters: T;
  setFilters: (fn: (prevFilters: T) => T) => void;
  finalBig10?: boolean;
}
function ManagedTagFilters<T extends ManagedTagFilter>({ filters, setFilters, finalBig10 = false }: ManagedTagFiltersProps<T>) {
  const { managedTags } = useCustomFieldsContext();
  const filteredTagSet = getFilteredTagSet(managedTags);

  if (finalBig10) {
    return (
      <Checkbox
        id="select-final-big10"
        labelText={FINAL_BIG_10_TAG}
        checked={filters.ibmWideManagedTags.includes(FINAL_BIG_10_TAG)}
        onChange={(value: boolean) => {
          if (value) {
            setFilters((prevFilters) => {
              const ibmWideManagedTags = [...prevFilters.ibmWideManagedTags, FINAL_BIG_10_TAG];
              return { ...prevFilters, ibmWideManagedTags };
            });
          } else {
            const newFilters = filters.ibmWideManagedTags.filter((t) => t !== FINAL_BIG_10_TAG);
            setFilters((prevFilters) => ({
              ...prevFilters,
              ibmWideManagedTags: newFilters
            }));
          }
        }}
      />
    );
  }
  return (
    <>{filteredTagSet.map((managedTagSet) => {
      const selectedTags = managedTagSet.items.filter((tag: { key: string; }) => filters.ibmWideManagedTags.includes(tag.key));
      return (
        <div>
          <MultiSelect
            id={`${managedTagSet.key}-tags`}
            label={managedTagSet.label}
            titleText={managedTagSet.label}
            hideLabel
            light
            size="sm"
            selectionFeedback="fixed"
            items={managedTagSet.items}
            selectedItems={selectedTags}
            itemToString={(item) => item.label}
            onChange={({ selectedItems }) => {
              setFilters((prevFilters) => {
                const newSelectedTags = selectedItems.map((tag) => tag.key);
                const newManagedTags = createNewTagList(prevFilters.ibmWideManagedTags, newSelectedTags, managedTagSet.tags);
                return {
                  ...prevFilters,
                  ibmWideManagedTags: newManagedTags
                };
              });
            }}
          />
        </div>
      );
    })}
    </>
  );
}

export default ManagedTagFilters;
