import { Add } from '@carbon/icons-react';
import { Form, TextInput } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import ButtonSpinner from '../../../../components/ButtonSpinner/ButtonSpinner';
import ColourPicker from '../../../../components/ColourPicker/ColourPicker';
import { useGroupsContext } from '../../../../context/GroupsContext';
import { useToastContext } from '../../../../context/ToastContext';
import { NewTag } from '../../../../models/tag';
import { addGroupTag } from '../../../../utils/api';
import styles from './AddGroupTag.module.css';

function AddGroupTag() {
  const { addToast } = useToastContext();
  const groupsContext = useGroupsContext();
  const { currentGroup, currentGroupTags, getNextTagColour } = groupsContext;

  const tagNameInputRef = useRef<HTMLInputElement>(null);
  const hasAdded = useRef(false);

  const [name, setName] = useState('');
  const [colour, setColour] = useState(getNextTagColour());

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setColour(getNextTagColour());
  }, [currentGroupTags]);

  useEffect(() => {
    if (isAdding) {
      hasAdded.current = true;
    }

    if (hasAdded.current && !isAdding) {
      tagNameInputRef.current?.focus();
    }
  }, [isAdding]);

  if (!currentGroup) return null;

  const isValid = name.trim().length > 0;

  const onAdd = async () => {
    const newTag: NewTag = { name: name.trim(), colour };

    if (currentGroupTags.find((t) => t.name === newTag.name)) {
      addToast({
        kind: 'error',
        title: 'Tag already exists',
        message: <>A tag with the name <em>{newTag.name}</em> already exists in this group</>
      });
      tagNameInputRef.current?.focus();
      return;
    }

    setIsAdding(true);

    try {
      const { data } = await addGroupTag(currentGroup.id, newTag);
      data.idea_count = 0;
      groupsContext?.addGroupTag(data);
      setName('');
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add group tag',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsAdding(false);
  };

  return (
    <Form className={styles.addGroupTag} onSubmit={(e) => { e.preventDefault(); onAdd(); }}>
      <h4>Add a group tag</h4>
      <div className={styles.formRow}>
        <TextInput
          ref={tagNameInputRef}
          id="tag-name"
          className={styles.nameInput}
          labelText=""
          placeholder="Tag name"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={isAdding}
          maxLength={50}
        />
        <ColourPicker selectedColour={colour} onChange={setColour} />
      </div>
      <ButtonSpinner
        type="submit"
        icon={Add}
        disabled={!isValid}
        isLoading={isAdding}
        loadingText="Adding tag..."
      >
        Add tag
      </ButtonSpinner>
    </Form>
  );
}

export default AddGroupTag;
