import { createContext, useContext, useMemo, useState } from 'react';

interface FlashContextInterface {
  setNewIdeaId: (newIdeaId: string | null) => void;
  newIdeaId: string | null;
}

const FlashContext = createContext<FlashContextInterface | null>(null);

export function useFlashContext() {
  return useContext(FlashContext)!;
}

interface FlashProviderProps {
  children: React.ReactNode;
}

export function FlashProvider({ children }: FlashProviderProps) {
  const [newIdeaId, setNewIdeaId] = useState<string | null>(null);

  const flashContext = useMemo(() => ({
    setNewIdeaId,
    newIdeaId
  }), [newIdeaId]);

  return (
    <FlashContext.Provider value={flashContext}>
      {children}
    </FlashContext.Provider>
  );
}

export default FlashContext;
