import { Outlet } from 'react-router-dom';
import { useIssueTrackerContext } from '../../context/IssueTrackerContext';
import PageTab from '../../../../components/PageTabs/PageTab';
import PageTabs from '../../../../components/PageTabs/PageTabs';
import styles from './IssueTrackerParent.module.css';

function IssueTrackerParent() {
  const { currentParent } = useIssueTrackerContext();

  if (!currentParent) return <h2>Product not found</h2>;

  return (
    <section key={currentParent.id} className={styles.IssueTrackerParent}>
      <h2>{currentParent.name}</h2>
      <PageTabs>
        <PageTab to={`/issue-tracker/${currentParent.slug}`}>Delivery Outlook Summary</PageTab>
        <PageTab to={`/issue-tracker/${currentParent.slug}/inclusion`}>Inclusion State Summary</PageTab>
      </PageTabs>
      <Outlet />
    </section>
  );
}

export default IssueTrackerParent;
