/* eslint-disable react/no-danger */
import { Idea } from '../../models/idea';
import { sanitizeHTML } from '../../utils/sanitize';
import { formatDate } from '../../utils/dates';
import styles from './AdminResponse.module.css';

interface AdminResponseProps {
  idea: Idea;
}

function AdminResponse({ idea }: AdminResponseProps) {
  return (
    <div className={styles.adminResponse}>
      <div className={styles.responseHeader}>
        <h3>IBM Response</h3>
        <p>{formatDate(idea.admin_response_created_at)}</p>
      </div>
      <div
        className={styles.responseBody}
        dangerouslySetInnerHTML={{ __html: sanitizeHTML(idea.admin_response) }}
      />
    </div>
  );
}

export default AdminResponse;
