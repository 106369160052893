import { Calendar, ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { useEffect, useRef, useState } from 'react';
import styles from './MonthRangeInput.module.css';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface MonthRangeInputProps {
  minDate?: string;
  maxDate?: string;
  startDate?: string | null;
  endDate?: string | null;
  onRangeChange: (startDate: string, endDate: string) => void;
}

function MonthRangeInput({ minDate, maxDate, startDate = null, endDate = null, onRangeChange }: MonthRangeInputProps) {
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectionStartDate, setSelectionStartDate] = useState<string | null>(startDate);
  const [selectionEndDate, setSelectionEndDate] = useState<string | null>(endDate);
  const [isSelecting, setIsSelecting] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const minYear = minDate ? parseInt(minDate.split('-')[0], 10) : null;
  const maxYear = maxDate ? parseInt(maxDate.split('-')[0], 10) : null;

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (e.target && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsShowingDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const onMouseOver = (date: string) => {
    if (!isSelecting) return;
    setSelectionEndDate(date);
  };

  return (
    <div>
      <div className="bx--dropdown bx--dropdown--light bx--dropdown--sm bx--list-box bx--list-box--sm bx--list-box--light">
        <button
          type="button"
          className={`bx--list-box__field ${styles.openDropdownBtn}`}
          title="Pick date range"
          id="downshift-735-toggle-button"
          aria-haspopup="listbox"
          aria-expanded={isShowingDropdown}
          aria-labelledby="downshift-735-label downshift-735-toggle-button"
          onClick={() => setIsShowingDropdown((prev) => !prev)}
        >
          <span className="bx--list-box__label">Approved Date Range</span>
          <div className="bx--list-box__menu-icon">
            <Calendar />
          </div>
        </button>
        <div
          ref={dropdownRef}
          id="downshift-735-menu"
          className={`bx--list-box__menu ${styles.dropdown} ${isSelecting ? styles.isSelecting : ''}`}
          role="listbox"
          aria-labelledby="downshift-735-label"
          tabIndex={-1}
        >
          <div className={styles.yearBar}>
            <button
              type="button"
              className={styles.prevYear}
              onClick={() => setCurrentYear((year) => year - 1)}
              disabled={minYear !== null && currentYear <= minYear}
            >
              <ChevronLeft />
            </button>
            <button
              type="button"
              className={styles.year}
              onClick={() => {
                setIsShowingDropdown(false);
                setSelectionStartDate(`${currentYear}-01`);
                setSelectionEndDate(`${currentYear}-12`);
                onRangeChange(`${currentYear}-01`, `${currentYear}-12`);
              }}
            >
              {currentYear}
            </button>
            <button
              type="button"
              className={styles.nextYear}
              onClick={() => setCurrentYear((year) => year + 1)}
              disabled={maxYear !== null && currentYear >= maxYear}
            >
              <ChevronRight />
            </button>
          </div>
          <div className={styles.months}>
            {MONTHS.map((month, monthIndex) => {
              const monthStr = (monthIndex + 1).toString().padStart(2, '0');
              const date = `${currentYear}-${monthStr}`;
              const classes: string[] = [];

              let visibleStartDate = startDate;
              let visibleEndDate = endDate;

              if (isSelecting) {
                visibleStartDate = selectionStartDate;
                visibleEndDate = selectionEndDate;
              }

              if (visibleStartDate === date) {
                classes.push(styles.startDate);
              }

              if (visibleStartDate && visibleEndDate && date > visibleStartDate && date < visibleEndDate) {
                classes.push(styles.middleDate);
              }

              if (visibleEndDate === date) {
                classes.push(styles.endDate);
              }

              const isBeforeMinDate = (!!minDate && date < minDate);
              const isAfterMaxDate = (!!maxDate && date > maxDate);
              const isBeforeSelectionStart = (isSelecting && !!selectionStartDate && date < selectionStartDate);

              return (
                <button
                  key={date}
                  type="button"
                  className={classes.join(' ')}
                  onClick={() => {
                    if (isSelecting) {
                      setIsShowingDropdown(false);
                      if (selectionStartDate && date) {
                        onRangeChange(selectionStartDate, date);
                      }
                      setIsSelecting(false);
                    } else {
                      setSelectionStartDate(date);
                      setSelectionEndDate(date);
                      setIsSelecting(true);
                    }
                  }}
                  onMouseOver={() => onMouseOver(date)}
                  onFocus={() => onMouseOver(date)}
                  disabled={isBeforeMinDate || isAfterMaxDate || isBeforeSelectionStart}
                >
                  {month}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthRangeInput;
