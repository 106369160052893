import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getCustomFieldValues } from '../utils/api';
import { useUserContext } from './UserContext';
import { FINAL_BIG_10_TAG } from '../utils/managedTags';

interface CustomFieldsContextInterface {
  managedTags: string[];
  classificationThemes: string[];
  outlooks: string[];
}

const CustomFieldsContext = createContext<CustomFieldsContextInterface | null>(null);

export function useCustomFieldsContext() {
  return useContext(CustomFieldsContext)!;
}

interface CustomFieldsProviderProps {
  children: React.ReactNode;
}

export function CustomFieldsProvider({ children }: CustomFieldsProviderProps) {
  const [managedTags, setManagedTags] = useState<string[]>([]);
  const [classificationThemes, setClassificationThemes] = useState<string[]>([]);
  const [outlooks, setOutlooks] = useState<string[]>([]);
  const { isProductScoutWWLeader } = useUserContext();

  useEffect(() => {
    const fetchManagedTags = async () => {
      let { data } = await getCustomFieldValues('ibm_wide_managed_tags_ideas');
      // only show Final big 10 tag to WW Lead
      if (!isProductScoutWWLeader) {
        data = data.filter((tag) => tag !== FINAL_BIG_10_TAG);
      }
      setManagedTags(data);
    };
    const fetchClassificationThemes = async () => {
      const { data } = await getCustomFieldValues('field_classification_theme');
      setClassificationThemes(data);
    };
    const fetchOutlooks = async () => {
      const { data } = await getCustomFieldValues('field_outlook');
      setOutlooks(data);
    };
    fetchManagedTags();
    fetchClassificationThemes();
    fetchOutlooks();
  }, []);

  const customFieldsContext = useMemo(() => ({
    managedTags,
    classificationThemes,
    outlooks,
  }), [
    managedTags,
    classificationThemes,
    outlooks,
  ]);

  return (
    <CustomFieldsContext.Provider value={customFieldsContext}>
      {children}
    </CustomFieldsContext.Provider>
  );
}

export default CustomFieldsContext;
