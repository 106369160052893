import { SettingsAdjust } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useCallback, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import AdvancedSearchDialog, { AdvancedSearchInput } from '../AdvancedSearchDialog/AdvancedSearchDialog';
import SearchInput from '../SearchInput/SearchInput';
import Tag from '../Tag/Tag';
import styles from './PageHeader.module.css';

function PageHeader() {
  const { isIBMEmployee } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchQueryInput, setSearchQueryInput] = useState(searchParams.get('query') || '');

  const [advancedSearchInput, setAdvancedSearchInput] = useState<AdvancedSearchInput>({
    customer: searchParams.get('customer') || '',
    creator: searchParams.get('creator') || '',
    assignee: searchParams.get('assignee') || '',
  });

  const [isAdvancedSearchDialogOpen, setIsAdvancedSearchDialogOpen] = useState(false);

  const isAdvancedSearch = advancedSearchInput.customer || advancedSearchInput.creator || advancedSearchInput.assignee;

  const doSearch = (newQuery: string, newAdvancedSearchInput: AdvancedSearchInput) => {
    let params: { [key: string]: string } = {};

    // Only do advanced search if IBM employee
    if (isIBMEmployee) {
      params = Object.fromEntries(Object.entries(newAdvancedSearchInput).filter(([, v]) => v !== ''));
    }

    if (newQuery) {
      params.query = newQuery;
    }

    if (Object.keys(params).length >= 1) {
      if (location.pathname === '/search') {
        // Maintain existing filters
        searchParams.delete('query');
        searchParams.delete('customer');
        searchParams.delete('creator');
        searchParams.delete('assignee');

        const mergedSearchParams = new URLSearchParams({
          ...Object.fromEntries(searchParams),
          ...params
        });

        navigate(`/search?${createSearchParams(mergedSearchParams)}`);
      } else {
        navigate(`/search?${createSearchParams(params)}`);
      }
    } else {
      navigate('/search');
    }
  };

  const setAndDoSearch = (newQuery: string, newAdvancedSearchInput: AdvancedSearchInput) => {
    setSearchQueryInput(newQuery);
    setAdvancedSearchInput(newAdvancedSearchInput);
    doSearch(newQuery, newAdvancedSearchInput);
  };

  const onSearchChange = useCallback((newQuery: string) => {
    doSearch(newQuery, advancedSearchInput);
  }, [searchParams, advancedSearchInput]);

  const clearSearch = () => {
    setSearchQueryInput('');
    setAdvancedSearchInput({ customer: '', creator: '', assignee: '' });
    if (location.pathname === '/search') {
      navigate('/search');
    }
  };

  useEffect(() => {
    if (location.pathname !== '/search') {
      clearSearch();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/search') {
      setAdvancedSearchInput(() => ({ query: '', customer: '', creator: '', assignee: '' }));
    }
  }, [location.pathname, searchParams, setSearchParams]);

  return (
    <header aria-labelledby="page-header" className={styles.pageHeader}>
      <div className={styles.titleContainer}>
        <h1 id="page-header" className={styles.title}>IBM Ideas</h1>
        <div className={styles.intro}>
          <p>
            This portal is designed to provide you with an integrated view of all of your submitted,
            voted or commented on ideas for any IBM product supported by an Aha! product portal.
          </p>
          <p>To learn more about the IBM Ideas Portal, visit our <a href="https://www.ibm.com/ideas">support and FAQs</a> page.</p>
          <p>Need help? Email us at <a href="mailto:ideasibm@us.ibm.com">ideasibm@us.ibm.com</a></p>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div className={`${styles.searchInputContainer} ${isIBMEmployee ? styles.hasAdvancedSearchBtn : ''}`}>
          <SearchInput
            placeholder="Search all products and ideas..."
            value={searchQueryInput}
            onChange={setSearchQueryInput}
            onSearchChange={onSearchChange}
          />
          {isIBMEmployee && (
            <>
              <Button
                className={`${styles.advancedSearchBtn} ${isAdvancedSearch ? styles.active : ''}`}
                iconDescription="Advanced Search"
                renderIcon={SettingsAdjust}
                onClick={() => setIsAdvancedSearchDialogOpen(true)}
                hasIconOnly
                tooltipAlignment="end"
                tooltipPosition="bottom"
              />
              <AdvancedSearchDialog
                isOpen={isAdvancedSearchDialogOpen}
                onClose={() => setIsAdvancedSearchDialogOpen(false)}
                onClear={() => clearSearch()}
                onSearch={(query, advancedSearch) => { setAndDoSearch(query, advancedSearch); }}
                initialQueryInput={searchQueryInput}
                initialAdvancedSearchInput={advancedSearchInput}
              />
            </>
          )}
        </div>
        {isIBMEmployee && isAdvancedSearch && (
          <div className={styles.advancedSearchBy}>
            {advancedSearchInput.customer && (
              <Tag
                name={`Customer: ${advancedSearchInput.customer}`}
                colour="#f4f4f4"
                onRemove={() => setAndDoSearch(searchQueryInput, { ...advancedSearchInput, customer: '' })}
                removeTooltip="Remove filter"
              />
            )}
            {advancedSearchInput.creator && (
              <Tag
                name={`Creator: ${advancedSearchInput.creator}`}
                colour="#f4f4f4"
                onRemove={() => setAndDoSearch(searchQueryInput, { ...advancedSearchInput, creator: '' })}
                removeTooltip="Remove filter"
              />
            )}
            {advancedSearchInput.assignee && (
              <Tag
                name={`Assignee: ${advancedSearchInput.assignee}`}
                colour="#f4f4f4"
                onRemove={() => setAndDoSearch(searchQueryInput, { ...advancedSearchInput, assignee: '' })}
                removeTooltip="Remove filter"
              />
            )}
          </div>
        )}
      </div>
    </header>
  );
}

export default PageHeader;
