import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IdeasListing from '../../components/IdeasListing/IdeasListing';
import { getProductCategory } from '../../utils/api';
import useAPI from '../../hooks/useAPI';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import styles from './ProductCategory.module.css';
import FollowButton from '../../components/FollowButton/FollowButton';

function ProductCategory() {
  const { productId, categoryId } = useParams();
  const productAPI = useAPI(getProductCategory);

  useEffect(() => {
    productAPI.execute(productId, categoryId);
  }, [productId, categoryId]);

  const queryParams = useMemo(() => ({
    category_ids: categoryId || ''
  }), [categoryId]);

  if (productAPI.state === 'idle' || productAPI.state === 'loading') {
    return <LoadingSpinner text="Loading product..." />;
  }

  return (
    <>
      <header className={styles.header}>
        <h2>Product: {productAPI.data?.name} &raquo; <strong>{productAPI.data?.idea_category?.name}</strong></h2>
        <FollowButton
          product={{
            id: productAPI.data!.id,
            name: productAPI.data!.name,
            idea_category: {
              id: productAPI.data!.idea_category!.id,
              name: productAPI.data!.idea_category!.name
            }
          }}
        />
      </header>
      <IdeasListing
        queryParams={queryParams}
        showQueryFilter
        hideProductFilter
        noIdeasText="There are no ideas in this product"
      />
    </>
  );
}

export default ProductCategory;
