import { Button, ComposedModal, FilterableMultiSelect, InlineLoading, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useCustomFieldsContext } from '../../../context/CustomFieldsContext';
import { useToastContext } from '../../../context/ToastContext';
import { useUserContext } from '../../../context/UserContext';
import { Idea, IdeaUpdate } from '../../../models/idea';
import { updateIdea, setIssueTrackerInclusion } from '../../../utils/api';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import Tag from '../../Tag/Tag';
import styles from './EditIdeaDialog.module.css';
import { IssueTrackerInclusionState } from '../../../utils/issueTrackerInclusionStates';

interface EditIdeaDialogProps {
  idea: Idea;
  isOpen: boolean;
  onClose: () => void;
  onMarkAsCandidate: () => void;
  isMarkingAsCandidate?: boolean;
}

function EditIdeaDialog({ idea, isOpen, onClose, onMarkAsCandidate, isMarkingAsCandidate = false }: EditIdeaDialogProps) {
  const { isProductScoutWWLeader, isProductScoutGeoLeader } = useUserContext();
  const { addToast } = useToastContext();
  const { classificationThemes } = useCustomFieldsContext();
  const [isSaving, setIsSaving] = useState(false);

  const isProductScoutLeader = isProductScoutWWLeader || isProductScoutGeoLeader;
  const canEditImpact = isProductScoutLeader || !idea.impact_statement_provided;

  const [newImpactData, setNewImpactData] = useState(isProductScoutLeader ? (idea.impact_statement || '') : '');
  const [newClassificationThemes, setNewClassificationThemes] = useState(idea.classification_themes || []);

  const [impactError, setImpactError] = useState('');

  const onSubmit = async () => {
    if (canEditImpact && (newImpactData.trim() === '')) {
      setImpactError('IBM business impact statement is required');
      return;
    }

    setIsSaving(true);

    try {
      // Update the idea
      const updateIdeaData: IdeaUpdate = {
        classification_themes: newClassificationThemes
      };

      if (canEditImpact) {
        updateIdeaData.impact_statement = newImpactData;
      }

      await updateIdea(idea.id, updateIdeaData);

      // Update the local copy of the idea
      idea.classification_themes = newClassificationThemes;

      if (canEditImpact) {
        idea.impact_statement = newImpactData;
      }

      if (!isMarkingAsCandidate) {
        addToast({
          kind: 'success',
          title: 'The idea has been updated',
        });
      } else {
        await setIssueTrackerInclusion(idea.id, IssueTrackerInclusionState.CANDIDATE);
        onMarkAsCandidate();

        addToast({
          kind: 'success',
          title: 'The idea has been marked as candidate',
        });
      }

      onClose();
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed to update idea',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsSaving(false);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.EditIdeaDialog}
      open={isOpen}
      onClose={() => onClose()}
      preventCloseOnClickOutside
    >
      <ModalHeader title={isMarkingAsCandidate ? 'Mark idea as Issue Tracker candidate' : 'Edit idea Issue Tracker fields'} />
      <ModalBody>
        <div className={styles.info}>
          {isMarkingAsCandidate ? (
            <>
              <p>
                Before marking this idea as an Issue Tracker candidate, please ensure that the IBM business impact statement and classification themes are set.
                This will help Product Scout leaders with their decision to include in the Issue Tracker.
                Learn more about the <a href="https://w3.ibm.com/w3publisher/product-scout-program/#Questions" target="_blank" rel="noopener noreferrer">Product Scout Program</a>.
              </p>
              <p>
                These are IBM internal fields that cannot be submitted via an Aha portal.
              </p>
            </>
          ) : (
            <>These are IBM internal fields that cannot be submitted via an Aha portal.</>
          )}
        </div>
        <div className={styles.formRow}>
          <p>
            Selected idea: <strong>{idea.name}</strong> [{idea.reference}]
          </p>
        </div>
        <div className={`${styles.formRow} ${styles.shortField}`}>
          <FilterableMultiSelect
            id="classification-theme-dropdown"
            titleText="Classification themes (optional)"
            placeholder="Add classification themes"
            items={classificationThemes}
            itemToString={(dropdownItem) => dropdownItem}
            initialSelectedItems={newClassificationThemes}
            onChange={({ selectedItems }) => setNewClassificationThemes(selectedItems)}
            disabled={isSaving}
          />
          <div className={styles.tagsContainer}>
            {newClassificationThemes.map((theme) => (
              <Tag
                key={theme}
                name={theme}
                colour="#DDD"
              />
            ))}
          </div>
        </div>
        <div className={styles.formRow}>
          <label htmlFor="impact-statement" className="bx--label">IBM business impact statement</label>
          {canEditImpact ? (
            <>
              <RichTextEditor
                className={`${styles.richTextEditor} ${impactError ? styles.invalid : ''}`}
                data={newImpactData || ''}
                placeholder="Please provide the business impact that this idea will have. Include customer name(s) and if possible its revenue potential. This information will make an impact and is important to prioritize and drive this item forward."
                onChange={setNewImpactData}
                disabled={isSaving}
              />
              {impactError && (
                <div className={styles.formError}>{impactError}</div>
              )}
            </>
          ) : (
            <div className={styles.redacted}>An IBM business impact statement has already been provided. It has been redacted as it may contain IBM Confidential information.</div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button kind="secondary" disabled={isSaving} onClick={onClose}>Cancel</Button>
        <div className={styles.submitContainer}>
          {isSaving ? (
            <InlineLoading className={styles.submitLoading} description="Updating idea..." />
          ) : (
            <Button size="sm" kind="primary" onClick={onSubmit}>
              {isMarkingAsCandidate ? 'Mark as candidate' : 'Update idea' }
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default EditIdeaDialog;
