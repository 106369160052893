import { CarbonIconType } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { TooltipAlignment } from 'carbon-components-react/typings/shared';
import { useRef } from 'react';
import { useWindowSizeContext } from '../../context/WindowSizeContext';

interface IconButtonAutoTooltipProps {
  className: string;
  icon: CarbonIconType;
  tooltip: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

function IconButtonAutoTooltip({ className, icon, tooltip, onClick, disabled = false }: IconButtonAutoTooltipProps) {
  const windowSize = useWindowSizeContext();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const buttonEl = buttonRef.current;
  const tooltipEl = buttonEl?.querySelector('.bx--assistive-text') as HTMLDivElement;

  let tooltipAlignment: TooltipAlignment = 'center';

  if (windowSize.width && buttonEl && tooltipEl) {
    const buttonMiddleX = buttonEl.offsetLeft + (buttonEl.clientWidth / 2);
    const tooltipWidth = tooltipEl.offsetWidth;

    if (buttonMiddleX + tooltipWidth / 2 > windowSize.width - 20) {
      tooltipAlignment = 'end';
    }
  }

  return (
    <Button
      ref={buttonRef}
      className={className}
      iconDescription={tooltip}
      tooltipAlignment={tooltipAlignment}
      renderIcon={icon}
      hasIconOnly
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default IconButtonAutoTooltip;
