/* eslint-disable react/no-danger */
import { Bee, UserAvatarFilledAlt, UserAvatarFilled, Reply } from '@carbon/icons-react';
import ReactTimeAgo from 'react-time-ago';
import React, { useEffect, useRef, useState } from 'react';
import { Button, TooltipIcon } from 'carbon-components-react';
import { useUserContext } from '../../context/UserContext';
import { useCommentContext } from '../../context/CommentContext';
import { sanitizeHTML } from '../../utils/sanitize';
import { IdeaComment } from '../../models/idea';
import { CommentVisibility } from '../../utils/commentVisibility';
import CommentBox from '../CommentBox/CommentBox';
import styles from './Comment.module.css';

interface CommentProps {
  comment: IdeaComment;
}

function Comment({ comment }: CommentProps) {
  const { isIBMEmployee } = useUserContext();
  const [childrenComments, setChildrenComments] = useState(comment.children_comments || []);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const { commentId, setCommentId } = useCommentContext();
  const privatelyShared = (ideaComment: IdeaComment) => (ideaComment.comment_visibility === CommentVisibility.commentCreator);
  const privatelySharedIdeaCreator = (ideaComment: IdeaComment) => (ideaComment.comment_visibility === CommentVisibility.ideaCreator);

  const isNewComment = useRef(commentId === comment.comment_id);
  const replyBoxID = `reply-box-${comment.comment_id}`;

  useEffect(() => {
    if (showReplyBox) {
      const element = document.getElementById(replyBoxID);
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showReplyBox]);

  useEffect(() => {
    setShowReplyBox(false);
  }, [childrenComments]);

  useEffect(() => {
    if (isNewComment.current) {
      setCommentId(null);
    }
  }, []);

  const userIcon = (ideaComment: IdeaComment) => {
    let iconClass = styles.userAvatar;
    let avatar = UserAvatarFilledAlt;
    if (ideaComment.user_name === 'You') {
      avatar = UserAvatarFilled;
    } else if (ideaComment.ibm_employee) {
      iconClass = styles.ibmUserAvatar;
      avatar = Bee;
    }
    const avatarComponent = React.createElement(avatar, { className: iconClass, size: '32' });
    if (isIBMEmployee && ideaComment.user_email) {
      return (
        <TooltipIcon
          className={iconClass}
          tooltipText={ideaComment.user_email}
        >
          {avatarComponent}
        </TooltipIcon>
      );
    }
    return (avatarComponent);
  };

  const singleComment = (ideaComment: IdeaComment, commentStyle: string) => (
    <div key={ideaComment.comment_id} className={commentStyle}>
      <div className={styles.commentDetails}>
        {userIcon(ideaComment)}
        <div className={styles.userName}>{ideaComment.user_name}</div>
        <div className={styles.commentDate}>
          <span>
            <ReactTimeAgo date={new Date(ideaComment.comment_created_at)} />
          </span>
          {privatelyShared(ideaComment)
          && (
          <span>
            Shared privately with IBM
          </span>
          )}
          {privatelySharedIdeaCreator(ideaComment)
          && (
          <span>
            Shared privately with the Idea creator and IBM
          </span>
          )}

        </div>
        {commentStyle === styles.ideaComment && (
          <div className={styles.replyButton}>
            <Button kind="ghost" size="sm" onClick={() => setShowReplyBox(!showReplyBox)}><Reply />Reply</Button>
          </div>
        )}
      </div>
      <div
        className={styles.comment}
        dangerouslySetInnerHTML={{ __html: sanitizeHTML(ideaComment.comment || '') }}
      />
    </div>
  );

  return (
    <div id={comment.comment_id} className={`${styles.ideaCommentContainer} ${isNewComment.current ? styles.newComment : ''}`}>
      {singleComment(comment, styles.ideaComment)}
      {childrenComments && (
        childrenComments.map((child_comment) => (
          singleComment(child_comment, styles.ideaChildComment)
        ))
      )}
      {showReplyBox && (
        <div id={replyBoxID}>
          <CommentBox comment={comment} setChildrenComments={setChildrenComments} isReply />
        </div>
      )}
    </div>
  );
}

export default Comment;
