import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button, Checkbox, InlineNotification, TooltipIcon } from 'carbon-components-react';
import { Add, Close, Download, ListChecked } from '@carbon/icons-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IdeaCard from '../IdeaCard/IdeaCard';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Pagination from '../Pagination/Pagination';
import styles from './IdeasListing.module.css';
import { useGroupsContext } from '../../context/GroupsContext';
import MultiAddToGroupDialog from './MultiAddToGroupDialog/MultiAddToGroupDialog';
import MultiAddTagDialog from './MultiAddTagDialog/MultiAddTagDialog';
import ListingFilters, { IdeasListingFilters } from './ListingFilters/ListingFilters';
import MultiAddManagedTagDialog from './MultiAddManagedTagDialog/MultiAddManagedTagDialog';
import { useUserContext } from '../../context/UserContext';
import useAPI from '../../hooks/useAPI';
import { getIdeaCategories, getIdeas, getIdeasCSVURL, getIdeasProducts, getIdeasStatuses } from '../../utils/api';
import usePagination from '../../hooks/usePagination';
import FilteringBy from './FilteringBy/FilteringBy';
import { queryParamStrToArray } from '../../utils/queryParams';

interface IdeasListingProps {
  queryParams: { [key: string]: string | string[] | number }
  showQueryFilter?: boolean;
  showRelevanceSort?: boolean;
  hideProductFilter?: boolean;
  hideCategoryFilter?: boolean;
  defaultSort?: string;
  noIdeasText: React.ReactNode;
}

function IdeasListing({ queryParams, showQueryFilter = false, showRelevanceSort = false, hideProductFilter = false, hideCategoryFilter = true, defaultSort = 'created_at', noIdeasText }: IdeasListingProps) {
  const { groups } = useGroupsContext();
  const { isIBMEmployee } = useUserContext();

  const { groupId } = useParams();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const productQueryParam = queryParamStrToArray(searchParams.get('product'));
  const categoryQueryParam = queryParamStrToArray(searchParams.get('category'));
  const statusQueryParam = queryParamStrToArray(searchParams.get('status'));
  const tagsQueryParam = queryParamStrToArray(searchParams.get('tags'));
  const managedTagsQueryParam = queryParamStrToArray(searchParams.get('managedtags'));

  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [selectedIdeaIds, setSelectedIdeaIds] = useState<string[]>([]);
  const [isChangingPage, setIsChangingPage] = useState(false);

  const [isMultiAddToGroupDialogOpen, setIsMultiAddToGroupDialogOpen] = useState(false);
  const [isMultiAddTagDialogOpen, setIsMultiAddTagDialogOpen] = useState(false);
  const [isMultiAddManagedTagDialogOpen, setIsMultiAddManagedTagDialogOpen] = useState(false);

  const getIdeasAPI = useAPI(getIdeas);
  const getProductsAPI = useAPI(getIdeasProducts);
  const getIdeaProductCategoriesAPI = useAPI(getIdeaCategories);
  const getStatusesAPI = useAPI(getIdeasStatuses);
  const { currentPage, changePage, resetPage, isResettingPage } = usePagination();

  const ideas = getIdeasAPI.data;

  const totalPages = useMemo(() => {
    if (!getIdeasAPI.headers) return 0;
    return parseInt(getIdeasAPI.headers['pagination-last-page'], 10);
  }, [getIdeasAPI.headers]);

  const totalItems = useMemo(() => {
    if (!getIdeasAPI.headers) return 0;
    return parseInt(getIdeasAPI.headers['pagination-total-items'], 10);
  }, [getIdeasAPI.headers]);

  const [filters, setFilters] = useState<IdeasListingFilters>({
    sort: defaultSort,
    productIds: productQueryParam,
    categoryIds: categoryQueryParam,
    query: '',
    tagIds: tagsQueryParam,
    ibmWideManagedTags: managedTagsQueryParam,
    statuses: statusQueryParam,
  });

  let exportCSVLabelText = `Download ${totalItems} Ideas as CSV`;
  if (totalItems > 1000) {
    exportCSVLabelText = 'Cannot export CSV containing over 1000 ideas';
  } else if (totalItems === 0) {
    exportCSVLabelText = 'No ideas to export';
  } else if (totalItems === 1) {
    exportCSVLabelText = `Download ${totalItems} Idea as CSV`;
  }

  // If the filters change, update the URL query params
  useEffect(() => {
    const updateParam = (key: string, value: string | null) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    };

    updateParam('product', filters.productIds.join(','));
    updateParam('category', filters.categoryIds.join(','));
    updateParam('status', filters.statuses.join(','));
    updateParam('tags', filters.tagIds.join(','));
    updateParam('managedtags', filters.ibmWideManagedTags.join(','));

    navigate({ search: searchParams.toString().replaceAll('%2C', ',') });
  }, [filters]);

  useEffect(resetPage, [queryParams, filters]);

  const isInvalidPage = totalPages !== 0 && totalPages < currentPage;

  // If the current page is greater than total pages (e.g. ideas have been removed),
  // change to the last page
  useLayoutEffect(() => {
    if (isInvalidPage) {
      changePage(totalPages);
      setIsChangingPage(true);
    }
  }, [totalPages, currentPage]);

  function getAPIParams(withPagination: boolean = true) {
    const params: { [key: string]: string | string[] | number } = {
      ...queryParams,
      sort: filters.sort
    };

    // Remove product ID if category filters are selected on the product page
    if (queryParams.product_id && filters.categoryIds.length !== 0) {
      delete params.product_id;
    }

    if (withPagination) {
      params.page = currentPage;
      params.per_page = 20;
    }

    if (filters.query) {
      params.query = filters.query;
    }

    if (filters.productIds.length !== 0) {
      params.product_ids = filters.productIds;
    }

    if (filters.tagIds.length !== 0) {
      params.tag_ids = filters.tagIds;
    }

    if (filters.categoryIds.length !== 0) {
      params.category_ids = filters.categoryIds;
    }

    if (filters.ibmWideManagedTags.length !== 0) {
      params.ibm_wide_managed_tags = filters.ibmWideManagedTags;
    }

    if (filters.statuses) {
      params.workflow_statuses = filters.statuses;
    }

    return params;
  }

  const refreshIdeas = () => {
    if (isResettingPage) {
      return undefined;
    }

    const abortController = new AbortController();

    getIdeasAPI.execute(getAPIParams(true), abortController)
      .then(() => setIsChangingPage(false));

    return () => abortController.abort();
  };

  useEffect(refreshIdeas, [queryParams, currentPage, filters]);

  const refreshFilters = () => {
    const abortController = new AbortController();

    getProductsAPI.execute(queryParams, abortController);
    getStatusesAPI.execute(queryParams, abortController);
    getIdeaProductCategoriesAPI.execute(queryParams, abortController);

    return () => abortController.abort();
  };

  useEffect(refreshFilters, [queryParams]);

  const refreshListing = () => {
    refreshIdeas();
    refreshFilters();
  };

  const addFilterTag = (tagId: string) => {
    if (!filters.tagIds.includes(tagId)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        tagIds: [...prevFilters.tagIds, tagId]
      }));
    }
  };

  const addFilterIBMManagedTag = (tag: string) => {
    if (!filters.ibmWideManagedTags.includes(tag)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ibmWideManagedTags: [...prevFilters.ibmWideManagedTags, tag]
      }));
    }
  };

  const toggleIdeaSelected = (ideaId: string, selected: boolean) => {
    if (selected) {
      setSelectedIdeaIds((prevIds) => {
        if (prevIds.includes(ideaId)) {
          return prevIds;
        }
        return [...prevIds, ideaId];
      });
    } else {
      setSelectedIdeaIds((prevIds) => prevIds.filter((id) => id !== ideaId));
    }
  };

  const clearSelectingMultiple = () => {
    setIsSelectingMultiple(false);
    setSelectedIdeaIds([]);
  };

  // Go through each filter and see if any have been applied. Ignore the sort filter
  // Also ignore the query filter when on the search page (i.e. showQueryFilter is false)
  const areFiltersApplied = useMemo(
    () => !Object.entries(filters).every(
      ([filterKey, filterValue]) => filterKey === 'sort' || filterValue === null || filterValue.length === 0 || (!showQueryFilter && filterKey === 'query')
    ),
    [showQueryFilter, filters]
  );

  const multiSelectOptions = [];

  if (groups.length !== 0) {
    multiSelectOptions.push(
      <Button
        key="multi-add-to-group"
        kind="tertiary"
        size="sm"
        renderIcon={Add}
        disabled={selectedIdeaIds.length === 0}
        onClick={() => setIsMultiAddToGroupDialogOpen(true)}
      >
        Add {selectedIdeaIds.length} {selectedIdeaIds.length === 1 ? 'idea' : 'ideas'} to a group
      </Button>
    );
  }

  if (groupId) {
    multiSelectOptions.push(
      <Button
        key="multi-add-tag"
        kind="tertiary"
        size="sm"
        renderIcon={Add}
        disabled={selectedIdeaIds.length === 0}
        onClick={() => setIsMultiAddTagDialogOpen(true)}
      >
        Add a tag to {selectedIdeaIds.length} {selectedIdeaIds.length === 1 ? 'idea' : 'ideas'}
      </Button>
    );
  }

  if (isIBMEmployee) {
    multiSelectOptions.push(
      <Button
        key="multi-add-ibm-tag"
        kind="tertiary"
        size="sm"
        renderIcon={Add}
        disabled={selectedIdeaIds.length === 0}
        onClick={() => setIsMultiAddManagedTagDialogOpen(true)}
      >
        Add IBM wide managed tag to {selectedIdeaIds.length} {selectedIdeaIds.length === 1 ? 'idea' : 'ideas'}
      </Button>
    );
  }

  const products = useMemo(() => getProductsAPI.data || [], [getProductsAPI.data]);
  const categories = useMemo(() => getIdeaProductCategoriesAPI.data || [], [getIdeaProductCategoriesAPI.data]);
  const statuses = useMemo(() => getStatusesAPI.data || [], [getStatusesAPI.data]);

  return (
    <>
      <ListingFilters
        products={products}
        statuses={statuses}
        categories={categories}
        showQueryFilter={showQueryFilter}
        hideProductFilter={hideProductFilter}
        hideCategoryFilter={hideCategoryFilter}
        showRelevanceSort={showRelevanceSort}
        filters={filters}
        setFilters={setFilters}
      />
      <FilteringBy products={products} categories={categories} filters={filters} setFilters={setFilters} />
      <div className={styles.keyButtons}>
        <div className={styles.multiselectContainer}>
          {multiSelectOptions.length !== 0 && (
            <div className={styles.multiselectOptions}>
              <Button
                kind="tertiary"
                size="sm"
                renderIcon={isSelectingMultiple ? Close : ListChecked}
                onClick={() => {
                  if (isSelectingMultiple) {
                    clearSelectingMultiple();
                  } else {
                    setIsSelectingMultiple(true);
                  }
                }}
                disabled={ideas?.length === 0}
              >
                {isSelectingMultiple ? 'Cancel select multiple' : 'Select multiple ideas'}
              </Button>
              {isSelectingMultiple && multiSelectOptions}
            </div>
          )}
        </div>
        <div className={styles.exportToCsvButton}>
          <TooltipIcon direction="top" align="end" tooltipText={exportCSVLabelText}>
            <Button
              kind="ghost"
              size="sm"
              renderIcon={Download}
              disabled={ideas?.length === 0 || totalItems > 1000}
              href={getIdeasCSVURL(getAPIParams(false))}
              download="IBM Ideas.csv"
              aria-label={exportCSVLabelText}
            >
              Export CSV
            </Button>
          </TooltipIcon>
        </div>
      </div>
      {(getIdeasAPI.state === 'idle' || getIdeasAPI.state === 'loading' || isInvalidPage || isChangingPage) ? (
        <LoadingSpinner text="Loading ideas..." />
      ) : (
        getIdeasAPI.state === 'error' ? (
          <InlineNotification
            className={styles.errorMessage}
            kind="error"
            title="Failed to load ideas"
            subtitle="Please refresh to try again"
            lowContrast
            hideCloseButton
          />
        ) : ideas?.length === 0 ? (
          areFiltersApplied ? (
            <p>There are no ideas that match the current filters</p>
          ) : (
            noIdeasText
          )
        ) : (
          <>
            <span className={styles.listTotal}>List contains {totalItems} ideas.</span>
            <div>
              {ideas?.map((idea) => (
                <div key={idea.id} className={styles.ideaRow}>
                  {isSelectingMultiple && (
                    <div className={styles.ideaSelectCheckbox}>
                      <Checkbox
                        id={`select-idea-${idea.id}`}
                        labelText=""
                        onChange={(value: boolean) => toggleIdeaSelected(idea.id, value)}
                        checked={selectedIdeaIds.includes(idea.id)}
                      />
                    </div>
                  )}
                  <IdeaCard
                    idea={idea}
                    onTagClick={(tag) => addFilterTag(tag.id)}
                    onIBMManagedTagClick={(tag) => addFilterIBMManagedTag(tag)}
                    onRemove={() => refreshListing()}
                  />
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={changePage}
            />
          </>
        )
      )}
      <MultiAddToGroupDialog
        selectedIdeaIds={selectedIdeaIds}
        isOpen={isMultiAddToGroupDialogOpen}
        onClose={() => setIsMultiAddToGroupDialogOpen(false)}
        onSubmit={() => { clearSelectingMultiple(); refreshListing(); }}
      />
      <MultiAddTagDialog
        selectedIdeaIds={selectedIdeaIds}
        isOpen={isMultiAddTagDialogOpen}
        onClose={() => setIsMultiAddTagDialogOpen(false)}
        onSubmit={() => { clearSelectingMultiple(); refreshListing(); }}
      />
      <MultiAddManagedTagDialog
        selectedIdeaIds={selectedIdeaIds}
        isOpen={isMultiAddManagedTagDialogOpen}
        onClose={() => setIsMultiAddManagedTagDialogOpen(false)}
        onSubmit={() => { clearSelectingMultiple(); refreshListing(); }}
      />
    </>
  );
}

export default IdeasListing;
