import { Search } from 'carbon-components-react';
import debounce from 'lodash.debounce';
import { useMemo } from 'react';

interface SearchInputProps {
  size?: 'lg' | 'sm';
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onSearchChange: (value: string) => void;
  onClear?: () => void;
}

function SearchInput({ size = 'lg', placeholder, value, onChange, onSearchChange, onClear }: SearchInputProps) {
  const debouncedOnChange = useMemo(() => debounce(onSearchChange, 500), [onSearchChange]);

  const setSearchInput = (newValue: string) => {
    onChange(newValue);

    if (newValue) {
      debouncedOnChange(newValue);
    } else {
      debouncedOnChange.cancel();
      onSearchChange('');
    }
  };

  return (
    <Search
      labelText={placeholder}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value)}
      onClear={() => onClear && onClear()}
      placeholder={placeholder}
      size={size}
    />
  );
}

export default SearchInput;
