import { Outlet, useParams } from 'react-router-dom';
import { IssueTrackerFiltersProvider } from '../../context/IssueTrackerFiltersContext';

function IssueTrackerFiltersContainer() {
  const { parentSlug } = useParams();

  return (
    <IssueTrackerFiltersProvider key={parentSlug}>
      <Outlet />
    </IssueTrackerFiltersProvider>
  );
}

export default IssueTrackerFiltersContainer;
