import { Add } from '@carbon/icons-react';
import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ButtonSpinner from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useToastContext } from '../../../../context/ToastContext';
import { addIdeasToCluster } from '../../../../utils/api';
import styles from './AddIdeaToClusterDialog.module.css';

interface AddIdeaToClusterDialogProps {
  itemId: string;
  clusterIdeaRefs: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function AddIdeaToClusterDialog({ itemId, clusterIdeaRefs, isOpen, onClose, onSubmit }: AddIdeaToClusterDialogProps) {
  const { addToast } = useToastContext();
  const [isAddingIdeaToCluster, setIsAddingIdeaToCluster] = useState(false);
  const [newClusterIdeaId, setNewClusterIdeaId] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      setIsAddingIdeaToCluster(false);
      setNewClusterIdeaId('');
      setError('');
    }
  }, [isOpen]);

  const onAddIdeaToCluster = async () => {
    const newRef = newClusterIdeaId.trim().toUpperCase();

    if (!newRef.includes('-I-')) {
      setError('Invalid idea reference');
      return;
    }

    if (clusterIdeaRefs.includes(newRef)) {
      setError(`Idea ${newRef} is already in the cluster`);
      return;
    }

    setIsAddingIdeaToCluster(true);
    try {
      await addIdeasToCluster(itemId, newRef);
      addToast({
        kind: 'success',
        title: `Idea ${newRef} has been added to the cluster`
      });
      onSubmit();
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add idea to cluster',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsAddingIdeaToCluster(false);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.AddIdeaToClusterDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title="Add Idea to Cluster" />
      <ModalBody>
        <TextInput
          id="cluster-idea-reference"
          labelText="Cluster with idea reference:"
          placeholder="ABC-I-123"
          invalid={!!error}
          invalidText={error}
          value={newClusterIdeaId}
          maxLength={30}
          size="sm"
          autoComplete="off"
          onChange={(e) => setNewClusterIdeaId(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" disabled={isAddingIdeaToCluster} onClick={onClose}>Cancel</Button>
        <ButtonSpinner
          className={styles.submitContainer}
          type="submit"
          icon={Add}
          isLoading={isAddingIdeaToCluster}
          loadingText="Adding idea to cluster..."
          onClick={onAddIdeaToCluster}
        >
          Add idea to cluster
        </ButtonSpinner>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default AddIdeaToClusterDialog;
