import { Locked } from '@carbon/icons-react';
import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './AdvancedSearchDialog.module.css';

export interface AdvancedSearchInput {
  customer: string;
  creator: string;
  assignee: string;
}

interface AdvancedSearchDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onClear: () => void;
  onSearch: (query: string, advancedSearchInput: AdvancedSearchInput) => void;
  initialQueryInput: string
  initialAdvancedSearchInput: AdvancedSearchInput;
}

function AdvancedSearchDialog({ isOpen, onClose, onClear, onSearch, initialQueryInput, initialAdvancedSearchInput }: AdvancedSearchDialogProps) {
  const [query, setQuery] = useState('');
  const [customer, setCustomer] = useState('');
  const [creator, setCreator] = useState('');
  const [assignee, setAssignee] = useState('');

  useEffect(() => {
    setQuery(initialQueryInput);
    setCustomer(initialAdvancedSearchInput.customer);
    setCreator(initialAdvancedSearchInput.creator);
    setAssignee(initialAdvancedSearchInput.assignee);
  }, [isOpen]);

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.AdvancedSearchDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
      preventCloseOnClickOutside
    >
      <ModalHeader title="Advanced Search" />
      <ModalBody hasScrollingContent>
        <TextInput
          className={styles.formInput}
          id="input-query"
          labelText="Search Query"
          placeholder="Idea title or description"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <p className={styles.privilegedContent}>
          <Locked /> As an IBM employee, you are able to search for ideas using the following privileged fields
        </p>
        <form>
          <TextInput
            className={styles.formInput}
            id="input-customer"
            labelText="Customer"
            placeholder="Company name"
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
          />
        </form>
        <form>
          <TextInput
            className={styles.formInput}
            id="input-creator"
            labelText="Idea creator"
            placeholder="Creator email address or domain"
            value={creator}
            onChange={(e) => setCreator(e.target.value)}
          />
        </form>
        <form>
          <TextInput
            className={styles.formInput}
            id="input-assignee"
            labelText="Assignee"
            placeholder="Assignee email address"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" onClick={() => { onClose(); onClear(); }}>Clear</Button>
        <Button kind="primary" onClick={() => { onClose(); onSearch(query, { customer, creator, assignee }); }}>Search</Button>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default AdvancedSearchDialog;
