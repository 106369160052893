import { createContext, useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { Group } from '../models/group';
import { Tag } from '../models/tag';
import { getGroups, getGroupTags } from '../utils/api';

interface GroupsContextInterface {
  isLoadingGroups: boolean;
  isLoadingGroupsSuccess: boolean;
  groups: Group[];
  fetchGroups: () => void;
  fetchCurrentGroupTags: () => void;
  setCurrentGroupId: (groupId: string | null) => void;
  currentGroup?: Group;
  removeCurrentGroupAdmin: () => void;
  decrementMemberRequestCount: () => void;
  isLoadingGroupTags: boolean;
  currentGroupTags: Tag[];
  addGroupTag: (tag: Tag) => void;
  updateGroupTag: (tag: Tag) => void;
  removeGroupTag: (tag: Tag) => void;
  getNextTagColour: () => string;
}

export const TAG_COLOURS = ['#ffd7d9', '#ffd6e8', '#e8daff', '#d0e2ff', '#bae6ff', '#9ef0f0', '#a7f0ba', '#e0e0e0'];

const chooseRandom = <T,>(arr: T[]) => arr[Math.floor(Math.random() * arr.length)];

const GroupsContext = createContext<GroupsContextInterface | null>(null);

export function useGroupsContext() {
  return useContext(GroupsContext)!;
}

interface GroupsProviderProps {
  children: React.ReactNode
}

export function GroupsProvider({ children }: GroupsProviderProps) {
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isLoadingGroupsSuccess, setIsLoadingGroupsSuccess] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);
  const [isLoadingGroupTags, setIsLoadingGroupTags] = useState(false);
  const [currentGroupTags, setCurrentGroupTags] = useState<Tag[]>([]);

  const currentGroup = useMemo(
    () => groups.find((g) => g.id === currentGroupId),
    [groups, currentGroupId]
  );

  const fetchGroups = async () => {
    setIsLoadingGroups(true);

    try {
      const { data } = await getGroups();
      setGroups(data);
      setIsLoadingGroupsSuccess(true);
    } catch (error) {
      setIsLoadingGroupsSuccess(false);
    }

    setIsLoadingGroups(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchCurrentGroupTags = async () => {
    if (!currentGroup) {
      setCurrentGroupTags([]);
      setIsLoadingGroupTags(false);
      return;
    }

    setIsLoadingGroupTags(true);
    const { data } = await getGroupTags(currentGroup.id);
    setCurrentGroupTags(data);
    setIsLoadingGroupTags(false);
  };

  useEffect(() => {
    fetchCurrentGroupTags();
  }, [currentGroup?.id]);

  const addGroupTag = useCallback((tag: Tag) => {
    setCurrentGroupTags((prevTags) => [...prevTags, tag].sort((a, b) => a.name.localeCompare(b.name)));
  }, [setCurrentGroupTags]);

  const updateGroupTag = useCallback((tag: Tag) => {
    setCurrentGroupTags((prevTags) => prevTags.map((t) => {
      if (t.id === tag.id) {
        return tag;
      }
      return t;
    }));
  }, [setCurrentGroupTags]);

  const removeGroupTag = useCallback((tag: Tag) => {
    setCurrentGroupTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
  }, [setCurrentGroupTags]);

  const removeCurrentGroupAdmin = useCallback(() => {
    if (!currentGroup) return;

    setGroups((prevGroups) => prevGroups.map((g) => {
      if (g.id === currentGroup.id) {
        return { ...g, is_admin: false };
      }
      return g;
    }));
  }, [currentGroup, setGroups]);

  const decrementMemberRequestCount = useCallback(() => {
    if (!currentGroup) return;

    setGroups((prevGroups) => prevGroups.map((g) => {
      if (g.id === currentGroup.id && g.user_request_count) {
        return { ...g, user_request_count: g.user_request_count - 1 };
      }
      return g;
    }));
  }, [currentGroup, setGroups]);

  const getNextTagColour = useCallback(() => {
    const tagColours = currentGroupTags.map((t) => t.colour);

    // Count the number of times each tag colour has been used
    const colourCounts: { [key: string]: number } = Object.assign({}, ...Array.from(TAG_COLOURS, (c) => ({ [c]: 0 })));

    tagColours.forEach((c) => {
      if (c in colourCounts) {
        colourCounts[c] += 1;
      }
    });

    // Find tag colours that has been used the minimum number of times
    const minCount = Math.min(...Object.values(colourCounts));
    const minUsedColours = TAG_COLOURS.filter((c) => colourCounts[c] === minCount);

    // Pick a random colour from minimum used colours
    return chooseRandom(minUsedColours);
  }, [currentGroupTags]);

  const groupsContext: GroupsContextInterface = useMemo(() => ({
    fetchGroups,
    fetchCurrentGroupTags,
    isLoadingGroups,
    isLoadingGroupsSuccess,
    groups,
    setCurrentGroupId,
    currentGroup,
    removeCurrentGroupAdmin,
    decrementMemberRequestCount,
    isLoadingGroupTags,
    currentGroupTags,
    addGroupTag,
    updateGroupTag,
    removeGroupTag,
    getNextTagColour,
  }), [
    fetchGroups,
    fetchCurrentGroupTags,
    isLoadingGroups,
    isLoadingGroupsSuccess,
    groups,
    setCurrentGroupId,
    currentGroup,
    removeCurrentGroupAdmin,
    decrementMemberRequestCount,
    isLoadingGroupTags,
    currentGroupTags,
    addGroupTag,
    updateGroupTag,
    removeGroupTag,
    getNextTagColour,
  ]);

  return (
    <GroupsContext.Provider value={groupsContext}>
      {children}
    </GroupsContext.Provider>
  );
}

export default GroupsContext;
