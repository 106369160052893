import { Settings } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useMemo } from 'react';
import { Link, Navigate } from 'react-router-dom';
import IdeasListing from '../../components/IdeasListing/IdeasListing';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useProductsContext } from '../../context/ProductsContext';
import styles from './FollowedProducts.module.css';

function FollowedProducts() {
  const { followedProducts, isLoadingFollowedProducts } = useProductsContext();

  const queryParams = useMemo(() => {
    const productIds: string[] = [];
    const categoryIds: string[] = [];

    for (const followedItem of followedProducts) {
      if (followedItem.idea_category) {
        categoryIds.push(followedItem.idea_category.id);
      } else {
        productIds.push(followedItem.id);
      }
    }

    return { product_ids: productIds, category_ids: categoryIds };
  }, [followedProducts]);

  if (!isLoadingFollowedProducts && followedProducts.length === 0) {
    return <Navigate to="/settings/products" replace />;
  }

  return (
    <>
      <header className={styles.header}>
        <h2>My Followed Products</h2>
        <Button as={Link} to="/settings/products" kind="tertiary" size="sm" renderIcon={Settings}>Manage Followed Products</Button>
      </header>
      { isLoadingFollowedProducts ? (
        <LoadingSpinner text="Loading followed products..." />
      ) : (
        <IdeasListing
          queryParams={queryParams}
          showQueryFilter
          hideProductFilter
          noIdeasText="There are no ideas in your followed products"
        />
      )}

    </>
  );
}

export default FollowedProducts;
