import { Dropdown } from 'carbon-components-react';
import styles from './SortDropdown.module.css';

interface SortOption {
  text: string;
  value: string;
}

const sortOptions: SortOption[] = [
  { text: 'Recently created', value: 'created_at' },
  { text: 'Recently updated', value: 'updated_at' },
  { text: 'Votes', value: 'vote_count' },
  { text: 'Comments', value: 'comment_count' },
];

const relevanceOption: SortOption = { text: 'Relevance', value: 'relevance' };

interface SortDropdownProps {
  selectedValue: string;
  onChange: (value: string) => void;
  showRelevance?: boolean;
}

function SortDropdown({ selectedValue, onChange, showRelevance = false }: SortDropdownProps) {
  const options = showRelevance ? [relevanceOption, ...sortOptions] : sortOptions;

  const selectedSortOption = options.find((sortOption) => sortOption.value === selectedValue);

  return (
    <div className={styles.sortDropdownContainer}>
      <Dropdown
        className={styles.sortDropdown}
        id="sort-dropdown"
        titleText="Sort by:"
        label="Sort by"
        type="inline"
        light
        size="sm"
        items={options}
        itemToString={(item: SortOption) => item.text}
        selectedItem={selectedSortOption}
        onChange={({ selectedItem }: { selectedItem: SortOption }) => {
          onChange(selectedItem.value);
        }}
      />
    </div>
  );
}

export default SortDropdown;
