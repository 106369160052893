import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FollowProduct } from '../models/product';
import {
  getFollowedProducts,
  followProduct as followProductAPI,
  unfollowProduct as unfollowProductAPI,
  followProductCategory as followProductCategoryAPI,
  unfollowProductCategory as unfollowProductCategoryAPI,
} from '../utils/api';
import { useToastContext } from './ToastContext';

interface ProductsContextInterface {
  followedProducts: FollowProduct[];
  followedProductsMenu: FollowProduct[];
  isLoadingFollowedProducts: boolean;
  isFollowingProduct: (productId: string, categoryId?: string | null) => boolean;
  followProduct: (product: FollowProduct) => void;
  unfollowProduct: (productId: string, categoryId?: string | null) => void;
}

const ProductsContext = createContext<ProductsContextInterface | null>(null);

export function useProductsContext() {
  return useContext(ProductsContext)!;
}

interface ProductsProviderProps {
  children: React.ReactNode
}

export function ProductsProvider({ children }: ProductsProviderProps) {
  const { addToast } = useToastContext();

  const [followedProducts, setFollowedProducts] = useState<FollowProduct[]>([]);
  const [isLoadingFollowedProducts, setIsLoadingFollowedProducts] = useState(true);

  useEffect(() => {
    const fetchFollowedProducts = async () => {
      const { data } = await getFollowedProducts();
      setFollowedProducts(data);
      setIsLoadingFollowedProducts(false);
    };
    fetchFollowedProducts();
  }, []);

  const followedProductsMenu = useMemo(() => {
    const menuItems: FollowProduct[] = [...followedProducts];

    // Check that each category menu item has a parent menu item
    // If it does not, then insert a placeholder parent menu item
    for (const followedProduct of followedProducts) {
      if (followedProduct.idea_category) {
        const foundParent = menuItems.find((otherFollowedProduct) => otherFollowedProduct.id === followedProduct.id && otherFollowedProduct.idea_category === null);
        if (!foundParent) {
          menuItems.push({ id: followedProduct.id, name: followedProduct.name, idea_category: null, is_not_following: true });
        }
      }
    }

    menuItems.sort((a, b) => {
      const compareProductNames = a.name.localeCompare(b.name);
      if (compareProductNames !== 0) {
        return compareProductNames;
      }

      if (a.idea_category === null) {
        return -1;
      }

      if (b.idea_category === null) {
        return 1;
      }

      return a.idea_category.name.localeCompare(b.idea_category.name);
    });

    return menuItems;
  }, [followedProducts]);

  // eslint-disable-next-line arrow-body-style
  const findFollowedProduct = (productId: string, categoryId: string | null = null) => {
    return followedProducts.find((followedProduct) => {
      if (followedProduct.id !== productId) return false;
      // Product ID matches

      if (categoryId === null && followedProduct.idea_category === null) {
        // Product following
        return true;
      }

      if (categoryId && followedProduct.idea_category && followedProduct.idea_category.id === categoryId) {
        // Category following
        return true;
      }

      return false;
    });
  };

  const isFollowingProduct = (productId: string, categoryId: string | null = null) => !!findFollowedProduct(productId, categoryId);

  const localFollowProduct = (newProduct: FollowProduct) => {
    setFollowedProducts((prevFollowedProducts) => [...prevFollowedProducts, newProduct]);
  };

  const localUnfollowProduct = (productId: string, categoryId: string | null = null) => {
    const productToRemove = findFollowedProduct(productId, categoryId);

    setFollowedProducts(
      (prevFollowedProducts) => prevFollowedProducts.filter((followedProduct) => followedProduct !== productToRemove)
    );
  };

  const followProduct = async (newProduct: FollowProduct) => {
    if (isFollowingProduct(newProduct.id, newProduct.idea_category?.id || null)) {
      // Already following product
      return;
    }

    localFollowProduct(newProduct);

    try {
      if (newProduct.idea_category) {
        await followProductCategoryAPI(newProduct.id, newProduct.idea_category.id);
      } else {
        await followProductAPI(newProduct.id);
      }
    } catch {
      localUnfollowProduct(newProduct.id, newProduct.idea_category?.id || null);
      addToast({
        kind: 'error',
        title: 'Failed to follow product',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const unfollowProduct = async (productId: string, categoryId: string | null = null) => {
    const unfollowingProduct = findFollowedProduct(productId, categoryId);
    if (!unfollowingProduct) return;

    localUnfollowProduct(productId, categoryId);

    try {
      if (categoryId) {
        await unfollowProductCategoryAPI(productId, categoryId);
      } else {
        await unfollowProductAPI(productId);
      }
    } catch {
      localFollowProduct(unfollowingProduct);
      addToast({
        kind: 'error',
        title: 'Failed to unfollow product',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const productsContext: ProductsContextInterface = useMemo(() => ({
    followedProducts,
    followedProductsMenu,
    isLoadingFollowedProducts,
    isFollowingProduct,
    followProduct,
    unfollowProduct,
  }), [
    followedProducts,
    followedProductsMenu,
    isLoadingFollowedProducts,
  ]);

  return (
    <ProductsContext.Provider value={productsContext}>
      {children}
    </ProductsContext.Provider>
  );
}

export default ProductsContext;
