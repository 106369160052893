import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGroupsContext } from '../../../context/GroupsContext';
import { useToastContext } from '../../../context/ToastContext';
import { GroupMember } from '../../../models/groupMember';
import { getGroupMembers, removeGroupMember, updateGroupMember } from '../../../utils/api';
import AddGroupMember from './AddGroupMember/AddGroupMember';
import GroupMembersTable from './GroupMembersTable/GroupMembersTable';
import styles from './GroupMembers.module.css';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useUserContext } from '../../../context/UserContext';
import GroupMemberRequestsTable from './GroupMemberRequestsTable/GroupMemberRequestsTable';
import GroupMemberRequestLogTable from './GroupMemberRequestLogTable/GroupMemberRequestLogTable';

function GroupMembers() {
  const { user } = useUserContext();
  if (!user) return null;

  const { addToast } = useToastContext();
  const { fetchGroups, currentGroup, removeCurrentGroupAdmin } = useGroupsContext();
  const navigate = useNavigate();

  const [isLoadingGroupMembers, setIsLoadingGroupMembers] = useState(true);
  const [groupMembers, setGroupMembers] = useState<GroupMember[]>([]);

  const [isRemoveSelfAdminDialogOpen, setIsRemoveSelfAdminDialogOpen] = useState(false);
  const [isRemoveSelfDialogOpen, setIsRemoveSelfDialogOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<GroupMember>();

  const fetchUsers = async () => {
    if (!currentGroup) return;
    setIsLoadingGroupMembers(true);
    const { data } = await getGroupMembers(currentGroup.id);
    setGroupMembers(data);
    setIsLoadingGroupMembers(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [currentGroup?.id]);

  const onAddMember = async (newMember: GroupMember) => {
    setGroupMembers((prevGroupMembers) => [...prevGroupMembers, newMember].sort((a, b) => a.email.localeCompare(b.email)));
  };

  const removeMember = async (groupMember: GroupMember) => {
    if (!currentGroup) return;
    const { email } = groupMember;
    const isSelf = groupMember.email.toLowerCase() === user.email.toLowerCase();

    try {
      await removeGroupMember(currentGroup.id, email);
      setGroupMembers((prevGroupMembers) => prevGroupMembers.filter((m) => m.email !== email));
      let leavingTitle = 'Member removed from the group';
      let leavingMessage = `${email} has been removed from the group`;

      if (isSelf) {
        navigate('/groups');
        await fetchGroups();
        leavingTitle = 'Left the group';
        leavingMessage = `You have left ${currentGroup.name}`;
      }
      addToast({
        kind: 'success',
        title: leavingTitle,
        message: leavingMessage
      });
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed to remove group member',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const onRemoveMember = async (groupMember: GroupMember) => {
    const isSelf = groupMember.email.toLowerCase() === user.email.toLowerCase();

    if (isSelf) {
      setMemberToRemove(groupMember);
      setIsRemoveSelfDialogOpen(true);
    } else {
      removeMember(groupMember);
    }
  };

  const changeMemberAdmin = async (email: string, isAdmin: boolean) => {
    if (!currentGroup) return;

    const groupMember = groupMembers.find((m) => m.email.toLowerCase() === email.toLowerCase());
    if (!groupMember) return;

    const oldGroupMember = { ...groupMember };
    const newGroupMember = { ...groupMember, group_admin: isAdmin };

    setGroupMembers((prevGroupMembers) => prevGroupMembers.map((m) => {
      if (m.email === groupMember.email) {
        return newGroupMember;
      }
      return m;
    }));

    try {
      await updateGroupMember(currentGroup.id, newGroupMember);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to update group member',
        message: 'Something went wrong, please try again later'
      });

      setGroupMembers((prevGroupMembers) => prevGroupMembers.map((m) => {
        if (m.email === groupMember.email) {
          return oldGroupMember;
        }
        return m;
      }));
    }
  };

  const onChangeMemberAdmin = async (groupMember: GroupMember, isAdmin: boolean) => {
    const loggedInEmail = user?.email.toLowerCase();

    if (groupMember.email.toLowerCase() === loggedInEmail) {
      const isOtherAdmin = groupMembers.some(
        (m) => m.group_admin && m.email.toLowerCase() !== loggedInEmail
      );

      if (isOtherAdmin) {
        setIsRemoveSelfAdminDialogOpen(true);
      } else {
        addToast({
          kind: 'error',
          title: 'Failed to remove admin role',
          message: 'Please assign another member as admin before removing yourself'
        });
      }
    } else {
      changeMemberAdmin(groupMember.email, isAdmin);
    }
  };

  if (!currentGroup) return null;

  return (
    <>
      <h3 className={styles.header}>Group Members</h3>
      <section className={styles.groupMembersSection}>
        {currentGroup.is_admin ? (
          <p className={styles.note}>As an admin, you can manage group members</p>
        ) : (
          <p className={styles.note}>Only admins are able to add and manage group members</p>
        )}
        <GroupMembersTable
          isLoading={isLoadingGroupMembers}
          groupMembers={groupMembers}
          onRemove={onRemoveMember}
          onChangeAdmin={onChangeMemberAdmin}
        />
        <ConfirmationDialog
          isOpen={isRemoveSelfDialogOpen}
          onClose={() => setIsRemoveSelfDialogOpen(false)}
          onSubmit={() => removeMember(memberToRemove!)}
          message="Are you sure you want to leave the group?"
          submitButtonText="Leave group"
        />
      </section>
      {currentGroup.is_admin && (
        <AddGroupMember onAdd={onAddMember} currentMembers={groupMembers} />
      )}
      {currentGroup.is_admin && (
        <>
          <section className={styles.groupMemberRequestsSection} id="requests">
            <h3 className={styles.header}>Group Member Requests</h3>
            <p className={styles.note}>As an admin, you can approve or reject group member requests</p>
            <GroupMemberRequestsTable onApprove={onAddMember} />
          </section>
          <section className={styles.groupMemberRequestsSection} id="request-logs">
            <h3 className={styles.header}>Group Member Log</h3>
            <GroupMemberRequestLogTable />
          </section>
        </>
      )}
      <ConfirmationDialog
        isOpen={isRemoveSelfAdminDialogOpen}
        onClose={() => setIsRemoveSelfAdminDialogOpen(false)}
        onSubmit={() => {
          if (!user) return;
          changeMemberAdmin(user.email, false);
          removeCurrentGroupAdmin();
        }}
        message="Are you sure you want to remove your own admin role?"
        submitButtonText="Remove my admin role"
      />
    </>
  );
}

export default GroupMembers;
