import { Add, Checkmark, Locked, Subtract, Tag as TagIcon, Time, ViewOff } from '@carbon/icons-react';
import { Button, ComboBox, InlineLoading, OverflowMenu, OverflowMenuItem, TooltipDefinition, TooltipIcon } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Idea } from '../../models/idea';
import { addIdeasToGroup, addNewTagToIdeas, addTagToIdeas, removeIdeasFromGroup, removeTagFromIdeas, addIBMWideManagedTagToIdeas, removeIBMWideManagedTagFromIdeas, setIssueTrackerInclusion } from '../../utils/api';
import { lighten } from '../../utils/colors';
import { formatDate } from '../../utils/dates';
import { Tag as TagModel } from '../../models/tag';
import Tag from '../Tag/Tag';
import styles from './IdeaCard.module.css';
import { Group } from '../../models/group';
import { useFlashContext } from '../../context/FlashContext';
import { useGroupsContext } from '../../context/GroupsContext';
import { useCustomFieldsContext } from '../../context/CustomFieldsContext';
import { useToastContext } from '../../context/ToastContext';
import { useUserContext } from '../../context/UserContext';
import IconButtonAutoTooltip from '../IconButtonAutoTooltip/IconButtonAutoTooltip';
import EditIdeaDialog from './EditIdeaDialog/EditIdeaDialog';
import { commaSeparate } from '../../utils/format';
import { getVisibilityText } from '../../utils/ideaVisibility';
import { IssueTrackerInclusionState } from '../../utils/issueTrackerInclusionStates';
import { WORKFLOW_TOOLTIP_TEXTS } from '../../utils/workflowState';
import { getIdeaIssueTrackerURL } from '../../utils/issueTracker';
import Votes from '../Votes/Votes';
import CommentButton from '../CommentButton/CommentButton';

interface IdeaCardProps {
  idea: Idea;
  onRemove?: () => void;
  onTagClick: (tag: TagModel) => void;
  onIBMManagedTagClick: (tag: string) => void;
}

function IdeaCard({ idea, onRemove, onTagClick, onIBMManagedTagClick }: IdeaCardProps) {
  const { isIBMEmployee, isProductScoutWWLeader, isProductScoutGeoLeader } = useUserContext();
  const { addToast } = useToastContext();
  const { groups, currentGroup, currentGroupTags, addGroupTag, getNextTagColour, fetchGroups, fetchCurrentGroupTags } = useGroupsContext();
  const { newIdeaId, setNewIdeaId } = useFlashContext();
  const { managedTags } = useCustomFieldsContext();

  const isNewIdea = useRef(newIdeaId === idea.id);

  useEffect(() => {
    if (isNewIdea.current) {
      setNewIdeaId(null);
    }
  }, []);

  const { groupId } = useParams();

  const [isAddingTag, setIsAddingTag] = useState(false);
  const [tags, setTags] = useState<TagModel[]>(idea.tags || []);

  const [isAddingManagedTag, setIsAddingManagedTag] = useState(false);
  const [ibmManagedTags, setIBMManagedTags] = useState(idea.ibm_wide_managed_tags || []);

  const [isAddingToGroup, setIsAddingToGroup] = useState(false);
  const [isRemovingFromGroup, setIsRemovingFromGroup] = useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isMarkingAsCandidate, setIsMarkingAsCandidate] = useState(false);
  const [editDialogOpenCount, setEditDialogOpenCount] = useState(0);

  const unusedTags = currentGroupTags.filter((groupTag) => !tags.some((ideaTag) => ideaTag.id === groupTag.id));

  const [issueTrackerInc, setIssueTrackerInc] = useState(idea.issue_tracker_inclusion);

  const addToGroup = async (group: Group) => {
    setIsAddingToGroup(true);

    try {
      await addIdeasToGroup(group.id, [idea.id]);
      addToast({
        kind: 'success',
        title: 'Idea added to group',
        message: <>The idea has been added to the group <em>{group.name}</em></>
      });

      // Update locally so that the group is ticked in the dropdown
      if (!idea.shared_groups) {
        idea.shared_groups = [];
      }
      idea.shared_groups.push(group.id);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add idea to group',
        message: 'Something went wrong, please try again later'
      });
    }

    await fetchGroups();
    setIsAddingToGroup(false);
  };

  const removeFromGroup = async () => {
    if (!currentGroup) return;

    setIsRemovingFromGroup(true);

    try {
      await removeIdeasFromGroup(currentGroup.id, [idea.id]);

      if (onRemove) {
        onRemove();
      }

      addToast({
        kind: 'success',
        title: 'Idea removed from group',
      });
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove idea from group',
        message: 'Something went wrong, please try again later'
      });
    }

    await fetchGroups();
    setIsRemovingFromGroup(false);
  };

  const removeTag = async (tag: TagModel) => {
    if (!currentGroup) return;

    setTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));

    try {
      await removeTagFromIdeas(currentGroup?.id, [idea.id], tag.id);
      await fetchCurrentGroupTags();
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove tag from idea',
        message: 'Something went wrong, please try again later'
      });
      setTags((prevTags) => [...prevTags, tag]);
    }
  };

  const removeIBMWideManagedTag = async (tag: string) => {
    setIBMManagedTags((prevManagedTags) => prevManagedTags.filter((t) => t !== tag));
    try {
      await removeIBMWideManagedTagFromIdeas([idea.id], tag);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove ibm wide managed tag from idea',
        message: 'Something went wrong, please try again later'
      });
      setIBMManagedTags((prevManagedTags) => [...prevManagedTags, tag]);
    }
  };

  const addIBMWideManagedTag = async (tag: string) => {
    setIBMManagedTags((prevManagedTags) => [...prevManagedTags, tag]);
    try {
      await addIBMWideManagedTagToIdeas([idea.id], tag);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add ibm wide managed tag to idea',
        message: 'Something went wrong, please try again later'
      });
      setIBMManagedTags((prevManagedTags) => prevManagedTags.filter((t) => t !== tag));
    }
  };

  const addExistingTag = async (tagId: string) => {
    if (!currentGroup) return;

    const existingTag = currentGroupTags.find((t) => t.id === tagId);
    if (!existingTag) return;

    // Prevent adding the same tag twice
    if (tags.some((t) => t.id === existingTag.id)) return;

    setTags((prevTags) => [...prevTags, existingTag]);

    try {
      await addTagToIdeas(currentGroup.id, [idea.id], existingTag.id);
      await fetchCurrentGroupTags();
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed to add tag to idea',
        message: 'Something went wrong, please try again later'
      });
      setTags((prevTags) => prevTags.filter((t) => t.id !== existingTag.id));
    }
  };

  const addNewTag = async (tagName: string) => {
    if (!currentGroup) return;
    if (!tagName) return;

    const existingTag = currentGroupTags.find((t) => t.name === tagName);

    if (existingTag) {
      addExistingTag(existingTag.id);
      return;
    }

    const newTagId = new Date().valueOf().toString();

    const newTag: TagModel = {
      id: newTagId,
      name: tagName,
      colour: getNextTagColour(),
      isTemp: true,
    };

    setTags((prevTags) => [...prevTags, newTag]);

    try {
      const { data } = await addNewTagToIdeas(currentGroup.id, [idea.id], newTag);

      const newTagReal = { ...newTag, id: data.id, isTemp: false };

      // Update temporary tag ID to real tag ID from API response
      setTags((prevTags) => prevTags.map((t) => {
        if (t.id === newTagId) {
          return newTagReal;
        }
        return t;
      }));
      addGroupTag(newTagReal);
      await fetchCurrentGroupTags();
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed to add tag to idea',
        message: 'Something went wrong, please try again later'
      });
      setTags((prevTags) => prevTags.filter((t) => t.id !== newTagId));
    }
  };

  const unmarkAsIssueTrackerCandidate = async () => {
    setIssueTrackerInc(IssueTrackerInclusionState.UNSET);
    try {
      await setIssueTrackerInclusion(idea.id, IssueTrackerInclusionState.UNSET);
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed to unmark as issue tracker candidate',
        message: 'Something went wrong, please try again later'
      });
      setIssueTrackerInc(IssueTrackerInclusionState.CANDIDATE);
    }
  };

  const openEditDialog = (markAsCandidate: boolean) => {
    setIsMarkingAsCandidate(markAsCandidate);
    setIsEditDialogOpen(true);

    // Key hack to force re-render of edit dialog to reset the form
    setEditDialogOpenCount((prev) => prev + 1);
  };

  const workflowTooltipText = WORKFLOW_TOOLTIP_TEXTS[idea.workflow_state];

  let categoryElements: JSX.Element[] = [];

  if (idea.product_categories && idea.product_categories.length !== 0) {
    categoryElements = idea.product_categories.slice(0, 3).map((category) => (
      <Link key={category.id} to={`/products/${idea.product_id}/categories/${category.id}`}>{category.name}</Link>
    ));

    if (idea.product_categories.length > 3) {
      categoryElements.push(
        <TooltipDefinition tooltipText={idea.product_categories.slice(3).map((category) => category.name).join(', ')}>...</TooltipDefinition>
      );
    }
  }

  const visibilityTooltipText = getVisibilityText(idea.visibility, idea.requester_email, idea.is_requester_creator);

  const issueTrackerURL = getIdeaIssueTrackerURL(idea);

  return (
    <div className={`${styles.ideaCard} ${isNewIdea.current ? styles.newIdea : ''}`}>
      <div className={styles.content}>
        <header>
          <h4>
            {visibilityTooltipText && (
              <TooltipIcon className={styles.visibilityIcon} tooltipText={visibilityTooltipText} align="start"><ViewOff /></TooltipIcon>
            )}
            <Link to={`/ideas/${idea.reference}`}>
              {idea.name} <span className={styles.reference}>[{idea.reference}]</span>
            </Link>
          </h4>
          {idea.workflow_state && (
            <div
              className={styles.workflow}
              style={{
                borderColor: idea.workflow_color,
                backgroundColor: lighten(idea.workflow_color, 0.7)
              }}
            >
              {workflowTooltipText ? (
                <TooltipDefinition direction="top" align="end" tooltipText={workflowTooltipText}>
                  {idea.workflow_state}
                </TooltipDefinition>
              ) : (
                idea.workflow_state
              )}
            </div>
          )}
          <div className={styles.groupBtnsContainer}>
            {groups.length !== 0 && (
              isAddingToGroup ? (
                <InlineLoading className={styles.iconButtonSpinner} />
              ) : (
                <OverflowMenu
                  className={`${styles.addToGroupBtn} bx--tooltip__trigger bx--tooltip--icon__top`}
                  menuOptionsClass={styles.addToGroupMenu}
                  ariaLabel="Add to group"
                  iconDescription="Add to group"
                  flipped
                  size="sm"
                  onClick={(e) => e.preventDefault()}
                  renderIcon={Add}
                >
                  {groups.map((group) => (
                    <OverflowMenuItem
                      key={group.id}
                      itemText={(
                        <div className={styles.groupOption} title={group.name}>
                          {idea.shared_groups && idea.shared_groups.includes(group.id) && (
                            <Checkmark />
                          )}
                          {group.name}
                        </div>
                      )}
                      onClick={() => addToGroup(group)}
                      disabled={idea.shared_groups ? idea.shared_groups.includes(group.id) : false}
                    />
                  ))}
                </OverflowMenu>
              )
            )}
            {groupId && (
              isRemovingFromGroup ? (
                <InlineLoading className={styles.iconButtonSpinner} />
              ) : (
                <Button
                  className={styles.removeFromGroupBtn}
                  size="sm"
                  renderIcon={Subtract}
                  iconDescription="Remove from group"
                  tooltipAlignment="end"
                  hasIconOnly
                  onClick={removeFromGroup}
                />
              )
            )}
          </div>
        </header>
        <div className={styles.description}>
          {idea.description}
        </div>
        <div className={styles.meta}>
          <div>Idea for <Link to={`/products/${idea.product_id}`}>{idea.product}</Link></div>
          {categoryElements.length !== 0 && (
            <div>
              <div>
                (Category: {commaSeparate(categoryElements)})
              </div>
            </div>
          )}
          <Votes idea={idea} className={styles.voteComponent} />
          <CommentButton reference={idea.reference} commentCount={idea.comment_count} />
          <div><Time /> Created on {formatDate(idea.created_at)}</div>
          {groupId && (
            <div>
              <TagIcon className={styles.tagsIcon} />
              <div className={styles.tagsContainer}>
                {tags.map((tag) => (
                  <Tag
                    key={tag.id}
                    name={tag.name}
                    colour={tag.colour}
                    onClick={() => onTagClick(tag)}
                    onRemove={() => removeTag(tag)}
                    disableRemove={tag.isTemp === true}
                  />
                ))}
                {isAddingTag ? (
                  <ComboBox
                    className={styles.addTagComboBox}
                    id="add-tags"
                    items={unusedTags}
                    itemToString={(item) => item?.name || ''}
                    placeholder="Add tag"
                    size="sm"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addNewTag((e.target as HTMLInputElement).value);
                        setIsAddingTag(false);
                      }
                    }}
                    onChange={({ selectedItem }: { selectedItem: TagModel }) => {
                      addExistingTag(selectedItem.id);
                      setIsAddingTag(false);
                    }}
                    onFocus={(e) => e.target.click()}
                    onBlur={() => setIsAddingTag(false)}
                    maxLength={50}
                    autoFocus
                  />
                ) : (
                  <IconButtonAutoTooltip
                    className={styles.addTagBtn}
                    tooltip="Add a tag"
                    icon={Add}
                    onClick={() => setIsAddingTag(true)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isIBMEmployee && (
        <div className={styles.privilegedContent}>
          <TooltipIcon tooltipText="As an IBM employee, you are able to see additional privileged information about this idea">
            <Locked />
          </TooltipIcon>
          <div className={styles.privilegedItemsContainer}>
            <div className={styles.privilegedItem}>
              <strong>Created by:</strong> {idea.requester_email}
            </div>
            {idea.assignee_email && (
              <div className={styles.privilegedItem}>
                <strong>Assignee:</strong> {idea.assignee_email}
              </div>
            )}
            {idea.company_name && (
              <div className={styles.privilegedItem}>
                <strong>Company:</strong> {idea.company_name}
              </div>
            )}
            <div className={styles.privilegedItem}>
              <strong>IBM wide managed tags:</strong>
              <div className={styles.tagsContainer}>
                {ibmManagedTags && ibmManagedTags.length > 0 && (
                  ibmManagedTags.map((tag) => (
                    <Tag
                      key={tag}
                      name={tag}
                      colour="#DDD"
                      onClick={() => onIBMManagedTagClick(tag)}
                      onRemove={() => removeIBMWideManagedTag(tag)}
                    />
                  ))
                )}
                {isAddingManagedTag ? (
                  <ComboBox
                    className={styles.addTagComboBox}
                    autoFocus
                    shouldFilterItem={({ inputValue, item, itemToString }) => {
                      if (!inputValue) return true;
                      return itemToString!(item).toLowerCase().includes(inputValue.toLowerCase());
                    }}
                    id="add-managed-tag"
                    placeholder="Add managed tag"
                    type="inline"
                    size="sm"
                    items={managedTags}
                    onFocus={(e) => e.target.click()}
                    onBlur={() => setIsAddingManagedTag(false)}
                    onChange={({ selectedItem }) => {
                      addIBMWideManagedTag(selectedItem as string);
                      setIsAddingManagedTag(false);
                    }}
                  />
                ) : (
                  <IconButtonAutoTooltip
                    className={styles.addTagBtn}
                    tooltip="Add an IBM Wide Managed tag"
                    icon={Add}
                    onClick={() => setIsAddingManagedTag(true)}
                  />
                )}
              </div>
            </div>
            {(process.env.REACT_APP_ENABLE_ISSUE_TRACKER === 'true') && (
              <div className={styles.privilegedItem}>
                <strong>Issue Tracker Inclusion:</strong>
                {idea.issue_tracker_child_product_slug !== null ? (
                  (issueTrackerInc === IssueTrackerInclusionState.UNSET) ? (
                    <Button kind="ghost" size="sm" onClick={() => openEditDialog(true)}>Mark as candidate</Button>
                  ) : (
                    <>
                      {issueTrackerURL ? (
                        <Link to={issueTrackerURL}>{issueTrackerInc}</Link>
                      ) : (
                        issueTrackerInc
                      )}
                      { issueTrackerInc === IssueTrackerInclusionState.CANDIDATE && (
                        <Button kind="ghost" size="sm" onClick={() => unmarkAsIssueTrackerCandidate()}>Unmark as candidate</Button>
                      )}
                    </>
                  )
                ) : (
                  <>
                    Not in Product Scout scope.<Link to="/issue-tracker" target="_blank">Learn more</Link>
                  </>
                )}
              </div>
            )}
            {(issueTrackerInc !== IssueTrackerInclusionState.UNSET && (isProductScoutWWLeader || isProductScoutGeoLeader)) && (
              <div className={styles.privilegedItem}>
                <Button kind="ghost" size="sm" onClick={() => openEditDialog(false)}>Edit issue tracker fields</Button>
              </div>
            )}
          </div>
          <EditIdeaDialog
            key={editDialogOpenCount}
            idea={idea}
            isOpen={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            onMarkAsCandidate={() => setIssueTrackerInc(IssueTrackerInclusionState.CANDIDATE)}
            isMarkingAsCandidate={isMarkingAsCandidate}
          />
        </div>
      )}
    </div>
  );
}

export default IdeaCard;
