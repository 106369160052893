import { Link, useMatch } from 'react-router-dom';

interface PageTabProps {
  to: string;
  children: React.ReactNode;
}

function PageTab({ to, children }: PageTabProps) {
  const match = useMatch(to);

  const isSelected = match !== null;

  return (
    <li
      className={`bx--tabs__nav-item ${isSelected ? 'bx--tabs__nav-item--selected' : ''}`}
    >
      <Link
        className="bx--tabs__nav-link"
        to={to}
        onClick={(e) => (e.target as HTMLElement).blur()}
      >
        {children}
      </Link>
    </li>
  );
}

export default PageTab;
