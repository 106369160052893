import { createContext, useContext, useMemo, useState } from 'react';

interface CommentContextInterface {
  setCommentId: (commentId: string | null) => void;
  commentId: string | null;
  setCommentCount: (commentCount: number) => void;
  commentCount: number;
}

const CommentContext = createContext<CommentContextInterface | null>(null);

export function useCommentContext() {
  return useContext(CommentContext)!;
}

interface CommentProviderProps {
  children: React.ReactNode;
}

export function CommentProvider({ children }: CommentProviderProps) {
  const [commentId, setCommentId] = useState<string | null>(null);
  const [commentCount, setCommentCount] = useState<number>(0);

  const commentContext = useMemo(() => ({
    setCommentId,
    commentId,
    setCommentCount,
    commentCount
  }), [commentId, commentCount]);

  return (
    <CommentContext.Provider value={commentContext}>
      {children}
    </CommentContext.Provider>
  );
}

export default CommentContext;
