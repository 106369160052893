import { InlineNotification } from 'carbon-components-react';
import { useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import FollowButton from '../../components/FollowButton/FollowButton';
import IdeasListing from '../../components/IdeasListing/IdeasListing';
import { useUserContext } from '../../context/UserContext';
import useAPI from '../../hooks/useAPI';
import { getProducts } from '../../utils/api';
import styles from './SearchResults.module.css';

function SearchResults() {
  const { isIBMEmployee } = useUserContext();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const customer = searchParams.get('customer') || '';
  const creator = searchParams.get('creator') || '';
  const assignee = searchParams.get('assignee') || '';

  const productSearchAPI = useAPI(getProducts);

  useEffect(() => {
    const abortController = new AbortController();
    productSearchAPI.execute(query, 'search', abortController);
    return () => abortController.abort();
  }, [query]);

  const queryParams = useMemo(() => {
    const params = {
      query,
      customer_query: customer,
      creator_email: creator,
      assignee_email: assignee,
    };
    return Object.fromEntries(Object.entries(params).filter(([, v]) => v !== ''));
  }, [searchParams]);

  const isSearch = query || customer || creator || assignee;
  const isAdvancedSearch = customer || creator || assignee;

  const isProductResults = !isAdvancedSearch && productSearchAPI.state === 'success' && productSearchAPI.data && productSearchAPI.data.length > 0;

  return (
    <div className={styles.SearchResults}>
      <h2>Search Results</h2>
      {!isSearch ? (
        <p>
          Looking for some inspiration? Want to see if great minds really do think alike?<br />
          Use the search bar above to start searching through IBM&apos;s public product portals
        </p>
      ) : (
        (isAdvancedSearch && !isIBMEmployee) ? (
          <InlineNotification
            className={styles.errorMessage}
            kind="error"
            title="You do not have permission to see the search results"
            subtitle="Please try a new search"
            lowContrast
            hideCloseButton
          />
        ) : (
          <>
            {isProductResults && (
              <>
                <h4>Product Results</h4>
                <ul className={styles.productList}>
                  {productSearchAPI.data?.map((product) => (
                    <li key={product.id}>
                      <div>
                        <Link to={`/products/${product.id}`}>
                          <div className={styles.productCol}>
                            {product.parent && (
                              <span className={styles.productParentName}>{product.parent.name}</span>
                            )}
                            <span className={styles.productName}>{product.name}</span>
                          </div>
                          <FollowButton product={{ id: product.id, name: product.name, idea_category: null }} />
                        </Link>
                      </div>
                      {product.idea_categories && product.idea_categories.length !== 0 && (
                        <div className={styles.productCategories}>
                          {product.idea_categories?.map((category) => (
                            <Link to={`/products/${product.id}/categories/${category.id}`}>
                              <div className={styles.productCol}>
                                <span className={styles.productName}>{category.name}</span>
                              </div>
                              <FollowButton product={{ id: product.id, name: product.name, idea_category: { id: category.id, name: category.name } }} />
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {isProductResults && <h4>Idea Results</h4>}
            <IdeasListing
              queryParams={queryParams}
              showRelevanceSort
              defaultSort="relevance"
              noIdeasText="There are no ideas that match the search query"
            />
          </>
        )
      )}
    </div>
  );
}

export default SearchResults;
