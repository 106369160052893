import { StarFilled, Locked, User } from '@carbon/icons-react';
import { Column, Grid, Row } from 'carbon-components-react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { NavButton } from '../../../components/Sidebar/Sidebar';
import NotificationBanner from '../../../components/NotificationBanner/NotificationBanner';
import { IssueTrackerProvider, useIssueTrackerContext } from '../context/IssueTrackerContext';
import { useUserContext } from '../../../context/UserContext';
import styles from './IssueTracker.module.css';
import { IssueTrackerParent } from '../models/issueTracker';

function IssueTrackerSidebar() {
  const { menu, currentParent } = useIssueTrackerContext();
  const location = useLocation();

  // Set top level NavButton as end=false if we're on the inclusion tab, so that it still shows as selected in the menu
  const onInclusionPage = location.pathname.includes('/inclusion');

  const topLevelMenuItem = (topLevelItem: IssueTrackerParent) => {
    const heading = topLevelItem.id === 'ibm' || topLevelItem.data_aggregation === false;
    return (
      <li key={topLevelItem.id}>
        <div className={`${styles.toplevel} ${!heading ? styles.topLevelIndented : styles.topLevelTitle}`}>
          <NavButton to={`/issue-tracker/${topLevelItem.slug}`} end={!onInclusionPage}>{topLevelItem.name}</NavButton>
        </div>
        {currentParent && currentParent === topLevelItem && currentParent.id !== 'ibm' && (
        <ul>
          <li><NavButton to={`/issue-tracker/${topLevelItem.slug}/the-big-10`}><StarFilled className={styles.bigTopTenIcon} /> The Big 10</NavButton></li>
          {topLevelItem.children.map((childItem) => (
            <li key={childItem.id}>
              <NavButton to={`/issue-tracker/${topLevelItem.slug}/${childItem.slug}`} end={false}>{childItem.name}</NavButton>
            </li>
          ))}
        </ul>
        )}
      </li>
    );
  };

  return (
    <aside className={styles.sidebar} aria-label="Sidebar">
      <ul className={styles.sidebarList}>
        {menu.map((topLevelItem) => (
          ((topLevelItem.data_aggregation === undefined || topLevelItem.data_aggregation === true) && topLevelMenuItem(topLevelItem))
        ))}
        {menu.map((topLevelItem) => (
          ((topLevelItem.data_aggregation === false) && topLevelMenuItem(topLevelItem))
        ))}
      </ul>
    </aside>
  );
}

function IssueTrackerContent() {
  const { isLoadingMenu } = useIssueTrackerContext();

  if (isLoadingMenu) {
    return <LoadingSpinner text="Loading the Issue Tracker..." />;
  }

  return (
    <div className={styles.container}>
      <IssueTrackerSidebar />

      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  );
}

function IssueTracker() {
  const { isIBMEmployee, isProductScoutWWLeader, isProductScoutGeoLeader, isMarketScout } = useUserContext();
  let userRoleName : string | null = null;

  if (!isIBMEmployee || !(process.env.REACT_APP_ENABLE_ISSUE_TRACKER === 'true')) {
    return <Navigate to="/" />;
  }

  if (isProductScoutWWLeader) {
    userRoleName = 'WW Leader';
  } else if (isProductScoutGeoLeader) {
    userRoleName = 'GEO Leader';
  } else if (isMarketScout) {
    userRoleName = 'Market Scout';
  }

  return (
    <IssueTrackerProvider>
      <Grid>
        <Row>
          <NotificationBanner showBanner />
        </Row>
        <Row>
          <Column>
            <header className={styles.header} aria-label="Product Scout Issue Tracker">
              <div className={styles.internalDisclaimer}>
                <Locked />The Issue Tracker is only visible to IBMers
                {userRoleName && (
                  <div><User />Role: {userRoleName}</div>
                )}
              </div>
              <div>
                <h1 className={styles.mainTitle}>Product Scout Issue Tracker</h1>
                <p className={styles.issueTrackerSlogan}>Tracking ideas from submission to resolution</p>
              </div>
            </header>
          </Column>
        </Row>
        <Row>
          <Column>
            <IssueTrackerContent />
          </Column>
        </Row>
      </Grid>
    </IssueTrackerProvider>
  );
}

export default IssueTracker;
