import { ThumbsDown, ThumbsUp } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { Company } from '../../../models/company';
import styles from './CompanyGuess.module.css';

interface CompanyGuessProps {
  guess: Company,
  onYes: () => void,
  onNo: () => void,
}

function CompanyGuess({ guess, onYes, onNo }: CompanyGuessProps) {
  return (
    <div className={styles.companyGuess}>
      <div>We think you are associated with <strong>{guess.company_name}</strong>. Is this correct?</div>
      <div className={styles.options}>
        <Button
          size="sm"
          renderIcon={ThumbsUp}
          kind="tertiary"
          onClick={() => onYes()}
        >
          Yes
        </Button>
        <Button
          size="sm"
          renderIcon={ThumbsDown}
          kind="tertiary"
          onClick={() => onNo()}
        >
          No
        </Button>
      </div>
    </div>
  );
}

export default CompanyGuess;
