/* eslint-disable import/prefer-default-export */
import { sanitize, addHook } from 'dompurify';

addHook('uponSanitizeElement', (node) => {
  if (node.tagName === 'IMG' && node.hasAttribute('src')) {
    if (!node.getAttribute('src')?.startsWith('https://bigblue.aha.io/attachments/')) {
      const italicEl = document.createElement('i');
      const anchorEl = document.createElement('a');
      const text = node.getAttribute('src') || '';
      anchorEl.href = text;
      anchorEl.textContent = text;

      italicEl.textContent = '(Image from an external source: ';
      italicEl.append(anchorEl, ')');

      node.replaceWith(italicEl);
    }
  }
  return node;
});

// addHook('afterSanitizeElements', (node) => {
//   if (node.tagName === 'LI' && node.firstChild?.nodeName === 'P') {
//     const oldChild = node.firstChild;
//     const newChild = node.firstChild.childNodes;
//     if (newChild) {
//       node.removeChild(oldChild);
//       newChild.forEach((child) => {
//         node.appendChild(child);
//       });
//     }
//   }
// });

addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

addHook('beforeSanitizeAttributes', (node) => {
  if (node.className && node.className.match('labelPill')) {
    let style = 'border: 2px solid; border-radius: 10px; padding: 0.2rem; font-weight: bold; text-transform: uppercase;';
    if (node.className.match('labelPill__gray')) {
      style = style.concat('color: #333333; background-color: #f1f1f1; border-color: #e1e1e1;');
    } else if (node.className.match('labelPill__blue')) {
      style = style.concat('color: #0073cf; background-color: #dbefff; border-color: #b1d4f5;');
    } else if (node.className.match('labelPill__green')) {
      style = style.concat('color: #4f8f0e; background-color: #e8f2d9; border-color: #7bba34;');
    } else if (node.className.match('labelPill__yellow')) {
      style = style.concat('color: #b1901e; background-color: #faebb9; border-color: #f0ca4d;');
    } else if (node.className.match('labelPill__red')) {
      style = style.concat('color: #992e0b; background-color: #fae7e1; border-color: #fac0af;');
    }
    node.setAttribute('style', style);
  }
});

export function sanitizeHTML(dirty: string): string {
  return sanitize(dirty, { ALLOWED_TAGS: [
    'img', 'p', 'br', 'b', 'strong', 'i', 'em', 'u', 'a', 'ul', 'ol', 'li',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
    'table', 'tbody', 'tr', 'td', 'th', 'thead',
    'hr', 'del', 's', 'code', 'pre', 'span', 'label', 'input'] });
}
