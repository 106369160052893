import { Close } from '@carbon/icons-react';
import { Button, Checkbox, InlineLoading } from 'carbon-components-react';
import { useGroupsContext } from '../../../../context/GroupsContext';
import { useUserContext } from '../../../../context/UserContext';
import { GroupMember } from '../../../../models/groupMember';
import styles from './GroupMembersTable.module.css';

interface GroupMembersTableProps {
  isLoading: boolean;
  groupMembers: GroupMember[];
  onRemove: (groupMember: GroupMember) => void;
  onChangeAdmin: (gropuMember: GroupMember, isAdmin: boolean) => void;
}

function GroupMembersTable({ isLoading, groupMembers, onRemove, onChangeAdmin }: GroupMembersTableProps) {
  const { user } = useUserContext();
  if (!user) return null;

  const { currentGroup } = useGroupsContext();
  if (!currentGroup) return null;

  const admins = groupMembers.filter((m) => m.group_admin);
  const isLastAdmin = currentGroup.is_admin && (admins.length === 1);

  if (isLoading) {
    return <InlineLoading description="Loading group members..." />;
  }

  return (
    <table className={styles.membersTable}>
      <thead>
        <tr>
          <th>User</th>
          <th className={styles.colAdmin}>Admin</th>
          {currentGroup.is_admin && (
            <th aria-label="Remove" />
          )}
        </tr>
      </thead>
      <tbody>
        {groupMembers.map((groupMember) => (
          <tr key={groupMember.email}>
            <td>{groupMember.email}</td>
            <td className={styles.colAdmin}>
              <Checkbox
                id={`admin-checkbox-${groupMember.email}`}
                labelText=""
                checked={groupMember.group_admin}
                onChange={(value: boolean) => onChangeAdmin(groupMember, value)}
                disabled={(!currentGroup.is_admin || (isLastAdmin && groupMember.group_admin))}
              />
            </td>
            {(currentGroup.is_admin || groupMember.email.toLowerCase() === user.email.toLowerCase()) && (
              <td>
                <span className={isLastAdmin && groupMember.group_admin ? 'bx--tooltip__trigger bx--btn--icon-only--right disabledButton' : ''} aria-label="Cannot remove last admin">
                  <Button
                    className={styles.leaveGroupBtn}
                    onClick={() => onRemove(groupMember)}
                    size="sm"
                    kind="ghost"
                    renderIcon={Close}
                    iconDescription="Remove member"
                    hasIconOnly
                    disabled={isLastAdmin && groupMember.group_admin}
                    tooltipPosition="right"
                  />
                </span>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default GroupMembersTable;
