import { ComposedModal, ModalFooter, ModalHeader } from 'carbon-components-react';
import ReactDOM from 'react-dom';
import styles from './ConfirmationDialog.module.css';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message: string;
  submitButtonText: string;
}

function ConfirmationDialog({ isOpen, onClose, onSubmit, message, submitButtonText }: ConfirmationDialogProps) {
  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.confirmationDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title={message} />
      <ModalFooter
        danger
        primaryButtonText={submitButtonText}
        secondaryButtonText="Cancel"
        onRequestSubmit={() => { onClose(); onSubmit(); }}
      />
    </ComposedModal>,
    document.body
  );
}

export default ConfirmationDialog;
