import { ToastNotification } from 'carbon-components-react';
import { createRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Toast } from '../../models/toast';
import styles from './ToastContainer.module.css';

interface ToastContainerProps {
  toasts: Toast[]
}

function ToastContainer({ toasts }: ToastContainerProps) {
  return (
    <TransitionGroup component="div" className={styles.toastContainer}>
      {toasts.map((toast) => {
        const nodeRef = createRef<HTMLDivElement>();
        return (
          <CSSTransition
            key={toast.id}
            nodeRef={nodeRef}
            timeout={200}
            classNames={{
              enter: styles.toastEnter,
              enterActive: styles.toastEnterActive,
              exit: styles.toastExit,
              exitActive: styles.toastExitActive,
            }}
          >
            <div ref={nodeRef}>
              <ToastNotification
                className={styles.toast}
                kind={toast.kind}
                title={toast.title}
                subtitle={toast.message}
                lowContrast
              />
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
}

export default ToastContainer;
