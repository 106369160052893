import { IssueTrackerItem } from '../../models/issueTracker';
import ItemCard from './ItemCard';
import styles from './ItemClusterCard.module.css';

interface ItemClusterCardProps {
  items: IssueTrackerItem[];
  onRefresh: () => void;
  onItemChange: (itemId: string, fn: (prevItem: IssueTrackerItem) => IssueTrackerItem) => void;
}

function ItemClusterCard({ items, onRefresh, onItemChange }: ItemClusterCardProps) {
  const theseIdeaRefs = items.map((i) => i.reference);
  const cluteredIdeaRefs = items[0].related_ideas.map((i) => i.idea_reference);
  cluteredIdeaRefs.sort();

  const notShowncluteredIdeaRefs = cluteredIdeaRefs.filter((ref) => !theseIdeaRefs.includes(ref));

  return (
    <div className={styles.ItemClusterCard}>
      {items.map((item) => (
        <ItemCard
          key={item.id}
          item={item}
          onRefresh={onRefresh}
          onChange={(updateFn) => onItemChange(item.id, updateFn)}
        />
      ))}
      {(notShowncluteredIdeaRefs.length !== 0) && (
        <div className={styles.unshownClusteredIdeas}>
          Clustered ideas that are not in the current view: {notShowncluteredIdeaRefs.join(', ')}
        </div>
      )}
    </div>
  );
}

export default ItemClusterCard;
