import { useIssueTrackerContext } from '../../context/IssueTrackerContext';
import IssueTrackerListing from '../../components/IssueTrackerListing/IssueTrackerListing';
import { IssueTrackerInclusionState } from '../../../../utils/issueTrackerInclusionStates';

function IssueTrackerBigTen() {
  const { currentParent } = useIssueTrackerContext();

  if (!currentParent) return <h2>Product not found</h2>;

  return (
    <>
      <h2>{currentParent.name}: The Big 10</h2>
      <IssueTrackerListing
        parentId={currentParent.id}
        inclusionStatuses={[IssueTrackerInclusionState.THE_BIG_10]}
        hideBig10Filter
      />
    </>
  );
}

export default IssueTrackerBigTen;
