import { TextInput } from 'carbon-components-react';

interface CompanyInputTextProps {
  companyText: string,
  onChange: (companyText: string) => void,
  isInvalid: boolean,
}

function CompanyInputText({ companyText, onChange, isInvalid }: CompanyInputTextProps) {
  return (
    <TextInput
      id="company"
      name="company"
      labelText=""
      placeholder="Example Company Ltd"
      invalid={isInvalid}
      required
      value={companyText}
      onChange={(e) => { onChange(e.target.value); }}
      onBlur={(e) => onChange(e.target.value)}
    />
  );
}

export default CompanyInputText;
