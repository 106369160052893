import { Add } from '@carbon/icons-react';
import { Dropdown, Form, TextInput } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import ButtonSpinner from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useGroupsContext } from '../../../../context/GroupsContext';
import { useToastContext } from '../../../../context/ToastContext';
import { GroupMember } from '../../../../models/groupMember';
import { addGroupMember } from '../../../../utils/api';
import styles from './AddGroupMember.module.css';

const ROLES = ['Contributor', 'Admin'];

const EMAIL_REGEX = /^(.+)@(.+)$/;

interface AddGroupMemberProps {
  onAdd: (newMember: GroupMember) => void;
  currentMembers: GroupMember[];
}

function AddGroupMember({ onAdd, currentMembers }: AddGroupMemberProps) {
  const { addToast } = useToastContext();
  const { currentGroup, fetchGroups } = useGroupsContext();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState(ROLES[0]);
  const [isAddingGroupMember, setIsAddingGroupMember] = useState(false);

  const groupNameInputRef = useRef<HTMLInputElement>(null);
  const hasAdded = useRef(false);

  useEffect(() => {
    if (isAddingGroupMember) {
      hasAdded.current = true;
    }

    if (hasAdded.current && !isAddingGroupMember) {
      groupNameInputRef.current?.focus();
    }
  }, [isAddingGroupMember]);

  const isValid = EMAIL_REGEX.test(email.trim());

  const onAddGroupMember = async () => {
    if (!currentGroup) return;

    const groupMember: GroupMember = {
      email: email.trim().toLowerCase(),
      group_admin: role === 'Admin'
    };

    if (currentMembers.find((m) => m.email === groupMember.email)) {
      addToast({
        kind: 'error',
        title: 'Member already exists',
        message: `${groupMember.email} is already in the group`
      });
      groupNameInputRef.current?.focus();
      return;
    }

    setIsAddingGroupMember(true);

    try {
      await addGroupMember(currentGroup.id, groupMember);
      onAdd(groupMember);
      setEmail('');
      addToast({
        kind: 'success',
        title: 'Member added to the group',
        message: `${groupMember.email} has been added to the group`
      });
    } catch (err) {
      addToast({
        kind: 'error',
        title: 'Failed adding group member',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsAddingGroupMember(false);
    await fetchGroups();
  };

  return (
    <Form className={styles.addGroupMemberForm} onSubmit={(e) => { e.preventDefault(); onAddGroupMember(); }}>
      <h4>Add a group member</h4>
      <div className={styles.formRow}>
        <TextInput
          ref={groupNameInputRef}
          id="new-member-email"
          className={styles.emailInput}
          labelText=""
          placeholder="user@domain.com"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isAddingGroupMember}
          maxLength={320}
        />
        <Dropdown
          id="role-dropdown"
          className={styles.roleDropdown}
          label=""
          items={ROLES}
          selectedItem={role}
          onChange={({ selectedItem }) => {
            setRole(selectedItem || ROLES[0]);
          }}
          disabled={isAddingGroupMember}
        />
      </div>
      <ButtonSpinner
        type="submit"
        icon={Add}
        disabled={!isValid}
        isLoading={isAddingGroupMember}
        loadingText="Adding group member..."
      >
        Add group member
      </ButtonSpinner>
    </Form>
  );
}

export default AddGroupMember;
