import { PaginationNav } from 'carbon-components-react';
import styles from './Pagination.module.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  scroll?: boolean;
  onChange: (newPage: number, scroll: boolean) => void;
}

function Pagination({ currentPage, totalPages, scroll = true, onChange }: PaginationProps) {
  return (
    <PaginationNav
      className={styles.pagination}
      page={currentPage - 1}
      totalItems={totalPages}
      itemsShown={10}
      onChange={(newPage) => {
        onChange(newPage + 1, scroll);
      }}
    />
  );
}

export default Pagination;
