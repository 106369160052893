import { Close, Edit, Save } from '@carbon/icons-react';
import { Button, InlineLoading, TextInput } from 'carbon-components-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ColourPicker from '../../../components/ColourPicker/ColourPicker';
import Tag from '../../../components/Tag/Tag';
import { useGroupsContext } from '../../../context/GroupsContext';
import { useToastContext } from '../../../context/ToastContext';
import { Tag as TagModel } from '../../../models/tag';
import { removeGroupTag, updateGroupTag } from '../../../utils/api';
import AddGroupTag from './AddGroupTag/AddGroupTag';
import styles from './GroupTags.module.css';

interface GroupTagRowProps {
  tag: TagModel;
}

function GroupTagRow({ tag }: GroupTagRowProps) {
  const { addToast } = useToastContext();
  const groupsContext = useGroupsContext();
  const { currentGroup } = groupsContext;

  const [isEditing, setIsEditing] = useState(false);
  const [tagName, setTagName] = useState(tag.name);
  const [tagColour, setTagColour] = useState(tag.colour);

  const onRemove = async () => {
    if (!currentGroup) return;
    groupsContext!.removeGroupTag(tag);

    try {
      await removeGroupTag(currentGroup.id, tag.id);
    } catch (err) {
      groupsContext!.addGroupTag(tag);
      addToast({
        kind: 'error',
        title: 'Failed to remove tag from group',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const onSave = async () => {
    if (!currentGroup) return;

    setIsEditing(false);

    const oldTag = { ...tag };
    const newTag = { ...tag, name: tagName.trim(), colour: tagColour };
    setTagName(newTag.name);

    if (newTag.name === '') {
      setTagName(oldTag.name);
      setTagColour(oldTag.colour);
      addToast({
        kind: 'error',
        title: 'Cannot update tag',
        message: 'Please enter a tag name'
      });
      return;
    }

    groupsContext!.updateGroupTag(newTag);

    try {
      await updateGroupTag(currentGroup.id, tag.id, newTag);
    } catch (err) {
      groupsContext!.updateGroupTag(oldTag);
      setTagName(oldTag.name);
      addToast({
        kind: 'error',
        title: 'Failed to update tag',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  return (
    <tr>
      <td className={styles.colTagName}>
        {isEditing ? (
          <div className={styles.editRow}>
            <TextInput
              id="edit-tag-name"
              className={styles.editTagNameInput}
              labelText=""
              autoComplete="off"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSave();
                }
              }}
              maxLength={50}
              autoFocus
            />
            <ColourPicker selectedColour={tagColour} onChange={setTagColour} />
          </div>
        ) : (
          <Link className={styles.tagLink} to={`..?tags=${tag.id}`}>
            <Tag name={tag.name} colour={tag.colour} />
          </Link>
        )}
      </td>
      <td>{tag.idea_count}</td>
      <td>
        {isEditing ? (
          <Button
            onClick={() => onSave()}
            size="sm"
            kind="ghost"
            aria-label="Save tag"
            disabled={tagName.trim().length === 0}
          >
            <Save />
          </Button>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="sm"
            kind="ghost"
            aria-label="Edit tag"
          >
            <Edit />
          </Button>
        )}

      </td>
      <td>
        <Button
          onClick={() => onRemove()}
          size="sm"
          kind="ghost"
          aria-label="Remove tag"
        >
          <Close />
        </Button>
      </td>
    </tr>
  );
}

function GroupTags() {
  const { isLoadingGroupTags, currentGroupTags } = useGroupsContext();

  return (
    <>
      <h3 className={styles.header}>Group Tags</h3>
      <section className={styles.groupTagsSection}>
        {isLoadingGroupTags ? (
          <InlineLoading description="Loading group tags..." />
        ) : currentGroupTags.length === 0 ? (
          <p>This group has no tags</p>
        ) : (
          <table className={styles.tagsTable}>
            <thead>
              <tr>
                <th>Tag</th>
                <th>Ideas</th>
                <th>Edit</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {currentGroupTags
                .sort((a, b) => ((a.name && b.name && (a.name.toLowerCase() > b.name.toLowerCase())) ? 1 : -1))
                .map((tag) => (
                  <GroupTagRow key={tag.id} tag={tag} />
                ))}
            </tbody>
          </table>
        )}
      </section>
      <AddGroupTag />
    </>
  );
}

export default GroupTags;
