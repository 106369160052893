import { useRef, useState, useMemo, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { ComboBox } from 'carbon-components-react';
import { Company } from '../../../models/company';
import { getCompanies } from '../../../utils/api';
import styles from './CompanyInputRow.module.css';

interface CompanyInputComboBoxProps {
  company: Company | null,
  onChange: (company: Company | null) => void,
  isInvalid: boolean,
  setError: Dispatch<SetStateAction<boolean>>
}

type CompanyItem = Company & { disabled?: boolean };
const LoadingCompanyItem = { company_name: 'Searching companies...', disabled: true };
const CompanyNotFoundItem = { company_name: 'Company not found', disabled: true };

function CompanyInputComboBox({ company, onChange, isInvalid, setError }: CompanyInputComboBoxProps) {
  const [companyInput, setCompanyInput] = useState<string>('');
  const companyInputAbortController = useRef<AbortController | null>(null);
  const [companyItems, setCompanyItems] = useState<CompanyItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCompanyInputChange = async (inputText: string) => {
    if (inputText === companyInput) {
      return;
    }

    if (companyInputAbortController.current) {
      companyInputAbortController.current.abort();
    }

    if (inputText === '') {
      setCompanyInput('');
      setCompanyItems([]);
      setIsLoading(false);
      return;
    }

    companyInputAbortController.current = new AbortController();

    try {
      setIsLoading(true);
      const { data } = await getCompanies(inputText, companyInputAbortController.current);
      setCompanyInput(inputText);
      setCompanyItems(data);
      setIsLoading(false);

      companyInputAbortController.current = null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        setError(true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const debouncedHandleCompanyInputChange = useMemo(() => debounce(handleCompanyInputChange, 300), [handleCompanyInputChange]);

  let shownCompanyItems = companyItems;

  if (isLoading) {
    shownCompanyItems = [LoadingCompanyItem];
  } else if (companyInput && companyItems.length === 0) {
    shownCompanyItems = [CompanyNotFoundItem];
  }

  return (
    <ComboBox
      id="company-dropdown"
      className={styles.companyDropdown}
      placeholder="Search for a company..."
      items={shownCompanyItems}
      itemToString={(c) => c?.company_name || ''}
      selectedItem={company}
      onInputChange={debouncedHandleCompanyInputChange}
      onChange={({ selectedItem }) => onChange(selectedItem || null)}
      spellCheck="false"
      invalid={isInvalid}
    />
  );
}

export default CompanyInputComboBox;
