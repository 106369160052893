import React, { useMemo } from 'react';
import IdeasListing from '../../components/IdeasListing/IdeasListing';

type Interaction = 'raised' | 'voted' | 'commented' | 'subscribed';

interface MyIdeasProps {
  interaction: Interaction;
}

const INTERACTION_TITLES: { [key in Interaction]: string } = {
  raised: 'My Ideas',
  voted: 'My Votes',
  commented: 'My Comments',
  subscribed: 'My Subscriptions'
};

const INTERACTION_NO_IDEAS_TEXT: { [key in Interaction]: React.ReactNode } = {
  raised: 'You have not raised any ideas',
  voted: 'You have not voted on any ideas',
  commented: 'You have not commented on any ideas',
  subscribed: 'You are not subscribed to any ideas'
};

function MyIdeas({ interaction }: MyIdeasProps) {
  const queryParams = useMemo(() => ({
    interaction
  }), [interaction]);

  return (
    <>
      <h2>{INTERACTION_TITLES[interaction]}</h2>
      <IdeasListing
        queryParams={queryParams}
        showQueryFilter
        noIdeasText={INTERACTION_NO_IDEAS_TEXT[interaction]}
      />
    </>
  );
}

export default MyIdeas;
