import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IssueTrackerParent, IssueTrackerProduct } from '../models/issueTracker';
import { getIssueTrackerMenu } from '../../../utils/api';

interface IssueTrackerContextInterface {
  isLoadingMenu: boolean;
  menu: IssueTrackerParent[];
  currentParent: IssueTrackerParent | null;
  currentProduct: IssueTrackerProduct | null;
}

const IssueTrackerContext = createContext<IssueTrackerContextInterface | null>(null);

export function useIssueTrackerContext() {
  return useContext(IssueTrackerContext)!;
}

interface IssueTrackerProps {
  children: React.ReactNode;
}

const IBMSoftware: IssueTrackerParent = {
  id: 'ibm',
  name: 'IBM Software',
  slug: 'ibm',
  children: []
};

export function IssueTrackerProvider({ children }: IssueTrackerProps) {
  const { parentSlug, productSlug } = useParams();
  const [isLoadingMenu, setIsLoadingMenu] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [menu, setMenu] = useState<IssueTrackerParent[]>([]);

  useEffect(() => {
    const fetchMenu = async () => {
      setIsLoadingMenu(true);
      const { data } = await getIssueTrackerMenu();
      setMenu([IBMSoftware, ...data]);
      setIsLoadingMenu(false);
    };
    fetchMenu();
  }, []);

  useEffect(() => {
    const big5 = searchParams.get('big5');
    if (big5 === 'true' || big5 === 'false') {
      searchParams.delete('big5');
      searchParams.append('big10', big5);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      }, { replace: true });
    }
    if (location.pathname.includes('the-big-5')) {
      const newPath = location.pathname.replace('the-big-5', 'the-big-10');
      navigate({
        pathname: newPath,
        search: searchParams.toString()
      }, { replace: true });
    }
  }, [navigate, location]);

  const currentParent = useMemo(() => menu.find((p) => p.slug === parentSlug) || null, [menu, parentSlug]);
  const currentProduct = useMemo(() => currentParent?.children.find((p) => p.slug === productSlug) || null, [currentParent, productSlug]);

  const issueTrackerContext = useMemo(() => ({
    isLoadingMenu,
    menu,
    currentParent,
    currentProduct,
  }), [
    isLoadingMenu,
    menu,
    currentParent,
    currentProduct,
  ]);

  return (
    <IssueTrackerContext.Provider value={issueTrackerContext}>
      {children}
    </IssueTrackerContext.Provider>
  );
}

export default IssueTrackerContext;
