import axios from 'axios';
import { ComboBox } from 'carbon-components-react';
import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { Category, Product } from '../../models/product';
import { getProducts } from '../../utils/api';
import styles from './ProductDropdown.module.css';

interface ProductDropdownProps {
  id: string;
  className?: string;
  placeholder: string;
  titleText: React.ReactNode;
  invalid: boolean;
  invalidText: string | null;
  selectedProductCategoryItem: ProductCategoryItem | null;
  onProductCategoryItemChange: (product: ProductCategoryItem | null) => void;
}

export interface ProductCategoryItem {
  product: Product;
  category: Category | null;
}

function ProductDropdown(props: ProductDropdownProps) {
  const {
    id,
    className = '',
    placeholder,
    titleText,
    invalid,
    invalidText,
    selectedProductCategoryItem,
    onProductCategoryItemChange
  } = props;

  const abortController = useRef<AbortController | null>(null);
  const [productInput, setProductInput] = useState('');
  const [productCategoryItems, setProductCategoryItems] = useState<ProductCategoryItem[]>([]);

  const handleProductInputChange = async (inputText: string) => {
    if (inputText === productInput) {
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }

    if (inputText === '') {
      setProductInput('');
      onProductCategoryItemChange(null);
      setProductCategoryItems([]);
      return;
    }

    abortController.current = new AbortController();

    try {
      const { data } = await getProducts(inputText, 'create_idea', abortController.current);

      const newProductCategoryItems: ProductCategoryItem[] = [];

      data.forEach((product) => {
        newProductCategoryItems.push({ product, category: null });

        if (product.idea_categories) {
          product.idea_categories.forEach((category) => {
            newProductCategoryItems.push({ product, category });
          });
        }
      });

      setProductInput(inputText);
      setProductCategoryItems(newProductCategoryItems);
      abortController.current = null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const debouncedHandleProductInputChange = useMemo(() => debounce(handleProductInputChange, 300), [handleProductInputChange]);

  const highlightWords = (str: string, words: string[]) => {
    if (words.length === 0) {
      return <span>{str}</span>;
    }
    // Strip out any special characters to avoid issues when adding to the Regex
    const escapedWords = words.map((w) => w.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'));
    const wordsRegex = escapedWords.join('|');

    const parts = str.split(new RegExp(`(${wordsRegex})`, 'gi'));

    return (
      <span>
        {parts.map((part, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} style={i % 2 === 1 ? { fontWeight: 'bold' } : {}}>{part}</span>
        ))}
      </span>
    );
  };

  const productCategoryItemToElement = (productCategoryItem: ProductCategoryItem) => {
    if (!productCategoryItem) {
      return <span />;
    }

    let productInputWords: string[] = [];

    if (productInput) {
      productInputWords = productInput.split(/\s+/);
    }

    const productEl = (
      <div>
        {highlightWords(productCategoryItem.product.parent.name, productInputWords)}
        <span>: </span>
        {highlightWords(productCategoryItem.product.name, productInputWords)}
        {productCategoryItem.category && (
          <>
            <span> &raquo; </span>
            {highlightWords(productCategoryItem.category.name, productInputWords)}
          </>
        )}
      </div>
    );

    const productInputWordsLower = productInputWords.map((word) => word.toLowerCase());

    const matchingAliases = productCategoryItem.product.aliases.filter((alias) => {
      const aliasLower = alias.toLowerCase();
      return productInputWordsLower.some((inputWord) => aliasLower.includes(inputWord));
    });

    const aliasParts = matchingAliases.map(
      (alias, index) => (
        <React.Fragment key={alias}>
          {index > 0 && ', '}
          {highlightWords(alias, productInputWords)}
        </React.Fragment>
      )
    );

    return (
      <div>
        {productEl}
        {(productCategoryItem.category === null) && (aliasParts.length > 0) && (
          <div className={styles.aliasLine}>Also known as: {aliasParts}</div>
        )}
      </div>
    );
  };

  const productCategoryItemToString = (productCategoryItem: ProductCategoryItem) => {
    if (!productCategoryItem) {
      return '';
    }

    return productCategoryItem.product.name;
  };

  return (
    <ComboBox
      id={id}
      className={`${styles.productDropdown} ${className}`}
      placeholder={placeholder}
      titleText={titleText}
      items={productCategoryItems}
      shouldFilterItem={() => true}
      itemToString={productCategoryItemToString}
      itemToElement={productCategoryItemToElement}
      onInputChange={debouncedHandleProductInputChange}
      selectedItem={selectedProductCategoryItem}
      invalid={invalid}
      invalidText={invalidText}
      onChange={({ selectedItem }: { selectedItem: ProductCategoryItem }) => {
        onProductCategoryItemChange(selectedItem);
      }}
      spellCheck="false"
    />
  );
}

export default ProductDropdown;
