import { FileUploaderButton, FileUploaderItem } from 'carbon-components-react';
import { SelectedFile } from '../../models/file';
import { removeFile, uploadFile } from '../../utils/api';
import styles from './FileUpload.module.css';

interface FileUploadProps {
  selectedFiles: SelectedFile[];
  updateSelectedFiles: (setSelectedFiles: (prevSelectedFiles: SelectedFile[]) => SelectedFile[]) => void;
}

function FileUpload({ selectedFiles, updateSelectedFiles }: FileUploadProps) {
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const tempId = new Date().valueOf().toString();

    const selectedFile: SelectedFile = {
      tempId,
      id: null,
      name: file.name,
      status: 'uploading'
    };

    updateSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, selectedFile]);

    const { data } = await uploadFile(file);

    updateSelectedFiles((prevSelectedFiles) => prevSelectedFiles.map((f) => {
      if (f.tempId === selectedFile.tempId) {
        return { ...f, id: data.id, status: 'edit' };
      }
      return f;
    }));
  };

  const onFileDelete = async (_: any, { uuid }: { uuid: string }) => {
    const deletedFile = selectedFiles.find((f) => f.tempId === uuid);

    updateSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((f) => f.tempId !== uuid));

    if (deletedFile?.id) {
      removeFile(deletedFile.id);
    }
  };

  return (
    <>
      <FileUploaderButton
        className={styles.uploadFileBtn}
        labelText="Upload file"
        disableLabelChanges
        onChange={onFileChange}
        buttonKind="tertiary"
        size="sm"
      />
      {selectedFiles.map((selectedFile) => (
        <FileUploaderItem
          key={selectedFile.tempId}
          uuid={selectedFile.tempId}
          name={selectedFile.name}
          status={selectedFile.status}
          onDelete={onFileDelete}
        />
      ))}
    </>
  );
}

export default FileUpload;
