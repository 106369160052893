import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIssueTrackerContext } from '../../context/IssueTrackerContext';
import { IssueTrackerInclusionSummary, IssueTrackerSummaryFilters } from '../../models/issueTracker';
import { getIssueTrackerInclusionSummary } from '../../../../utils/api';
import styles from './IssueTrackerParent.module.css';
import SummaryTableCell from './SummaryTableCell';
import IssueTrackerSummaryFilterBar from './IssueTrackerSummaryFilterBar/IssueTrackerSummaryFilterBar';
import IssueTrackerSummaryFilteringBy from './IssueTrackerSummaryFilteringBy/IssueTrackerSummaryFilteringBy';
import { queryParamsToStr, queryParamStrToArray } from '../../../../utils/queryParams';
import { ISSUE_TRACKER_INCLUDED_STATES, ISSUE_TRACKER_REJECTED_STATES, IssueTrackerInclusionState } from '../../../../utils/issueTrackerInclusionStates';

interface InclusionSummaryRowProps {
  row: IssueTrackerInclusionSummary,
  isIBMSoftware: boolean,
  queryParams: any,
}

function InclusionSummaryRow({ row, isIBMSoftware, queryParams }: InclusionSummaryRowProps) {
  function getInclusionCount(inclusionStates: string[]) {
    const countBuckets = row.by_inclusion.filter((bucket) => inclusionStates.includes(bucket.inclusion));
    return countBuckets.reduce((acc, bucket) => acc + bucket.ideas, 0);
  }

  return (
    <TableRow key={row.id} className={`${styles.summaryRow} ${row.total_row ? styles.totalRow : ''}`}>
      <TableCell>
        {row.total_row ? 'Total' : row.name }
      </TableCell>

      <SummaryTableCell
        value={getInclusionCount([IssueTrackerInclusionState.CANDIDATE])}
        to={(row.total_row || isIBMSoftware) ? null : `../${row.slug}/candidate${queryParamsToStr(queryParams)}`}
      />
      <SummaryTableCell
        value={getInclusionCount([IssueTrackerInclusionState.APPROVED_BY_GEO])}
        to={(row.total_row || isIBMSoftware) ? null : `../${row.slug}/approved-by-geo${queryParamsToStr(queryParams)}`}
      />
      <SummaryTableCell
        value={getInclusionCount(ISSUE_TRACKER_INCLUDED_STATES)}
        to={(row.total_row || isIBMSoftware) ? null : `../${row.slug}${queryParamsToStr(queryParams)}`}
      />
      <SummaryTableCell
        value={getInclusionCount(ISSUE_TRACKER_REJECTED_STATES)}
        to={(row.total_row || isIBMSoftware) ? null : `../${row.slug}/rejected${queryParamsToStr(queryParams)}`}
      />
    </TableRow>
  );
}

function InclusionSummary() {
  const { currentParent } = useIssueTrackerContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<IssueTrackerInclusionSummary[]>([]);
  const [filters, setFilters] = useState<IssueTrackerSummaryFilters>({
    ibmWideManagedTags: queryParamStrToArray(searchParams.get('managedtags')),
    big10: searchParams.get('big10'),
    approvedFrom: searchParams.get('approvedfrom'),
    approvedTo: searchParams.get('approvedto'),
  });

  const isIBMSoftware = currentParent!.id === 'ibm';

  // If the filters change, update the URL query params
  useEffect(() => {
    const currentSearch = searchParams.toString().replaceAll('%2C', ',');

    const updateParam = (key: string, value: string | null) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    };

    updateParam('managedtags', filters.ibmWideManagedTags.join(','));
    updateParam('big10', filters.big10);
    updateParam('approvedfrom', filters.approvedFrom);
    updateParam('approvedto', filters.approvedTo);

    const newSearch = searchParams.toString().replaceAll('%2C', ',');

    if (currentSearch !== newSearch) {
      navigate({ search: newSearch });
    }
  }, [filters]);

  useEffect(() => {
    if (!currentParent || !currentParent.id) return;

    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getIssueTrackerInclusionSummary(currentParent.id!, filters.ibmWideManagedTags);
      setRows(data);
      setIsLoading(false);
    };

    fetchData();
  }, [currentParent?.id, filters]);

  const queryParams = { managedtags: filters.ibmWideManagedTags };

  return (
    <>
      <h4>Summary of ideas by Issue Tracker inclusion state</h4>
      <IssueTrackerSummaryFilterBar filters={filters} setFilters={setFilters} showManagedTags />
      <IssueTrackerSummaryFilteringBy filters={filters} setFilters={setFilters} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Table className={`${styles.summaryTable} ${styles.summaryInclusionTable}`} size="lg" useZebraStyles={false}>
          <TableHead>
            <TableRow>
              <TableHeader>Product</TableHeader>
              <TableHeader>Candidate</TableHeader>
              <TableHeader>Approved by GEO</TableHeader>
              <TableHeader>Approved by WW</TableHeader>
              <TableHeader>Rejected</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <InclusionSummaryRow key={row.id} row={row} isIBMSoftware={isIBMSoftware} queryParams={queryParams} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default InclusionSummary;
