import { InlineNotification } from 'carbon-components-react';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import styles from './NotificationBanner.module.css';
import { compareDateTimeToCurrent } from '../../utils/dates';

interface NotificationBannerProps {
  showBanner?: boolean;
}

function NotificationBanner({ showBanner = false }: NotificationBannerProps) {
  const [cookies, setCookie, removeCookie] = useCookies(['ideas-closeBanner', 'notice_preferences']);
  const stopShowingAfterDate = compareDateTimeToCurrent('1 June 2024 00:00:00 UTC');
  const hideBanner = !showBanner || !stopShowingAfterDate || cookies['ideas-closeBanner'];
  const allowSetCookie = false;
  useEffect(() => {
    if (cookies.notice_preferences !== '2:') {
      removeCookie('ideas-closeBanner');
    }
  }, [cookies.notice_preferences]);

  const closeHandler = () => {
    if (allowSetCookie && cookies.notice_preferences === '2:') {
      setCookie('ideas-closeBanner', true, { maxAge: 1 * 60 * 60 * 24 * 7 });
    }
  };

  // const date = formatDate('30 April 2024 07:00:00 AM UTC');
  // const timeStart = formatTimeWithTimeZone('30 April 2024 07:00:00 AM UTC');
  // const timeEnd = formatTimeWithTimeZone('30 April 2024 11:00:00 AM UTC');
  // const message = `Updated: Please note that on ${date} between ${timeStart} and ${timeEnd} the IBM Ideas site will be unavailable due to planned maintenance.`;
  // const message = `IT Automation: CP4AIOps, Instana, Turbonomic
  // App Modernization: Integration, Websphere (App Platform)`;

  const message = (
    <div className={styles.bannerMessage}>
      <h5>Automation has been split into two areas, be sure to update any bookmarks</h5>
      <p>IT Automation: CP4AIOps, Instana, Turbonomic</p>
      <p>App Modernization: Integration, Websphere (App Platform)</p>
    </div>
  );

  return (
    <div className={styles.headerBanner}>
      {!hideBanner && (
      <InlineNotification
        className={styles.warningMessage}
        kind="info"
        title=""
        subtitle={message}
        lowContrast
        onCloseButtonClick={closeHandler}
      />
      )}
    </div>
  );
}

export default NotificationBanner;
