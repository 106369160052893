import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IdeasListing from '../../components/IdeasListing/IdeasListing';
import { getProduct } from '../../utils/api';
import useAPI from '../../hooks/useAPI';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import styles from './Product.module.css';
import FollowButton from '../../components/FollowButton/FollowButton';

function Product() {
  const { productId } = useParams();
  const productAPI = useAPI(getProduct);

  useEffect(() => {
    productAPI.execute(productId);
  }, [productId]);

  const queryParams = useMemo(() => ({
    product_id: productId || ''
  }), [productId]);

  if (productAPI.state === 'idle' || productAPI.state === 'loading') {
    return <LoadingSpinner text="Loading product..." />;
  }

  return (
    <>
      <header className={styles.header}>
        <h2>Product: <strong>{productAPI.data?.name}</strong></h2>
        <FollowButton product={{ id: productAPI.data!.id, name: productAPI.data!.name, idea_category: null }} />
      </header>
      <IdeasListing
        queryParams={queryParams}
        showQueryFilter
        hideProductFilter
        hideCategoryFilter={false}
        noIdeasText="There are no ideas in this product"
      />
    </>
  );
}

export default Product;
