import Tag from '../../../../../components/Tag/Tag';
import { formatDateRange } from '../../../../../utils/dates';
import { MANAGED_TAG_SETS, FINAL_BIG_10_TAG, createItemList, filterManagedTags } from '../../../../../utils/managedTags';
import { IssueTrackerSummaryFilters } from '../../../models/issueTracker';
import styles from './IssueTrackerSummaryFilteringBy.module.css';

interface IssueTrackerSummaryFilteringByProps {
  filters: IssueTrackerSummaryFilters,
  setFilters: (fn: (prevFilters: IssueTrackerSummaryFilters) => IssueTrackerSummaryFilters) => void;
}

function IssueTrackerSummaryFilteringBy({ filters, setFilters }: IssueTrackerSummaryFilteringByProps) {
  const currentFilters: React.ReactNode[] = [];

  const removeFilterIBMManagedTag = (tag: string) => {
    const newFilters = filters.ibmWideManagedTags.filter((t) => t !== tag);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ibmWideManagedTags: newFilters
    }));
  };

  const removeApprovedDate = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      approvedFrom: null,
      approvedTo: null
    }));
  };

  MANAGED_TAG_SETS.forEach((managedTagSet) => {
    const tags = filterManagedTags(filters.ibmWideManagedTags, managedTagSet.prefix);
    if (tags.length > 0) {
      const items = createItemList(tags, managedTagSet);
      currentFilters.push(
        <div className={styles.filterType}>
          <span className={styles.filterTypeLabel}>{managedTagSet.label}:</span>
          <div className={styles.filterTypeTags}>
            {items.map((item) => (
              <Tag
                key={item.key}
                name={item.label}
                colour="#EEE"
                onRemove={() => removeFilterIBMManagedTag(item.key)}
                removeTooltip="Remove filter"
              />
            ))}
          </div>
        </div>
      );
    }
  });

  if (filters.approvedFrom && filters.approvedTo) {
    const dateRangeStr = formatDateRange(filters.approvedFrom, filters.approvedTo);

    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Approved Date:</span>
        <div className={styles.filterTypeTags}>
          <Tag
            name={dateRangeStr}
            colour="#EEE"
            onRemove={() => removeApprovedDate()}
            removeTooltip="Remove filter"
          />
        </div>
      </div>
    );
  }

  if (filters.ibmWideManagedTags.length > 0 && filters.ibmWideManagedTags.includes(FINAL_BIG_10_TAG)) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Pilot:</span>
        <div className={styles.filterTypeTags}>
          <Tag
            key={FINAL_BIG_10_TAG}
            name={FINAL_BIG_10_TAG}
            colour="#EEE"
            onRemove={() => removeFilterIBMManagedTag(FINAL_BIG_10_TAG)}
            removeTooltip="Remove filter"
          />
        </div>
      </div>
    );
  }

  if (currentFilters.length === 0) return null;

  return (
    <div className={styles.IssueTrackerSummaryFilteringBy}>
      <span className={styles.filtersLabel}>Filters</span>
      <div className={styles.filters}>
        {currentFilters}
      </div>
    </div>
  );
}

export default IssueTrackerSummaryFilteringBy;
