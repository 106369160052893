import { Button, InlineLoading } from 'carbon-components-react';
import { CarbonIconType } from '@carbon/icons-react';

interface ButtonSpinnerProps {
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  kind?: 'primary' | 'tertiary';
  icon?: CarbonIconType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading: boolean;
  loadingText?: string;
}

function ButtonSpinner({ children, className, type = 'button', kind = 'primary', onClick, icon, isLoading, loadingText, disabled = false }: ButtonSpinnerProps) {
  if (isLoading) {
    return <InlineLoading className={className} description={loadingText} />;
  }

  return (
    <Button className={className} type={type} kind={kind} onClick={onClick} size="sm" renderIcon={icon} disabled={disabled}>
      {children}
    </Button>
  );
}

export default ButtonSpinner;
