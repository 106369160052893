import { TableCell } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import styles from './IssueTrackerParent.module.css';

function SummaryTableCell({ value, to }: { value: number, to: string | null }) {
  if (value === 0) {
    return <TableCell className={styles.zero}>-</TableCell>;
  }

  if (to === null) {
    return <TableCell>{value}</TableCell>;
  }

  return (
    <TableCell>
      <Link to={to}>{value}</Link>
    </TableCell>
  );
}

export default SummaryTableCell;
