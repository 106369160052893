import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import IdeasListing from '../../../components/IdeasListing/IdeasListing';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { useGroupsContext } from '../../../context/GroupsContext';

function GroupIdeas() {
  const { isLoadingGroups, currentGroup } = useGroupsContext();

  if (!currentGroup) {
    if (isLoadingGroups) {
      return <LoadingSpinner text="Loading group..." />;
    }
    return <>Could not find group</>;
  }

  const queryParams = useMemo(() => ({
    group_id: currentGroup.id
  }), [currentGroup.id]);

  return (
    <IdeasListing
      queryParams={queryParams}
      showQueryFilter
      noIdeasText={(
        <p>
          There are no ideas in this group.<br />
          Go to <Link to="/">My Ideas</Link> or use the search bar above to find ideas and add them to this group.
        </p>
      )}
    />
  );
}

export default GroupIdeas;
