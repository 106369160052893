import { createContext, useContext, useMemo, useState } from 'react';

interface SubscribeContextInterface {
  setIsSubscribed: (isSubscribed: boolean) => void;
  isSubscribed: boolean;
}

const SubscribeContext = createContext<SubscribeContextInterface | null>(null);

export function useSubscribeContext() {
  return useContext(SubscribeContext)!;
}

interface SubscribeProviderProps {
  children: React.ReactNode;
}

export function SubscribeProvider({ children }: SubscribeProviderProps) {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const subscribeContext = useMemo(() => ({
    setIsSubscribed,
    isSubscribed
  }), [isSubscribed]);

  return (
    <SubscribeContext.Provider value={subscribeContext}>
      {children}
    </SubscribeContext.Provider>
  );
}

export default SubscribeContext;
