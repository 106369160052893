import styles from './PageTabs.module.css';

interface PageTabsProps {
  children: React.ReactNode;
}

function PageTabs({ children }: PageTabsProps) {
  return (
    <div data-tabs className={`bx--tabs ${styles.PageTabs}`}>
      <ul className="bx--tabs__nav bx--tabs__nav--hidden">
        {children}
      </ul>
    </div>
  );
}

export default PageTabs;
