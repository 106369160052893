import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import PageTab from '../../../../components/PageTabs/PageTab';
import PageTabs from '../../../../components/PageTabs/PageTabs';
import { useIssueTrackerContext } from '../../context/IssueTrackerContext';
import IssueTrackerListing from '../../components/IssueTrackerListing/IssueTrackerListing';
import styles from './IssueTrackerProduct.module.css';
import { ISSUE_TRACKER_INCLUDED_STATES, ISSUE_TRACKER_REJECTED_STATES, IssueTrackerInclusionState } from '../../../../utils/issueTrackerInclusionStates';

function IssueTrackerProduct() {
  const { listingType } = useParams();
  const { currentParent, currentProduct } = useIssueTrackerContext();

  const inclusionStatuses = useMemo(() => {
    if (!listingType) {
      return ISSUE_TRACKER_INCLUDED_STATES;
    }
    if (listingType === 'candidate') {
      return [IssueTrackerInclusionState.CANDIDATE];
    }
    if (listingType === 'approved-by-geo') {
      return [IssueTrackerInclusionState.APPROVED_BY_GEO];
    }
    if (listingType === 'rejected') {
      return ISSUE_TRACKER_REJECTED_STATES;
    }

    return [];
  }, [listingType]);

  if (!currentParent || !currentProduct) return <h2>Product not found</h2>;

  return (
    <section className={styles.section}>
      <h2>{currentParent.name}: {currentProduct.name}</h2>
      <PageTabs>
        <PageTab to={`/issue-tracker/${currentParent.slug}/${currentProduct.slug}`}>Approved by WW</PageTab>
        <PageTab to={`/issue-tracker/${currentParent.slug}/${currentProduct.slug}/approved-by-geo`}>Approved by GEO</PageTab>
        <PageTab to={`/issue-tracker/${currentParent.slug}/${currentProduct.slug}/candidate`}>Candidate for Issue Tracker</PageTab>
        <PageTab to={`/issue-tracker/${currentParent.slug}/${currentProduct.slug}/rejected`}>Rejected from Issue Tracker</PageTab>
      </PageTabs>
      <IssueTrackerListing
        parentId={currentProduct.id}
        inclusionStatuses={inclusionStatuses}
        hideBig10Filter={!!listingType}
        hideApprovedDateFilter={!!listingType}
      />
    </section>
  );
}

export default IssueTrackerProduct;
