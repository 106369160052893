import { useState } from 'react';
import { Form, TextInput } from 'carbon-components-react';
import { Add } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import { useGroupsContext } from '../../../context/GroupsContext';
import { createGroup } from '../../../utils/api';
import styles from './NewGroupForm.module.css';
import ButtonSpinner from '../../../components/ButtonSpinner/ButtonSpinner';
import { useToastContext } from '../../../context/ToastContext';

function NewGroupForm() {
  const { addToast } = useToastContext();
  const { fetchGroups, setCurrentGroupId } = useGroupsContext();

  const navigate = useNavigate();

  const [groupName, setGroupName] = useState('');
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);

  const onCreateGroupClick = async () => {
    setIsCreatingGroup(true);

    try {
      const { data: newGroup } = await createGroup(groupName);
      setCurrentGroupId(newGroup.id);
      navigate(`/groups/${newGroup.id}`);
    } catch (error) {
      addToast({
        kind: 'error',
        title: 'Failed creating group',
        message: 'Something went wrong, please try again later'
      });
    }

    await fetchGroups();
    setIsCreatingGroup(false);
  };

  return (
    <Form className={styles.newGroupForm}>
      <h4>Create a new group</h4>
      <TextInput
        className={styles.groupNameInput}
        id="new-group-name"
        labelText=""
        placeholder="Group Name"
        autoComplete="off"
        maxLength={50}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <ButtonSpinner
        className={styles.createBtn}
        type="submit"
        onClick={onCreateGroupClick}
        icon={Add}
        disabled={groupName.length === 0}
        isLoading={isCreatingGroup}
        loadingText="Creating group..."
      >
        Create group
      </ButtonSpinner>
    </Form>
  );
}

export default NewGroupForm;
