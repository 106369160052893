import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'carbon-components-react';
import usePagination from '../../hooks/usePagination';
import useHash from '../../hooks/useHash';
import { Idea, IdeaComment } from '../../models/idea';
import { useCommentContext } from '../../context/CommentContext';
import Comment from '../Comment/Comment';
import Pagination from '../Pagination/Pagination';
import useAPI from '../../hooks/useAPI';
import { getIdeaComments } from '../../utils/api';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Visibility } from '../../utils/ideaVisibility';
import CommentBox from '../CommentBox/CommentBox';
import styles from './Comments.module.css';

interface CommentsProps {
  idea: Idea;
}

const ORDER = [
  { label: 'Newest First', value: 'asc' },
  { label: 'Oldest First', value: 'desc' },
];

const PER_PAGE = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
];

function Comments({ idea }: CommentsProps) {
  const hash = useHash();
  const { ideaId } = useParams();
  const ideaCommentsAPI = useAPI(getIdeaComments);
  const { currentPage, changePage, setScrollToID, isResettingPage } = usePagination();
  const [order, setOrder] = useState(ORDER[0]);
  const [perPage, setPerPage] = useState(PER_PAGE[0]);
  const { commentId, commentCount } = useCommentContext();
  const [commentsData, setCommentsData] = useState<IdeaComment[] | null>(null);
  const [newComment, setNewComment] = useState<boolean>(false);
  const params: { [key: string]: number | string } = {
    page: currentPage,
    per_page: perPage.value,
    direction: order.value
  };
  let extraPage: number = 0;

  const divID = 'idea-comments';
  setScrollToID(divID);
  const totalPages = useMemo(() => {
    if (!ideaCommentsAPI.headers) return 0;
    return parseInt(ideaCommentsAPI.headers['pagination-last-page'], 10) + extraPage;
  }, [ideaCommentsAPI.headers, extraPage]);
  const totalItems = useMemo(() => {
    if (!ideaCommentsAPI.headers) return 0;
    return parseInt(ideaCommentsAPI.headers['pagination-total-items'], 10);
  }, [ideaCommentsAPI.headers]);

  const refreshComments = () => {
    if (isResettingPage) return undefined;
    ideaCommentsAPI.reset();
    const abortController = new AbortController();
    ideaCommentsAPI.execute(ideaId, params);
    return () => abortController.abort();
  };

  useEffect(() => {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView();
    }
  }, [hash]);

  useEffect(() => {
    if (commentId) {
      if (order === ORDER[0]) {
        changePage(1, true);
        if (currentPage === 1) refreshComments();
      } else if (order === ORDER[1]) {
        extraPage = totalItems % parseInt(perPage.value, 10) === 0 ? 1 : 0;
        if (extraPage) setNewComment(true);
        const newPage = totalPages + extraPage;
        setScrollToID(commentId);
        changePage(newPage, false);
        if (newPage === currentPage && newPage === totalPages) refreshComments();
      }
    }
  }, [commentId]);

  const isInvalidPage = totalPages !== 0 && totalPages < currentPage;

  useEffect(() => {
    setCommentsData(ideaCommentsAPI.data);
  }, [ideaCommentsAPI.data]);

  // If the current page is greater than total pages (e.g. comments have been removed),
  // change to the last page
  useLayoutEffect(() => {
    if (isInvalidPage && !newComment) {
      changePage(totalPages, false);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    refreshComments();
  }, [currentPage, order, perPage]);

  let ideaComments;
  if (commentsData && commentsData.length > 0) {
    ideaComments = commentsData.map((comment: IdeaComment) => (
      <Comment key={comment.comment_id} comment={comment} />
    ));
  } else if (commentsData) {
    ideaComments = (<p>No Comments yet, add a comment below</p>);
  } else {
    ideaComments = (<LoadingSpinner text="Loading comments..." />);
  }

  return (
    <section>
      <div className={styles.commentsRow}>
        <h3 id={divID}>Comments ({commentCount})</h3>
        <Dropdown
          id="per-page-dropdown"
          className={styles.perPageDropdown}
          label=""
          size="sm"
          items={PER_PAGE}
          selectedItem={perPage}
          onChange={({ selectedItem }) => {
            setPerPage(selectedItem || PER_PAGE[0]);
          }}
        />
        <Dropdown
          id="order-dropdown"
          className={styles.orderDropdown}
          label=""
          size="sm"
          items={ORDER}
          selectedItem={order}
          onChange={({ selectedItem }) => {
            setOrder(selectedItem || ORDER[0]);
          }}
        />
      </div>
      {ideaComments}
      {commentsData && (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={changePage}
        scroll={false}
      />
      )}
      {(idea.url && idea.visibility !== Visibility.NOT_VISIBLE) && (
        <div className={styles.newCommentBox}>
          <CommentBox />
        </div>
      )}
    </section>
  );
}

export default Comments;
