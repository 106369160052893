import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIssueTrackerContext } from '../../context/IssueTrackerContext';
import { IssueTrackerOutlookSummary, IssueTrackerSummaryFilters } from '../../models/issueTracker';
import { getIssueTrackerOutlookSummary } from '../../../../utils/api';
import { queryParamsToStr, queryParamStrToArray } from '../../../../utils/queryParams';
import styles from './IssueTrackerParent.module.css';
import IssueTrackerSummaryFilterBar from './IssueTrackerSummaryFilterBar/IssueTrackerSummaryFilterBar';
import IssueTrackerSummaryFilteringBy from './IssueTrackerSummaryFilteringBy/IssueTrackerSummaryFilteringBy';
import SummaryTableCell from './SummaryTableCell';

function OutlookSummary() {
  const { currentParent } = useIssueTrackerContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isIBMSoftware = currentParent?.id === 'ibm';

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<IssueTrackerOutlookSummary[]>([]);
  const [filters, setFilters] = useState<IssueTrackerSummaryFilters>({
    ibmWideManagedTags: queryParamStrToArray(searchParams.get('managedtags')),
    big10: searchParams.get('big10'),
    approvedFrom: searchParams.get('approvedfrom'),
    approvedTo: searchParams.get('approvedto')
  });

  // If the filters change, update the URL query params
  useEffect(() => {
    const currentSearch = searchParams.toString().replaceAll('%2C', ',');

    const updateParam = (key: string, value: string | null) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    };

    updateParam('managedtags', filters.ibmWideManagedTags.join(','));
    updateParam('big10', filters.big10);
    updateParam('approvedfrom', filters.approvedFrom);
    updateParam('approvedto', filters.approvedTo);

    const newSearch = searchParams.toString().replaceAll('%2C', ',');

    if (currentSearch !== newSearch) {
      navigate({ search: newSearch });
    }
  }, [filters]);

  useEffect(() => {
    if (!currentParent || !currentParent.id) return;

    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getIssueTrackerOutlookSummary(currentParent.id!, filters.ibmWideManagedTags, filters.big10, filters.approvedFrom, filters.approvedTo);
      setRows(data);
      setIsLoading(false);
    };

    fetchData();
  }, [currentParent?.id, filters]);

  if (!currentParent) return <h2>Product not found</h2>;

  let outlooks: string[] = [];

  if (rows.length !== 0) {
    outlooks = rows[0].by_outlook.map((o) => o.outlook);
  }

  const queryParams = { managedtags: filters.ibmWideManagedTags, big10: filters.big10, approvedfrom: filters.approvedFrom, approvedto: filters.approvedTo };

  return (
    <>
      <h4>Summary of approved Issue Tracker ideas by idea delivery outlook</h4>
      <IssueTrackerSummaryFilterBar filters={filters} setFilters={setFilters} showManagedTags showBig10 showApprovedDate />
      <IssueTrackerSummaryFilteringBy filters={filters} setFilters={setFilters} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Table className={`${styles.summaryTable} ${styles.summaryOutlookTable}`} size="lg" useZebraStyles={false}>
            <TableHead>
              <TableRow>
                <TableHeader>Product</TableHeader>
                {outlooks.map((outlook) => (
                  <TableHeader key={outlook}>{outlook}</TableHeader>
                ))}
                <TableHeader>Rejected by Product Team</TableHeader>
                <TableHeader>Resolved Ideas</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} className={`${styles.summaryRow} ${row.total_row ? styles.totalRow : ''}`}>
                  <TableCell>
                    {row.total_row ? (
                      'Total'
                    ) : (
                      <Link to={`${isIBMSoftware ? '../../../' : './'}${row.slug}${queryParamsToStr(queryParams)}`}>{row.name}</Link>
                    )}
                  </TableCell>
                  {outlooks.map((outlook) => (
                    <SummaryTableCell
                      key={outlook}
                      value={row.by_outlook.find((o) => o.outlook === outlook)?.ideas || 0}
                      to={(row.total_row || isIBMSoftware) ? null : `./${row.slug}${queryParamsToStr({ ...queryParams, outlook })}`}
                    />
                  ))}
                  <SummaryTableCell
                    value={row.closed_rejected_ideas}
                    to={(row.total_row || isIBMSoftware) ? null : `./${row.slug}${queryParamsToStr({ ...queryParams, status: 'Rejected by Product Team' })}`}
                  />
                  <SummaryTableCell
                    value={row.closed_resolved_ideas}
                    to={(row.total_row || isIBMSoftware) ? null : `./${row.slug}${queryParamsToStr({ ...queryParams, status: 'Resolved Ideas' })}`}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <h4>Delivery Outlook Key</h4>
          <table className={styles.outlookDefinitions}>
            <tbody>
              <tr>
                <th>Outlook TBD:</th>
                <td>Ideas that have been accepted by the product scout team into the tracker but have yet to be jointly reviewed with the product management team.</td>
              </tr>
              <tr>
                <th>Planned:</th>
                <td>Ideas that are included as a roadmap epic for the stated period.</td>
              </tr>
              <tr>
                <th>Candidate:</th>
                <td>Ideas that will be included in a roadmap item review for the stated year. Candidate duration is limited to 18 months.</td>
              </tr>
              <tr>
                <th>Rejected by Product Team:</th>
                <td>Ideas that have been rejected and closed during the course of the ideas triage process.</td>
              </tr>
              <tr>
                <th>Resolved Ideas:</th>
                <td>Ideas that have been acted on and closed out, either through feature delivery or by identifying an existing function that meets the need.</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default OutlookSummary;
