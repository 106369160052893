import { Checkbox } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import styles from './SettingsPage.module.css';
import { updateUserSettings } from '../../../utils/api';
import { useToastContext } from '../../../context/ToastContext';
import { useUserContext } from '../../../context/UserContext';

function SettingsPage() {
  const { addToast } = useToastContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [notification, setNotification] = useState(true);
  const { user, setGlobalNotification } = useUserContext();

  useEffect(() => {
    const fetchNotifications = async () => {
      const globalNotification = user?.settings?.global_email_notification === true || user?.settings?.global_email_notification === 'true';
      setNotification(globalNotification);
      setIsLoading(false);
    };

    fetchNotifications();
  }, []);

  const updateNotifications = async (value: boolean) => {
    setIsUpdating(true);
    try {
      setNotification(value);
      await updateUserSettings('global_email_notification', value);
      setGlobalNotification(value);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to update notification settings',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsUpdating(false);
  };

  return (
    <div>
      <section>
        <h2>Manage Ideas Portal Notifications</h2>
        <table className={styles.settingsTable}>
          <tbody>
            <tr>
              <td className={styles.notificationCheckbox}>
                <Checkbox
                  id="select-idea-notifications"
                  labelText=""
                  checked={notification}
                  disabled={isLoading || isUpdating}
                  onChange={updateNotifications}
                />
              </td>
              <td>Enable/Disable Ideas Portal email notifications</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default SettingsPage;
