import { MultiSelect } from 'carbon-components-react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGroupsContext } from '../../../context/GroupsContext';
import SearchInput from '../../SearchInput/SearchInput';
import SortDropdown from './SortDropdown/SortDropdown';
import Tag from '../../Tag/Tag';
import styles from './ListingFilters.module.css';
import { IdeaProduct, IdeaProductCategory } from '../../../models/product';
import { useCustomFieldsContext } from '../../../context/CustomFieldsContext';
import { useUserContext } from '../../../context/UserContext';
import { IdeaStatus } from '../../../models/status';

export interface IdeasListingFilters {
  sort: string;
  query: string;
  productIds: string[];
  categoryIds: string[];
  tagIds: string[];
  ibmWideManagedTags: string[];
  statuses: string[];
}

interface ListingFiltersProps {
  products: IdeaProduct[];
  statuses: IdeaStatus[];
  categories: IdeaProductCategory[];
  showQueryFilter?: boolean;
  hideProductFilter?: boolean;
  hideCategoryFilter?: boolean;
  showRelevanceSort?: boolean;
  filters: IdeasListingFilters;
  setFilters: (fn: (prevFilters: IdeasListingFilters) => IdeasListingFilters) => void;
}

interface ProductCategoryItem {
  product_id: string;
  product_name: string;
  category_id: string | null;
  category_name: string | null;
  idea_count: number;
}

function ListingFilters({ products, categories, statuses, showQueryFilter = false, hideProductFilter = false, hideCategoryFilter = true, showRelevanceSort = false, filters, setFilters }: ListingFiltersProps) {
  const { isIBMEmployee } = useUserContext();
  const { currentGroupTags } = useGroupsContext();
  const { managedTags } = useCustomFieldsContext();

  const { groupId } = useParams();

  const [searchInput, setSearchInput] = useState(filters.query);

  const selectedStatusItems = useMemo(
    () => statuses.filter((status) => filters.statuses.includes(status.name)),
    [statuses, filters.statuses]
  );

  const filterTags = useMemo(
    () => currentGroupTags.filter((tag) => filters.tagIds.includes(tag.id)),
    [currentGroupTags, filters.tagIds]
  );

  const filterCategories = useMemo(
    () => categories.filter((category) => filters.categoryIds.includes(category.id)),
    [categories, filters.categoryIds]
  );

  const productCategoryItems = useMemo(
    () => {
      const items: ProductCategoryItem[] = [];

      for (const product of products) {
        items.push({
          product_id: product.id,
          product_name: product.name,
          category_id: null,
          category_name: null,
          idea_count: product.idea_count,
        });

        for (const category of product.categories) {
          items.push({
            product_id: product.id,
            product_name: product.name,
            category_id: category.id,
            category_name: category.name,
            idea_count: category.idea_count,
          });
        }
      }

      return items;
    },
    [products]
  );

  const selectedProductCategoryItems = useMemo(
    () => productCategoryItems.filter((item) => {
      if (item.category_id) {
        return filters.categoryIds.includes(item.category_id);
      }
      return filters.productIds.includes(item.product_id);
    }),
    [productCategoryItems, filters.productIds, filters.categoryIds]
  );

  const onSearchQueryChange = useCallback(
    (query: string) => setFilters((prevFilters) => ({ ...prevFilters, query })),
    [setFilters]
  );

  return (
    <div className={styles.listingFilters}>
      <div className={styles.filterQueryContainer}>
        {showQueryFilter && (
          <SearchInput
            placeholder="Filter ideas"
            size="sm"
            value={searchInput}
            onChange={setSearchInput}
            onSearchChange={onSearchQueryChange}
          />
        )}
      </div>
      {!hideProductFilter && (productCategoryItems.length !== 0) && (
        <MultiSelect
          id="product-filter"
          label="Product"
          titleText="Product"
          hideLabel
          type="inline"
          light
          size="sm"
          selectionFeedback="fixed"
          items={productCategoryItems}
          itemToString={(productItem) => productItem.product_name + (productItem.category_id ? ` - ${productItem.category_name}` : '')}
          itemToElement={(productItem) => (
            productItem.category_id ? (
              <div className={`${styles.dropdownItem} ${styles.categoryItem}`}>
                <div className={styles.dropdownName}>{productItem.category_name}</div>
                <div>({productItem.idea_count})</div>
              </div>
            ) : (
              <div className={`${styles.dropdownItem} ${styles.productItem}`}>
                <div className={styles.dropdownName}>{productItem.product_name}</div>
                <div>{productItem.idea_count}</div>
              </div>
            )
          )}
          selectedItems={selectedProductCategoryItems}
          onChange={({ selectedItems }) => {
            let selectedItemsFiltered = selectedItems;

            if (selectedItems.length >= 1) {
              const lastItem = selectedItems[selectedItems.length - 1];
              if (lastItem.category_id) {
                // Clicked a category, remove parent product
                selectedItemsFiltered = selectedItems.filter((item) => item.category_id || item.product_id !== lastItem.product_id);
              } else {
                // Clicked a product, remove child categories
                selectedItemsFiltered = selectedItems.filter((item) => !item.category_id || item.product_id !== lastItem.product_id);
              }
            }

            const productIds: string[] = [];
            const categoryIds: string[] = [];

            for (const item of selectedItemsFiltered) {
              if (item.category_id) {
                categoryIds.push(item.category_id);
              } else {
                productIds.push(item.product_id);
              }
            }

            setFilters((prevFilters) => ({ ...prevFilters, productIds, categoryIds }));
          }}
        />
      )}
      {!hideCategoryFilter && (categories.length !== 0) && (
        <MultiSelect
          id="category-filter"
          label="Category"
          titleText="Category"
          hideLabel
          type="inline"
          light
          size="sm"
          items={categories}
          itemToString={(category) => category.name}
          itemToElement={(category) => (
            <div className={styles.dropdownItem}>
              <div className={styles.dropdownName}>{category.name}</div>
              <div>{category.idea_count}</div>
            </div>
          )}
          selectedItems={filterCategories}
          onChange={({ selectedItems }) => {
            const categoryIds = selectedItems.map((c) => c.id);
            setFilters((prevFilters) => ({ ...prevFilters, categoryIds }));
          }}
        />
      )}
      {groupId && currentGroupTags.length !== 0 && (
        <MultiSelect
          id="tag-filter"
          label="Tag"
          titleText="Tag"
          hideLabel
          light
          size="sm"
          selectionFeedback="fixed"
          items={currentGroupTags}
          selectedItems={filterTags}
          itemToString={(tag) => tag.name}
          itemToElement={(tag) => <Tag name={tag.name} colour={tag.colour} />}
          onChange={({ selectedItems }) => {
            const tagIds = selectedItems.map((t) => t.id);
            setFilters((prevFilters) => ({ ...prevFilters, tagIds }));
          }}
        />
      )}
      {isIBMEmployee && (
        <MultiSelect
          id="ibm-wide-managed-tag-filter"
          label="IBM Wide Managed Tag"
          titleText="IBM Wide Managed Tag"
          hideLabel
          light
          size="sm"
          selectionFeedback="fixed"
          items={managedTags}
          selectedItems={filters.ibmWideManagedTags}
          itemToString={(tag) => tag}
          onChange={({ selectedItems }) => {
            setFilters((prevFilters) => ({ ...prevFilters, ibmWideManagedTags: [...selectedItems] }));
          }}
        />
      )}
      {(statuses.length !== 0) && (
        <MultiSelect
          id="status-filter"
          label="Status"
          titleText="Status"
          hideLabel
          type="inline"
          light
          size="sm"
          items={statuses}
          itemToString={(status) => status.name}
          itemToElement={(status) => (
            <div className={styles.dropdownItem}>
              <div className={styles.dropdownName}>{status.name}</div>
              <div>{status.idea_count}</div>
            </div>
          )}
          selectedItems={selectedStatusItems}
          onChange={({ selectedItems }) => {
            const statusItemNames = selectedItems.map((statusItem) => statusItem.name);
            setFilters((prevFilters) => ({ ...prevFilters, statuses: [...statusItemNames] }));
          }}
        />
      )}

      <SortDropdown
        selectedValue={filters.sort}
        onChange={(sort) => setFilters((prevFilters) => ({ ...prevFilters, sort }))}
        showRelevance={showRelevanceSort}
      />
    </div>
  );
}

export default ListingFilters;
