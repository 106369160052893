import { UnorderedList, ListItem } from 'carbon-components-react';
import styles from './IssueTrackerHome.module.css';

function IssueTrackerHome() {
  return (
    <div className={styles.IssueTrackerHome}>
      <section>
        <h4>What is the Issue Tracker?</h4>
        <p>
          <UnorderedList>
            <ListItem>A management system that allows <em>Product Scouts</em> to maintain a list of the highest priorty ideas</ListItem>
            <ListItem>A platform that drives the regular interlock that <em>Product Scouts</em> have with Product teams</ListItem>
            <ListItem>The place that the outcomes of these discussions are summarised to the <em>CSMs</em>, <em>Tech Sellers</em>, <em>Client Engineers</em> and <em>Expert Labs</em></ListItem>
          </UnorderedList>
        </p>
      </section>
      <section>
        <h4>What is a <em>Product Scout</em>?</h4>
        <p>
          <UnorderedList>
            <ListItem>A brand specific representative for the IBM Technical Community for product issues</ListItem>
            <ListItem>Someone who plays an important role in the IBM Technical Community &harr; Product Management Feedback Loop, collecting GEO/brand specific feedback and working with the WW team to gain resolutions and pass that information back to the Community</ListItem>
          </UnorderedList>
        </p>
      </section>
      <section>
        <h4>What products are included in the Issue Tracker?</h4>
        <p>
          <UnorderedList>
            <ListItem>The Issue Tracker is currently aimed at the IBM Software business unit</ListItem>
            <ListItem>Cloud Paks and other strategic software offerings are included as determined by the executive teams across <em>Product Management</em>, <em>CSM</em>, <em>Tech Sales</em>, <em>Client Engineering</em> and <em>Expert Labs</em></ListItem>
            <ListItem>If you believe a product should be added to the coverage model, please connect with the WW practice leader for the brand as documented <a href="https://w3.ibm.com/w3publisher/product-scout-program">here</a></ListItem>
          </UnorderedList>
        </p>
      </section>
      <section>
        <h4>Why can&apos;t I see Business Impact Statements?</h4>
        <p>
          Data in this field contains details that in general might be IBM Confidential. While we encourage all IBMers to provide this justifaction as part of raising an idea for inclusion within the Issue Tracker, currently only approved <em>Product Scout leaders</em> will be able to subsequently view this information within this tool.
        </p>
      </section>
      <section>
        <p>Find out more about the Product Scout initiative <a href="https://w3.ibm.com/w3publisher/product-scout-program">here</a></p>
      </section>
    </div>
  );
}

export default IssueTrackerHome;
