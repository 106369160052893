import { useState, useEffect } from 'react';
import { InlineLoading } from 'carbon-components-react';
import ReactTimeAgo from 'react-time-ago';
import { getGroupMemberRequestLogs } from '../../../../utils/api';
import { useGroupsContext } from '../../../../context/GroupsContext';
import { GroupMemberRequestLogs } from '../../../../models/groupMember';

import styles from './GroupMemberRequestLogTable.module.css';

function GroupMemberRequestLogTable() {
  const [isLoading, setIsLoading] = useState(false);
  const { currentGroup } = useGroupsContext();
  const [requestLogs, setRequestLogs] = useState<GroupMemberRequestLogs[]>([]);

  useEffect(() => {
    const fetchMemberRequests = async () => {
      if (!currentGroup) return;
      setIsLoading(true);
      const { data } = await getGroupMemberRequestLogs(currentGroup.id);
      setRequestLogs(data);
      setIsLoading(false);
    };

    fetchMemberRequests();
  }, [currentGroup?.id]);

  if (!currentGroup) return null;

  if (isLoading) {
    return <InlineLoading description="Loading group member request logs..." />;
  }

  if (requestLogs.length === 0) {
    return <p>There are no group member request logs</p>;
  }

  const logs = requestLogs.map((log) => (
    <tr>
      <td>
        {log.actioned_by__email} {log.event.toLowerCase()} {log.portal_user_email}
      </td>
      <td>
        <ReactTimeAgo date={new Date(log.date)} />
      </td>
    </tr>
  ));

  return (
    <>
      <p className={styles.note}>Logs before August 2023 are not available</p>
      <table className={styles.requestLogTable}>
        <thead />
        <tbody>{logs}</tbody>
      </table>
    </>
  );
}

export default GroupMemberRequestLogTable;
