import { useRef, useState } from 'react';

function usePagination() {
  const [currentPage, setCurrentPage] = useState(1);
  const isResettingPageRef = useRef(false);
  let customScrollID: string;

  const changePage = (newPage: number, scroll: boolean = true) => {
    isResettingPageRef.current = false;
    setCurrentPage(newPage);
    if (scroll) {
      window.scrollTo(0, 0);
    }
    if (customScrollID) {
      const element = document.getElementById(customScrollID);
      if (element) element.scrollIntoView();
    }
  };

  const setScrollToID = (scroll: any) => {
    customScrollID = scroll;
  };

  const resetPage = () => {
    isResettingPageRef.current = true;
    window.scrollTo(0, 0);
    setCurrentPage(1);
  };

  const isResettingPage = isResettingPageRef.current && currentPage !== 1;

  return { currentPage, changePage, resetPage, isResettingPage, setScrollToID };
}

export default usePagination;
