import { Idea, Tag, Edit, UserMultiple, Save, Settings } from '@carbon/icons-react';
import { Button, Form, InlineNotification, OverflowMenu, OverflowMenuItem, TextInput } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useGroupsContext } from '../../context/GroupsContext';
import { formatDate } from '../../utils/dates';
import { deleteGroup, getGroup, removeGroupMember, updateGroup, getHiddenIdeas, requestGroupAccess } from '../../utils/api';
import styles from './Group.module.css';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import ButtonSpinner from '../../components/ButtonSpinner/ButtonSpinner';
import { useToastContext } from '../../context/ToastContext';
import { useUserContext } from '../../context/UserContext';

function Group() {
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const { fetchGroups, isLoadingGroups, setCurrentGroupId, currentGroup } = useGroupsContext();

  const { groupId } = useParams();
  const navigate = useNavigate();

  const [isLeaveGroupDialogOpen, setIsLeaveGroupDialogOpen] = useState(false);
  const [isDeleteGroupDialogOpen, setIsDeleteGroupDialogOpen] = useState(false);
  const [isEditGroupName, setIsEditGroupName] = useState(false);

  const [isListingAllHiddenIdeas, setIsListingAllHiddenIdeas] = useState(false);

  const [hiddenIdeaRefs, setHiddenIdeaRefs] = useState<String[]>([]);
  const [hiddenIdeaCount, setHiddenIdeaCount] = useState(0);

  const [groupName, setGroupName] = useState('');

  const [isNotAuth, setIsNotAuth] = useState(false);
  const [isAccessRequestPending, setIsAccessRequestPending] = useState(false);
  const [isSendingAccessRequest, setIsSendingAccessRequest] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    setCurrentGroupId(groupId || null);
    setIsNotAuth(false);
    setIsAccessRequestPending(false);
    setIsNotFound(false);
  }, [groupId]);

  const isLastAdmin = (currentGroup?.is_admin && currentGroup?.admin_count === 1) || false;

  const getHiddenIdeasList = async () => {
    if (!groupId) return;

    try {
      const { data } = await getHiddenIdeas(groupId);
      setHiddenIdeaCount(data.idea_count);
      setHiddenIdeaRefs(data.idea_refs);
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 403) {
          setIsNotAuth(true);
        } else if (error.response.status === 404) {
          setIsNotFound(true);
        }
      }
    }
  };

  useEffect(() => {
    const getGroupStatus = async () => {
      if (!groupId) return;

      getHiddenIdeasList();

      try {
        await getGroup(groupId);
      } catch (err) {
        const error = err as Error | AxiosError;
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 403) {
            setIsNotAuth(true);
            const isRequestPending = (error.response.data as any).detail.includes('pending access request');
            setIsAccessRequestPending(isRequestPending);
          } else if (error.response.status === 404) {
            setIsNotFound(true);
          }
        }
      }
    };

    if (!isLoadingGroups && !currentGroup) {
      getGroupStatus();
    }
  }, [isLoadingGroups, currentGroup]);

  const onUpdateGroupClick = async () => {
    if (!currentGroup) return;

    setIsEditGroupName(false);

    try {
      currentGroup.name = groupName;
      await updateGroup(currentGroup.id, groupName);
    } catch (error) {
      addToast({
        kind: 'error',
        title: 'Failed updating group',
        message: 'Something went wrong, please try again later'
      });
    }

    await fetchGroups();
  };

  const onLeaveGroup = async () => {
    if (!currentGroup || !user) return;

    try {
      await removeGroupMember(currentGroup.id, user.email);
      await fetchGroups();
      navigate('/groups');
    } catch (error) {
      addToast({
        kind: 'error',
        title: 'Failed leaving group',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const onDeleteGroup = async () => {
    if (!currentGroup) return;

    try {
      await deleteGroup(currentGroup.id);
      await fetchGroups();
      navigate('/groups');
    } catch (error) {
      addToast({
        kind: 'error',
        title: 'Failed deleting group',
        message: 'Something went wrong, please try again later'
      });
    }
  };

  const onRequestAccess = async () => {
    if (!groupId) return;
    setIsSendingAccessRequest(true);

    try {
      await requestGroupAccess(groupId);
      setIsAccessRequestPending(true);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to request group access',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsSendingAccessRequest(false);
  };

  if (!currentGroup) {
    if (isNotAuth) {
      return (
        <>
          <InlineNotification
            className={styles.errorMessage}
            kind="error"
            title={isAccessRequestPending ? 'Your access request is pending' : 'You are not authorised to see this group'}
            subtitle={isAccessRequestPending && 'Please ask a group admin to approve your access request.'}
            lowContrast
            hideCloseButton
          />
          {!isAccessRequestPending && (
            <ButtonSpinner
              onClick={onRequestAccess}
              isLoading={isSendingAccessRequest}
              loadingText="Sending access request..."
            >
              Request Access
            </ButtonSpinner>
          )}
        </>
      );
    }

    if (isNotFound) {
      return (
        <InlineNotification
          className={styles.errorMessage}
          kind="error"
          title="Group could not be found"
          subtitle="Please check the URL and try again"
          lowContrast
          hideCloseButton
        />
      );
    }

    return <LoadingSpinner text="Loading group..." />;
  }

  return (
    <React.Fragment key={groupId}>
      <ConfirmationDialog
        isOpen={isLeaveGroupDialogOpen}
        onClose={() => setIsLeaveGroupDialogOpen(false)}
        onSubmit={onLeaveGroup}
        message="Are you sure you want to leave this group?"
        submitButtonText="Leave Group"
      />
      <ConfirmationDialog
        isOpen={isDeleteGroupDialogOpen}
        onClose={() => setIsDeleteGroupDialogOpen(false)}
        onSubmit={onDeleteGroup}
        message="Are you sure you want to delete this group?"
        submitButtonText="Delete Group"
      />

      <h2 className={styles.header}>
        Group:
        {(isEditGroupName) ? (
          <Form className={styles.groupNameContainer} onSubmit={(e) => { e.preventDefault(); onUpdateGroupClick(); }}>
            <TextInput
              className={styles.groupNameInput}
              id="new-group-name"
              labelText=""
              placeholder="New Group Name"
              autoComplete="off"
              autoFocus
              maxLength={50}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <Button
              kind="ghost"
              size="sm"
              type="submit"
              disabled={groupName.trim().length === 0}
              iconDescription="Save group name"
              renderIcon={Save}
              hasIconOnly
            />
          </Form>
        ) : (
          <div className={styles.groupNameContainer}>
            <strong className={styles.groupName}>{currentGroup.name}</strong>
            {currentGroup.is_admin && (
            <Button
              kind="ghost"
              size="sm"
              iconDescription="Edit group name"
              renderIcon={Edit}
              hasIconOnly
              onClick={() => { setGroupName(currentGroup.name); setIsEditGroupName(true); }}
            />
            )}
          </div>
        )}
      </h2>
      <div className={styles.metadata}>
        Created by: <strong>{currentGroup.creator}</strong> on{' '}
        <strong>{formatDate(currentGroup.creation_date)}</strong>
      </div>
      {(hiddenIdeaCount > 0
      && (
        <div className={styles.hiddenideas}>
          {(hiddenIdeaCount > 1 ? `The following ${hiddenIdeaCount} ideas are hidden to you:`
            : 'This idea is hidden to you:'
          )}
          {(isListingAllHiddenIdeas ? <span className={styles.hiddenIdeasRefs}>{hiddenIdeaRefs.join(', ').toString()}</span>
            : (
              <>
                <span className={styles.hiddenIdeasRefs}>{hiddenIdeaRefs.slice(0, 5).join(', ')}</span>
                {(hiddenIdeaCount > 5 && (
                <button type="button" className={styles.hiddenIdeasButton} onClick={() => setIsListingAllHiddenIdeas(true)}>
                  + {hiddenIdeaCount - 5} other {(hiddenIdeaCount - 5) > 1 ? 'ideas' : 'idea'}
                </button>
                ))}
              </>
            ))}
        </div>
      )
      )}
      <div className={styles.groupOptions}>
        <div className={styles.tabs}>
          <NavLink to="." end>
            <Idea /> Ideas
          </NavLink>
          <NavLink to="members">
            <UserMultiple /> Members
            {currentGroup.is_admin && currentGroup.user_request_count !== 0 && (
              <span className={styles.memberRequestCount}>{currentGroup.user_request_count}</span>
            )}
          </NavLink>
          <NavLink to="tags">
            <Tag /> Tags
          </NavLink>
        </div>
        <div>
          <OverflowMenu
            ariaLabel="Group settings"
            className="bx--tooltip__trigger bx--tooltip--icon__top"
            flipped
            renderIcon={() => <Settings size={20} />}
            iconDescription="Group settings"
            size="sm"
          >
            <div className={`${styles.leaveGroupBtn} ${isLastAdmin ? 'bx--tooltip__trigger bx--tooltip--icon__top' : ''}`} aria-label="Cannot remove last admin">
              <OverflowMenuItem itemText="Leave Group" disabled={isLastAdmin} onClick={() => setIsLeaveGroupDialogOpen(true)} />
            </div>
            {currentGroup?.is_admin && (
              <OverflowMenuItem itemText="Delete Group" onClick={() => setIsDeleteGroupDialogOpen(true)} isDelete />
            )}
          </OverflowMenu>
        </div>
      </div>
      <Outlet key={groupId} />
    </React.Fragment>
  );
}

export default Group;
