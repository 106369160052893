/* eslint-disable import/prefer-default-export */

function colorToHex(color: number): string {
  const hex = color.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r: number, g: number, b: number): string {
  const rHex = colorToHex(r);
  const gHex = colorToHex(g);
  const bHex = colorToHex(b);

  return `#${rHex}${gHex}${bHex}`;
}

function hexToRGB(hex: string): { r: number; g: number; b: number } {
  return {
    r: parseInt(hex.substr(1, 2), 16),
    g: parseInt(hex.substr(3, 2), 16),
    b: parseInt(hex.substr(5, 2), 16)
  };
}

export function lighten(hex: string, amount: number): string {
  let { r, g, b } = hexToRGB(hex);

  r = Math.floor(r + (255 - r) * amount);
  g = Math.floor(g + (255 - g) * amount);
  b = Math.floor(b + (255 - b) * amount);

  return rgbToHex(r, g, b);
}
