import GroupsTable from './GroupsTable/GroupsTable';
import NewGroupForm from './NewGroupForm/NewGroupForm';
import styles from './Groups.module.css';

function Groups() {
  return (
    <>
      <h2>My Shared Groups</h2>
      <section className={styles.section}>
        <GroupsTable />
      </section>
      <section className={styles.section}>
        <NewGroupForm />
      </section>
    </>
  );
}

export default Groups;
