import { EditorUI, EditorUIView } from '@ckeditor/ckeditor5-ui';
import { ElementReplacer } from '@ckeditor/ckeditor5-utils';
import { Editor } from '@ckeditor/ckeditor5-core';
import { enablePlaceholder } from '@ckeditor/ckeditor5-engine';
import CarbonEditorUIView from './CarbonEditorUIView';

// The class organizing the UI of the editor, binding it with existing Carbon elements in the DOM.
export default class CarbonEditorUI extends EditorUI {
  elementReplacer: ElementReplacer;

  myView: EditorUIView;

  constructor(editor: Editor) {
    super(editor);

    // A helper to easily replace the editor#element with editor.editable#element.
    this.elementReplacer = new ElementReplacer();

    // The global UI view of the editor. It aggregates various Carbon DOM elements.
    const uiElement = editor.config.get('uiElement') as HTMLElement;
    this.myView = new CarbonEditorUIView(editor.locale, editor.editing.view, uiElement);
  }

  // All EditorUI subclasses should expose their view instance
  // so other UI classes can access it if necessary.
  get view() {
    return this.myView;
  }

  init(replacementElement: HTMLElement) {
    const { editor } = this;
    const editingView = editor.editing.view;

    // Make sure the EditorUIView is rendered. This will, for instance, create a place for UI elements
    // like floating panels detached from the main editor UI in DOM.
    this.myView.render();

    // Create an editing root in the editing layer. It will correspond with the
    // document root created in the constructor().
    const editingRoot = editingView.document.getRoot()!;

    // The editable UI and editing root should share the same name.
    this.myView.editable.name = editingRoot.rootName;

    // Render the editable component in the DOM first.
    this.myView.editable.render();

    const editableElement = this.myView.editable.element;

    // Register editable element so it is available via getEditableElement() method.
    this.setEditableElement(this.myView.editable.name, editableElement as HTMLElement);

    // Let the editable UI element respond to the changes in the global editor focus tracker
    // and let the focus tracker know about the editable element.
    // this.focusTracker.add(editableElement as HTMLElement);
    this.myView.editable.bind('isFocused').to(this.focusTracker);

    // Bind the editable UI element to the editing view, making it an end– and entry–point
    // of the editor's engine. This is where the engine meets the UI.
    editingView.attachDomRoot(editableElement!);

    // Replace the editor#element with editor.editable#element.
    this.elementReplacer.replace(replacementElement, editableElement as HTMLElement);

    const placeholderText = editor.config.get('placeholder') as string;

    if (placeholderText) {
      enablePlaceholder({
        view: editingView,
        element: editingRoot,
        text: placeholderText,
        isDirectHost: false,
        keepOnFocus: true
      });
    }

    // Tell the world that the UI of the editor is ready to use.
    this.fire('ready');
  }

  destroy() {
    // Restore the original editor#element.
    this.elementReplacer.restore();

    // Destroy the view.
    this.myView.editable.destroy();
    this.myView.destroy();

    super.destroy();
  }
}
