import { Button, ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCustomFieldsContext } from '../../../context/CustomFieldsContext';
import { useToastContext } from '../../../context/ToastContext';
import { addIBMWideManagedTagToIdeas } from '../../../utils/api';
import styles from './MultiAddManagedTagDialog.module.css';

interface MultiAddManagedTagDialogProps {
  selectedIdeaIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function MultiAddManagedTagDialog({ selectedIdeaIds, isOpen, onClose, onSubmit }: MultiAddManagedTagDialogProps) {
  const { addToast } = useToastContext();
  const { managedTags } = useCustomFieldsContext();

  const [ideaIds, setIdeaIds] = useState<string[]>([]);
  const [selectedManagedTag, setSelectedManagedTag] = useState<string | null>('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Reset selected tag ID and idea IDs when opening the dialog
      setSelectedManagedTag(null);
      setIdeaIds([...selectedIdeaIds]);
    }
  }, [isOpen]);

  const addIBMWideManagedTag = async (tag: string) => {
    if (!selectedManagedTag) return;

    setIsSubmitting(true);
    try {
      await addIBMWideManagedTagToIdeas(ideaIds, tag);
      onSubmit();
      onClose();
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add ibm wide managed tag to ideas',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsSubmitting(false);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.multiAddManagedTagDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title={`Add an IBM wide managed tag to ${ideaIds.length} ${ideaIds.length === 1 ? 'idea' : 'ideas'}`} />
      <ModalBody>
        <ComboBox
          className={styles.addTagComboBox}
          autoFocus
          shouldFilterItem={({ inputValue, item, itemToString }) => {
            if (!inputValue) return true;
            return itemToString!(item).toLowerCase().includes(inputValue.toLowerCase());
          }}
          id="add-managed-tag"
          placeholder="Add managed tag"
          type="inline"
          size="sm"
          items={managedTags}
          selectedItem={selectedManagedTag}
          onFocus={(e) => e.target.click()}
          onChange={({ selectedItem }) => {
            setSelectedManagedTag(selectedItem as string);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" disabled={isSubmitting} onClick={onClose}>Cancel</Button>
        <div className={styles.submitContainer}>
          {isSubmitting ? (
            <InlineLoading
              className={styles.submitLoading}
              description={ideaIds.length === 1 ? 'Adding tag to idea...' : `Adding tag to ${ideaIds.length} ideas...`}
            />
          ) : (
            <Button
              kind="primary"
              onClick={() => addIBMWideManagedTag(selectedManagedTag!)}
              disabled={(selectedManagedTag === '') || (selectedManagedTag === null)}
            >
              {ideaIds.length === 1 ? 'Add tag to idea' : `Add tag to ${ideaIds.length} ideas`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default MultiAddManagedTagDialog;
