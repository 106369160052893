import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { IssueTrackerItem } from '../../models/issueTracker';
import ItemClusterCard from '../ItemCard/ItemClusterCard';

interface IssueTrackerListingItemsProps {
  isLoading: boolean;
  items: IssueTrackerItem[];
  onRefresh: () => void;
  onItemChange: (itemId: string, fn: (prevItem: IssueTrackerItem) => IssueTrackerItem) => void;
}

type IssueTrackerCluster = {
  items: IssueTrackerItem[];
  uuid: string;
};

function IssueTrackerListingItems({ isLoading, items, onRefresh, onItemChange }: IssueTrackerListingItemsProps) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (items.length === 0) {
    return <p>There are no items to show</p>;
  }

  const itemClusters: IssueTrackerCluster[] = [];

  items.forEach((item) => {
    if (item.related_cluster_uuid) {
      const existingCluster = itemClusters.find((cluster) => cluster.uuid === item.related_cluster_uuid);

      if (existingCluster) {
        // If the cluster ID already exists, put this item in with it
        existingCluster.items.push(item);
      } else {
        // If this is the first time we've seen the cluster ID on the page, create a new cluster
        itemClusters.push({
          items: [item],
          uuid: item.related_cluster_uuid
        });
      }
    } else {
      // Create a cluster for single items and put just this item in
      // This is so that we can treat all boxes as clusters
      itemClusters.push({
        items: [item],
        uuid: item.id
      });
    }
  });

  return (
    <div>
      {itemClusters.map((cluster) => (
        <ItemClusterCard
          key={cluster.uuid}
          items={cluster.items}
          onRefresh={onRefresh}
          onItemChange={onItemChange}
        />
      ))}
    </div>
  );
}

export default IssueTrackerListingItems;
