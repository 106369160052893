import { Dropdown, MultiSelect } from 'carbon-components-react';
import { useMemo } from 'react';
import { useCustomFieldsContext } from '../../../../../context/CustomFieldsContext';
import { IdeaProduct } from '../../../../../models/product';
import styles from './IssueTrackerFilters.module.css';
import { useIssueTrackerFiltersContext } from '../../../context/IssueTrackerFiltersContext';
import { BIG_10_ITEMS } from '../../../data/big10';
import MonthRangeInput from '../../../../../components/MonthRangeInput/MonthRangeInput';
import { currentYearMonth } from '../../../../../utils/dates';
import ManagedTagFilters from '../../../../../components/ManagedTagFilters/ManagedTagFilters';

interface IssueTrackerFiltersProps {
  products: IdeaProduct[];
  hideBig10Filter?: boolean;
  hideApprovedDateFilter?: boolean;
}

interface ProductCategoryItem {
  product_id: string;
  product_name: string;
  category_id: string | null;
  category_name: string | null;
  idea_count: number;
}

const STATUS_ITEMS = ['Open', 'Rejected by Product Team', 'Resolved Ideas'];

function IssueTrackerFilters({ products, hideBig10Filter = false, hideApprovedDateFilter = false }: IssueTrackerFiltersProps) {
  const { filters, setFilters } = useIssueTrackerFiltersContext();
  const { outlooks, classificationThemes } = useCustomFieldsContext();
  const outlookOptions = ['Outlook TBD', ...outlooks];
  const productCat = products;

  const productCategoryItems = useMemo(
    () => {
      const items: ProductCategoryItem[] = [];

      for (const product of productCat) {
        items.push({
          product_id: product.id,
          product_name: product.name,
          category_id: null,
          category_name: null,
          idea_count: product.idea_count,
        });

        for (const category of product.categories) {
          items.push({
            product_id: product.id,
            product_name: product.name,
            category_id: category.id,
            category_name: category.name,
            idea_count: category.idea_count,
          });
        }
      }

      return items;
    },
    [products]
  );

  const selectedProductCategoryItems = useMemo(
    () => productCategoryItems.filter((item) => {
      if (item.category_id) {
        return filters.categoryIds.includes(item.category_id);
      }
      return filters.productIds.includes(item.product_id);
    }),
    [productCategoryItems, filters.productIds, filters.categoryIds]
  );

  const selectedBig10Item = BIG_10_ITEMS.find((item) => item.value === filters.big10);

  return (
    <div className={styles.IssueTrackerFilters}>
      <MultiSelect
        id="product"
        label="Product"
        titleText="Product"
        hideLabel
        light
        size="sm"
        selectionFeedback="fixed"
        items={productCategoryItems}
        itemToString={(productItem) => productItem.product_name + (productItem.category_id ? ` - ${productItem.category_name}` : '')}
        itemToElement={(productItem) => (
          productItem.category_id ? (
            <div className={`${styles.dropdownItem} ${styles.categoryItem}`}>
              <div className={styles.dropdownName}>{productItem.category_name}</div>
            </div>
          ) : (
            <div className={`${styles.dropdownItem} ${styles.productItem}`}>
              <div className={styles.dropdownName}>{productItem.product_name}</div>
            </div>
          )
        )}
        selectedItems={selectedProductCategoryItems}
        onChange={({ selectedItems }) => {
          let selectedItemsFiltered = selectedItems;

          if (selectedItems.length >= 1) {
            const lastItem = selectedItems[selectedItems.length - 1];
            if (lastItem.category_id) {
              // Clicked a category, remove parent product
              selectedItemsFiltered = selectedItems.filter((item) => item.category_id || item.product_id !== lastItem.product_id);
            } else {
              // Clicked a product, remove child categories
              selectedItemsFiltered = selectedItems.filter((item) => !item.category_id || item.product_id !== lastItem.product_id);
            }
          }

          const productIds: string[] = [];
          const categoryIds: string[] = [];

          for (const item of selectedItemsFiltered) {
            if (item.category_id) {
              categoryIds.push(item.category_id);
            } else {
              productIds.push(item.product_id);
            }
          }

          setFilters((prevFilters) => ({ ...prevFilters, productIds, categoryIds }));
        }}
      />
      <MultiSelect
        id="outlook"
        label="Outlook"
        titleText="Outlook"
        hideLabel
        light
        size="sm"
        selectionFeedback="fixed"
        items={outlookOptions}
        selectedItems={filters.outlooks}
        itemToString={(item) => item}
        onChange={({ selectedItems }) => {
          setFilters((prevFilters) => ({ ...prevFilters, outlooks: selectedItems }));
        }}
      />
      <MultiSelect
        id="classification-theme"
        label="Classification Theme"
        titleText="Classification Theme"
        hideLabel
        light
        size="sm"
        selectionFeedback="fixed"
        items={classificationThemes}
        selectedItems={filters.classificationThemes}
        itemToString={(item) => item}
        onChange={({ selectedItems }) => {
          setFilters((prevFilters) => ({ ...prevFilters, classificationThemes: selectedItems }));
        }}
      />
      <ManagedTagFilters filters={filters} setFilters={setFilters} />
      <Dropdown
        id="status"
        label="Status"
        titleText="Status"
        hideLabel
        light
        size="sm"
        items={STATUS_ITEMS}
        selectedItem={filters.status}
        itemToString={(item) => item}
        onChange={({ selectedItem }) => {
          setFilters((prevFilters) => ({ ...prevFilters, status: selectedItem || 'Open' }));
        }}
      />
      {!hideBig10Filter && (
        <Dropdown
          id="the-big-10"
          label="The Big 10"
          titleText="The Big 10"
          hideLabel
          light
          size="sm"
          items={BIG_10_ITEMS}
          selectedItem={selectedBig10Item}
          itemToString={(item) => item.label}
          onChange={({ selectedItem }) => {
            setFilters((prevFilters) => ({ ...prevFilters, big10: selectedItem ? selectedItem.value : null }));
          }}
        />
      )}
      {!hideApprovedDateFilter && (
        <MonthRangeInput
          minDate="2022-10"
          maxDate={currentYearMonth()}
          startDate={filters.approvedFrom}
          endDate={filters.approvedTo}
          onRangeChange={(startDate, endDate) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              approvedFrom: startDate,
              approvedTo: endDate
            }));
          }}
        />
      )}
      <ManagedTagFilters filters={filters} setFilters={setFilters} finalBig10 />
    </div>
  );
}

export default IssueTrackerFilters;
