/* eslint-disable import/prefer-default-export */

import { Idea } from '../models/idea';
import { IssueTrackerInclusionState, ISSUE_TRACKER_REJECTED_STATES } from './issueTrackerInclusionStates';
import { REJECTED_BY_PRODUCT_TEAM_STATES, RESOLVED_IDEAS_STATES, WorkflowState } from './workflowState';

export function getIdeaIssueTrackerURL(idea: Idea): string | null {
  if (!idea.issue_tracker_inclusion || idea.issue_tracker_inclusion === IssueTrackerInclusionState.UNSET || !idea.issue_tracker_top_product_slug || !idea.issue_tracker_child_product_slug) {
    return null;
  }

  let issueTrackerURL = `/issue-tracker/${idea.issue_tracker_top_product_slug}/${idea.issue_tracker_child_product_slug}`;

  if (idea.issue_tracker_inclusion === IssueTrackerInclusionState.APPROVED_BY_GEO) {
    issueTrackerURL += '/approved-by-geo';
  } else if (idea.issue_tracker_inclusion === IssueTrackerInclusionState.CANDIDATE) {
    issueTrackerURL += '/candidate';
  } else if (ISSUE_TRACKER_REJECTED_STATES.includes(idea.issue_tracker_inclusion)) {
    issueTrackerURL += '/rejected';
  }

  const workflowState = idea.workflow_state as WorkflowState;

  if (REJECTED_BY_PRODUCT_TEAM_STATES.includes(workflowState)) {
    issueTrackerURL += '?status=Rejected+by+Product+Team';
  } else if (RESOLVED_IDEAS_STATES.includes(workflowState)) {
    issueTrackerURL += '?status=Resolved+Ideas';
  }

  issueTrackerURL += `#${idea.reference}`;
  return issueTrackerURL;
}
