import { useState, useEffect } from 'react';
import { Button } from 'carbon-components-react';
import { AddAlt, SubtractAlt } from '@carbon/icons-react';
import { subscribeToIdea, unSubscribeToIdea } from '../../utils/api';
import { useToastContext } from '../../context/ToastContext';
import { useSubscribeContext } from '../../context/SubscribeContext';
import { Idea } from '../../models/idea';

import styles from './SubscribeButton.module.css';

interface SubscribeButtonProps {
  idea: Idea;
}
function SubscribeButton({ idea }: SubscribeButtonProps) {
  const { addToast } = useToastContext();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { isSubscribed, setIsSubscribed } = useSubscribeContext();

  useEffect(() => {
    setIsSubscribed(idea.is_requester_subscribed);
  }, []);

  const onSubscribeClick = async () => {
    setIsSubscribing(true);
    setIsSubscribed(true);
    try {
      await subscribeToIdea(idea.reference);
      addToast({
        kind: 'success',
        title: 'You have been Subscribed to the idea',
      });
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to subscribe to the idea',
        message: 'Something went wrong, please try again later',
      });
      setIsSubscribed(false);
    }
    setIsSubscribing(false);
  };

  const onUnSubscribeClick = async () => {
    setIsSubscribing(true);
    setIsSubscribed(false);
    try {
      await unSubscribeToIdea(idea.reference);
      addToast({
        kind: 'success',
        title: 'You have been Unsubscribed from the idea',
      });
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove subscription to the idea',
        message: 'Something went wrong, please try again later',
      });
      setIsSubscribed(true);
    }
    setIsSubscribing(false);
  };

  return (
    (isSubscribed) ? (
      <Button
        disabled={isSubscribing}
        className={styles.sidebarLink}
        onClick={() => onUnSubscribeClick()}
        size="sm"
        renderIcon={SubtractAlt}
        kind="tertiary"
      >
        Unsubscribe
      </Button>
    ) : (
      <Button
        disabled={isSubscribing}
        className={styles.sidebarLink}
        onClick={() => onSubscribeClick()}
        size="sm"
        renderIcon={AddAlt}
        kind="tertiary"
      >
        Subscribe
      </Button>
    )
  );
}

export default SubscribeButton;
