import { Chat } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import styles from './CommentButton.module.css';

interface CommentButtonProps {
  reference: string;
  commentCount: number;
}

function CommentButton({ reference, commentCount }: CommentButtonProps) {
  const { isIBMEmployee } = useUserContext();

  return (
    <div className={styles.commentBtn}>
      {isIBMEmployee ? (
        <Link to={`/ideas/${reference}/#idea-comments`}>
          <Chat aria-label="Comments" />{commentCount}
        </Link>
      ) : (
        <span><Chat aria-label="Comments" />{commentCount}</span>
      )}
    </div>
  );
}

export default CommentButton;
