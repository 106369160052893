/* eslint-disable import/prefer-default-export */

export function queryParamStrToArray(str: string | null) {
  if (!str) return [];
  return str.split(',');
}

export function queryParamsToStr(paramsObj: { [key: string]: string | string[] | null }) {
  const params = new URLSearchParams();

  Object.keys(paramsObj).forEach((key) => {
    const value = paramsObj[key];
    if (!value) return;

    if (Array.isArray(value)) {
      if (value.length !== 0) {
        params.append(key, value.join(','));
      }
    } else {
      params.append(key, value);
    }
  });

  const queryString = params.toString().replaceAll('%2C', ',');

  if (queryString === '') return '';
  return `?${queryString}`;
}
