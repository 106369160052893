import { Settings, Favorite } from '@carbon/icons-react';
import { NavLink, Outlet } from 'react-router-dom';
import styles from './SettingsMenu.module.css';

function SettingsMenu() {
  return (
    <>
      <div className={styles.settingsOptions}>
        <div className={styles.tabs}>
          <NavLink to="." end>
            <Settings /> Settings
          </NavLink>
          <NavLink to="products">
            <Favorite /> Manage Followed Products
          </NavLink>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default SettingsMenu;
