import axios, { AxiosResponse, RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios';
import { useState, useCallback, useEffect } from 'react';

type State = 'idle' | 'loading' | 'success' | 'error';

function useAPI<T>(asyncFn: (...params: any[]) => Promise<AxiosResponse<T, any>>, immediate = false) {
  const [state, setState] = useState<State>('idle');
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);
  const [headers, setHeaders] = useState<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null);

  const execute = useCallback(async (...params: any[]) => {
    setState('loading');
    try {
      const result = await asyncFn(...params);
      setError(null);
      setData(result.data);
      setHeaders(result.headers);
      setState('success');
    } catch (err: any) {
      if (!axios.isCancel(err)) {
        setError(err);
        setData(null);
        setHeaders(null);
        setState('error');
        throw err;
      }
    }
  }, [asyncFn]);

  const reset = () => {
    setError(null);
    setData(null);
    setHeaders(null);
    setState('idle');
  };

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, data, headers, state, error, reset };
}

export default useAPI;
