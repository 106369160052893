export enum IssueTrackerInclusionState {
  UNSET = 'Unset',
  CANDIDATE = 'Candidate',
  APPROVED_BY_GEO = 'Approved by GEO',
  REJECTED_BY_GEO = 'Rejected by GEO',
  APPROVED_BY_WW = 'Approved by WW',
  REJECTED_BY_WW = 'Rejected by WW',
  THE_BIG_10 = 'The Big 10'
}

export const ALL_SET_INCLUSION_STATES = [
  IssueTrackerInclusionState.CANDIDATE,
  IssueTrackerInclusionState.APPROVED_BY_GEO,
  IssueTrackerInclusionState.REJECTED_BY_GEO,
  IssueTrackerInclusionState.APPROVED_BY_WW,
  IssueTrackerInclusionState.REJECTED_BY_WW,
  IssueTrackerInclusionState.THE_BIG_10,
];

export const ALLOWED_INCLUSION_STATES_WW = [
  IssueTrackerInclusionState.UNSET,
  IssueTrackerInclusionState.CANDIDATE,
  IssueTrackerInclusionState.APPROVED_BY_GEO,
  IssueTrackerInclusionState.REJECTED_BY_GEO,
  IssueTrackerInclusionState.APPROVED_BY_WW,
  IssueTrackerInclusionState.REJECTED_BY_WW,
  IssueTrackerInclusionState.THE_BIG_10,
];

export const ALLOWED_INCLUSION_STATES_GEO = [
  IssueTrackerInclusionState.UNSET,
  IssueTrackerInclusionState.CANDIDATE,
  IssueTrackerInclusionState.APPROVED_BY_GEO,
  IssueTrackerInclusionState.REJECTED_BY_GEO,
];

export const ISSUE_TRACKER_INCLUDED_STATES = [
  IssueTrackerInclusionState.APPROVED_BY_WW,
  IssueTrackerInclusionState.THE_BIG_10,
];

export const ISSUE_TRACKER_REJECTED_STATES = [
  IssueTrackerInclusionState.REJECTED_BY_GEO,
  IssueTrackerInclusionState.REJECTED_BY_WW,
];
