/* eslint-disable react/no-danger */
import { Save, OverflowMenuVertical, Launch, ViewOff } from '@carbon/icons-react';
import { Button, Dropdown, InlineLoading, MultiSelect, OverflowMenu, OverflowMenuItem, TooltipIcon } from 'carbon-components-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonSpinner from '../../../../components/ButtonSpinner/ButtonSpinner';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import { useCustomFieldsContext } from '../../../../context/CustomFieldsContext';
import { useToastContext } from '../../../../context/ToastContext';
import { useUserContext } from '../../../../context/UserContext';
import { IdeaUpdate } from '../../../../models/idea';
import { IssueTrackerItem } from '../../models/issueTracker';
import { removeIdeaFromCluster, updateIdea } from '../../../../utils/api';
import { formatDate } from '../../../../utils/dates';
import AddIdeaToClusterDialog from '../AddIdeaToClusterDialog/AddIdeaToClusterDialog';
import styles from './ItemCard.module.css';
import { getVisibilityText } from '../../../../utils/ideaVisibility';
import RejectReasonDialog from '../RejectReasonDialog/RejectReasonDialog';
import { ALLOWED_INCLUSION_STATES_GEO, ALLOWED_INCLUSION_STATES_WW, ISSUE_TRACKER_REJECTED_STATES, IssueTrackerInclusionState } from '../../../../utils/issueTrackerInclusionStates';
import { sanitizeHTML } from '../../../../utils/sanitize';

interface ItemCardProps {
  item: IssueTrackerItem;
  onRefresh: () => void;
  onChange: (fn: (prevItem: IssueTrackerItem) => IssueTrackerItem) => void;
}

const RANK_OPTIONS = [
  { value: 1, label: '1 - Highest' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9 - Lowest' },
];

function ItemCard({ item, onRefresh, onChange }: ItemCardProps) {
  const { isProductScoutWWLeader, isProductScoutGeoLeader, isMarketScout } = useUserContext();
  const { managedTags, classificationThemes, outlooks } = useCustomFieldsContext();
  const { addToast } = useToastContext();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddIdeaToClusterDialogOpen, setIsAddIdeaToClusterDialogOpen] = useState(false);
  const [isRemovingIdeaFromCluster, setIsRemovingIdeaFromCluster] = useState(false);

  const [newIssueTrackerInclusion, setNewIssueTrackerInclusion] = useState(item.issue_tracker_inclusion);
  const [newOutlook, setNewOutlook] = useState(item.outlook);
  const [newClassificationThemes, setNewClassificationThemes] = useState(item.classification_themes);
  const [newManagedTags, setNewManagedTags] = useState(item.ibm_wide_managed_tags);
  const [newImpactData, setNewImpactData] = useState(item.impact_statement);
  const [newStatusData, setNewStatusData] = useState(item.status_synopsis);
  const [newRankingAssessment, setNewRankingAssessment] = useState(item.ranking_assessment);

  const [rejectionReason, setRejectionReason] = useState('');
  const [isRejectReasonDialogOpen, setIsRejectReasonDialogOpen] = useState(false);

  const selectedRankOption = RANK_OPTIONS.find((r) => r.value === newRankingAssessment);

  const clusterIdeaRefs = [item.reference, ...item.related_ideas.map((i) => i.idea_reference)];

  let inclusionStates: IssueTrackerInclusionState[] = [];

  if (isProductScoutWWLeader) {
    inclusionStates = ALLOWED_INCLUSION_STATES_WW;
  } else if (isProductScoutGeoLeader) {
    inclusionStates = ALLOWED_INCLUSION_STATES_GEO;
  }

  const onEdit = () => {
    // When edit it clicked, set all the textboxes and dropdowns to current idea data
    setNewIssueTrackerInclusion(item.issue_tracker_inclusion);
    setNewOutlook(item.outlook);
    setNewClassificationThemes(item.classification_themes);
    setNewManagedTags(item.ibm_wide_managed_tags);
    setNewImpactData(item.impact_statement);
    setNewStatusData(item.status_synopsis);
    setNewRankingAssessment(item.ranking_assessment);

    setIsEditing(true);
  };

  const doSave = async () => {
    setIsSaving(true);

    const updateData: IdeaUpdate = {
      classification_themes: newClassificationThemes,
      ibm_wide_managed_tags: newManagedTags,
    };

    if (isProductScoutWWLeader || isProductScoutGeoLeader || isMarketScout) {
      updateData.impact_statement = newImpactData;
    }

    if ((isProductScoutWWLeader || isProductScoutGeoLeader) && (newIssueTrackerInclusion !== item.issue_tracker_inclusion)) {
      updateData.issue_tracker_inclusion = newIssueTrackerInclusion;

      if (ISSUE_TRACKER_REJECTED_STATES.includes(newIssueTrackerInclusion)) {
        updateData.issue_tracker_rejection_reason = rejectionReason;
      }
    }

    if (isProductScoutWWLeader) {
      updateData.outlook = newOutlook;
      updateData.status_synopsis = newStatusData;

      if (newRankingAssessment) {
        updateData.ranking_assessment = newRankingAssessment;
      }
    }

    try {
      await updateIdea(item.id, updateData);
      setIsEditing(false);

      if (newIssueTrackerInclusion !== item.issue_tracker_inclusion) {
        item.issue_tracker_inclusion = newIssueTrackerInclusion;
        onRefresh();
      } else if (newRankingAssessment !== item.ranking_assessment) {
        item.ranking_assessment = newRankingAssessment;
        onRefresh();
        return;
      } else {
        onChange((prevItem) => ({
          ...prevItem,
          ...updateData
        }));
      }
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to update idea',
        message: 'Something went wrong, please try again later'
      });
    }

    setIsSaving(false);
  };

  const onSave = async () => {
    // If the inclusion has changed, and it is now a rejected state, show the reject reason dialog
    if (newIssueTrackerInclusion !== item.issue_tracker_inclusion && ISSUE_TRACKER_REJECTED_STATES.includes(newIssueTrackerInclusion)) {
      setIsRejectReasonDialogOpen(true);
    } else {
      doSave();
    }
  };

  const onRemoveFromCluster = async () => {
    setIsRemovingIdeaFromCluster(true);
    try {
      await removeIdeaFromCluster(item.related_cluster_uuid, item.id);
      addToast({
        kind: 'success',
        title: `Idea ${item.reference} has been removed from the cluster`
      });
      onRefresh();
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove idea from cluster',
        message: 'Something went wrong, please try again later'
      });
    }
    setIsRemovingIdeaFromCluster(false);
  };

  let categoryElements: JSX.Element[] = [];

  if (item.product_categories && item.product_categories.length !== 0) {
    categoryElements = item.product_categories.slice(0, 3).map((category) => (
      <span>{category.name}</span>
    ));
  }

  const visibilityTooltipText = getVisibilityText(item.visibility, item.requester_email, item.is_requester_creator);

  return (
    <div id={item.reference} className={styles.itemCard}>
      <AddIdeaToClusterDialog
        itemId={item.id}
        clusterIdeaRefs={clusterIdeaRefs}
        isOpen={isAddIdeaToClusterDialogOpen}
        onClose={() => setIsAddIdeaToClusterDialogOpen(false)}
        onSubmit={() => onRefresh()}
      />
      <RejectReasonDialog
        isOpen={isRejectReasonDialogOpen}
        rejectionReason={rejectionReason}
        onRejectionReasonChange={setRejectionReason}
        onClose={() => setIsRejectReasonDialogOpen(false)}
        onSubmit={() => doSave()}
      />
      <div className={styles.header}>
        <h4>
          {visibilityTooltipText && (
            <TooltipIcon className={styles.visibilityIcon} tooltipText={visibilityTooltipText} align="start"><ViewOff /></TooltipIcon>
          )}
          <Link to={`/ideas/${item.reference}`}>
            {item.name}
          </Link>
        </h4>
        <div className={styles.headerActions}>
          { isRemovingIdeaFromCluster ? (
            <InlineLoading />
          ) : (
            <OverflowMenu
              menuOptionsClass={styles.actionsMenu}
              flipped
              renderIcon={OverflowMenuVertical}
              size="sm"
            >
              <OverflowMenuItem itemText="Edit" disabled={isEditing} onClick={onEdit} />
              {isProductScoutWWLeader ? (
                <OverflowMenuItem itemText="Add idea to cluster" onClick={() => setIsAddIdeaToClusterDialogOpen(true)} />
              ) : null}
              {(isProductScoutWWLeader && item.related_cluster_uuid && item.related_cluster_uuid !== '') ? (
                <OverflowMenuItem itemText="Remove idea from cluster" onClick={() => onRemoveFromCluster()} />
              ) : null}
            </OverflowMenu>
          )}
        </div>
      </div>
      <div className={styles.cols}>
        <div className={styles.col}>
          <table className={styles.meta}>
            <tbody>
              <tr>
                <th>Product:</th>
                <td>{item.product}</td>
              </tr>
              {categoryElements.length !== 0 && (
              <tr>
                <th>Category</th>
                <td>{categoryElements}</td>
              </tr>
              )}
              <tr>
                <th>Submission Date:</th>
                <td>{formatDate(item.created_at)}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{item.workflow_state}</td>
              </tr>
              <tr>
                <th>Issue Tracker Inclusion:</th>
                <td>
                  {((isProductScoutWWLeader || isProductScoutGeoLeader) && isEditing && inclusionStates.includes(newIssueTrackerInclusion)) ? (
                    <Dropdown
                      id="issue-tracker-inclusion-dropdown"
                      label="Issue Tracker Inclusion"
                      items={inclusionStates}
                      selectedItem={newIssueTrackerInclusion}
                      onChange={({ selectedItem }) => setNewIssueTrackerInclusion(selectedItem || IssueTrackerInclusionState.UNSET)}
                      size="sm"
                      disabled={isSaving}
                    />
                  ) : (item.issue_tracker_inclusion)}
                </td>
              </tr>
              {item.issue_tracker_approval_date && (
                <tr>
                  <th>Approved Date:</th>
                  <td>{formatDate(item.issue_tracker_approval_date)}</td>
                </tr>
              )}
              <tr>
                <th>Outlook:</th>
                <td>
                  {(isProductScoutWWLeader && isEditing) ? (
                    <Dropdown
                      id="outlook-dropdown"
                      label="Outlook"
                      items={outlooks}
                      selectedItem={newOutlook}
                      onChange={({ selectedItem: selectedOutlook }) => setNewOutlook(selectedOutlook || '')}
                      size="sm"
                      disabled={isSaving}
                    />
                  ) : (item.outlook || 'Outlook TBD')}
                </td>
              </tr>
              <tr>
                <th>Classification Theme:</th>
                <td>
                  {isEditing ? (
                    <div className={styles.classificationThemesDropdownContainer}>
                      <MultiSelect
                        id="classification-theme-dropdown"
                        label="Themes"
                        items={classificationThemes}
                        itemToString={(dropdownItem) => dropdownItem}
                        selectedItems={newClassificationThemes}
                        onChange={({ selectedItems }) => setNewClassificationThemes(selectedItems)}
                        size="sm"
                        hideLabel
                        disabled={isSaving}
                      />
                    </div>
                  ) : (
                    item.classification_themes.length > 0 ? item.classification_themes.join(', ') : '')}
                </td>
              </tr>
              <tr>
                <th>Managed Tags:</th>
                <td>
                  {isEditing ? (
                    <div className={styles.managedTagsDropdownContainer}>
                      <MultiSelect
                        id="managed-tags-dropdown"
                        label="Tags"
                        items={managedTags}
                        itemToString={(dropdownItem) => dropdownItem}
                        selectedItems={newManagedTags}
                        onChange={({ selectedItems }) => setNewManagedTags(selectedItems)}
                        size="sm"
                        hideLabel
                        disabled={isSaving}
                      />
                    </div>
                  ) : (
                    item.ibm_wide_managed_tags.length > 0 ? (
                      <>
                        {item.ibm_wide_managed_tags.sort().map((managedTag) => (
                          <div>{managedTag}</div>
                        ))}
                      </>
                    ) : ''
                  )}
                </td>
              </tr>
              <tr>
                <th>Ranking Assessment:</th>
                <td>
                  {(isProductScoutWWLeader && isEditing) ? (
                    <Dropdown
                      id="rankingAssessment-dropdown"
                      label="Rank"
                      items={RANK_OPTIONS}
                      itemToString={(dropdownItem) => dropdownItem.label}
                      selectedItem={selectedRankOption}
                      onChange={({ selectedItem }) => selectedItem && setNewRankingAssessment(selectedItem.value)}
                      size="sm"
                      disabled={isSaving}
                    />
                  ) : (item.ranking_assessment || '')}
                </td>
              </tr>
              <tr>
                <th>Aha ID:</th>
                <td>{item.reference}</td>
              </tr>
            </tbody>
          </table>

          {item.issue_tracker_inclusion_requester && (
            <p className={styles.metaExtra}>
              <strong>Issue Tracker Requester:</strong><br />
              {item.issue_tracker_inclusion_requester}
            </p>
          )}

          {item.url && (
            <p><a className={styles.itemLink} href={item.url} target="_blank" rel="noopener noreferrer">See this idea in Aha! <Launch /></a></p>
          )}
        </div>
        <div className={styles.col}>
          <h5>Impact</h5>
          {(isProductScoutWWLeader || isProductScoutGeoLeader || isMarketScout) ? (
            isEditing ? (
              <RichTextEditor data={newImpactData} onChange={setNewImpactData} disabled={isSaving} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.impact_statement) }} />
            )
          ) : (
            <div className={styles.redacted}>This field has been redacted as it may contain IBM Confidential information</div>
          )}
        </div>
        <div className={styles.col}>
          <h5>Current Status</h5>
          { (isProductScoutWWLeader && isEditing) ? (
            <RichTextEditor data={newStatusData} onChange={setNewStatusData} disabled={isSaving} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.status_synopsis) }} />
          )}
        </div>
      </div>
      {(isEditing || isSaving) && (
        <div className={styles.actionButtons}>
          <Button size="sm" kind="secondary" onClick={() => setIsEditing(false)} disabled={isSaving}>Cancel</Button>
          <ButtonSpinner icon={Save} onClick={onSave} isLoading={isSaving} loadingText="Saving...">Save</ButtonSpinner>
        </div>
      )}
    </div>
  );
}

export default ItemCard;
