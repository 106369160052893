import { useEffect, useMemo, useState } from 'react';
import Tag from '../../../../../components/Tag/Tag';
import { IdeaProduct, IdeaProductCategory } from '../../../../../models/product';
import styles from './IssueTrackerFilteringBy.module.css';
import { useIssueTrackerFiltersContext } from '../../../context/IssueTrackerFiltersContext';
import { formatDateRange } from '../../../../../utils/dates';
import { MANAGED_TAG_SETS, FINAL_BIG_10_TAG, createItemList, filterManagedTags } from '../../../../../utils/managedTags';

interface IssueTrackerFilteringByProps {
  products: IdeaProduct[];
}

function IssueTrackerFilteringBy({ products }: IssueTrackerFilteringByProps) {
  const { filters, setFilters } = useIssueTrackerFiltersContext();

  const currentFilters: React.ReactNode[] = [];
  const [selectedProducts, setSelectedProducts] = useState<IdeaProduct[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<IdeaProductCategory[]>([]);
  const productCat = products;

  const categories = useMemo(
    () => {
      const items: IdeaProductCategory[] = [];

      for (const product of productCat) {
        for (const category of product.categories) {
          items.push({
            product_id: product.id,
            product_name: product.name,
            id: category.id,
            name: category.name,
            idea_count: category.idea_count,
          });
        }
      }

      return items;
    },
    [products]
  );

  useEffect(() => {
    if (!filters.productIds) {
      // If there is no product filter, clear the selected products
      setSelectedProducts([]);
      return;
    }

    // For each filtered product ID
    const newProducts = filters.productIds.map((productId) => {
      // Try and find the product in the list of products
      const product = products.find((p) => p.id === productId);
      if (product) return product;

      // If the product was not found, create a fake product so that the filter still works
      return { id: productId, name: `Product ID: ${productId}`, idea_count: 0, prefix: '', categories: [] };
    });

    setSelectedProducts(newProducts);
  }, [products, filters.productIds]);

  useEffect(() => {
    if (!filters.categoryIds) {
      // If there is no category filter, clear the selected categories
      setSelectedCategories([]);
      return;
    }

    // For each filtered category ID
    const newCategory = filters.categoryIds.map((categoryId) => {
      // Try and find the category in the list of categories
      const category = categories.find((p) => p.id === categoryId);
      if (category) return category;

      // If the category was not found, create a fake category so that the filter still works
      return { id: categoryId, name: `Category ID: ${categoryId}`, idea_count: 0, prefix: '', categories: [] };
    });

    setSelectedCategories(newCategory);
  }, [categories, filters.categoryIds]);

  const removeFilterProduct = (productId: string) => {
    const newProductIds = filters.productIds.filter((pId) => pId !== productId);
    setFilters((prevFilters) => ({
      ...prevFilters,
      productIds: newProductIds
    }));
  };

  const removeFilterCategory = (categoryId: string) => {
    const newFilterIds = (filters.categoryIds.filter((cId) => cId !== categoryId));
    setFilters((prevFilters) => ({
      ...prevFilters,
      categoryIds: newFilterIds
    }));
  };

  const removeFilterOutlook = (outlook: string) => {
    const newOutlooks = filters.outlooks.filter((o) => o !== outlook);
    setFilters((prevFilters) => ({
      ...prevFilters,
      outlooks: newOutlooks
    }));
  };

  const removeFilterClassificationTheme = (theme: string) => {
    const newClassificationThemes = filters.classificationThemes.filter((t) => t !== theme);
    setFilters((prevFilters) => ({
      ...prevFilters,
      classificationThemes: newClassificationThemes
    }));
  };

  const removeFilterIBMManagedTag = (tag: string) => {
    const newFilters = filters.ibmWideManagedTags.filter((t) => t !== tag);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ibmWideManagedTags: newFilters
    }));
  };

  const removeApprovedDate = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      approvedFrom: null,
      approvedTo: null
    }));
  };

  if (selectedProducts.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Product:</span>
        <div className={styles.filterTypeTags}>
          {selectedProducts.map((product) => (
            <Tag
              key={product.id}
              name={product.name}
              colour="#EEE"
              onRemove={() => removeFilterProduct(product.id)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (selectedCategories.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Categories:</span>
        <div className={styles.filterTypeTags}>
          {selectedCategories.map((category) => (
            <Tag
              key={category.id}
              name={`${category.product_name ? `${category.product_name} » ` : ''}${category.name}`}
              colour="#EEE"
              onRemove={() => removeFilterCategory(category.id)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (filters.outlooks.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Outlook:</span>
        <div className={styles.filterTypeTags}>
          {filters.outlooks.map((outlook) => (
            <Tag
              key={outlook}
              name={outlook}
              colour="#EEE"
              onRemove={() => removeFilterOutlook(outlook)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  if (filters.classificationThemes.length > 0) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Classification Theme:</span>
        <div className={styles.filterTypeTags}>
          {filters.classificationThemes.map((theme) => (
            <Tag
              key={theme}
              name={theme}
              colour="#EEE"
              onRemove={() => removeFilterClassificationTheme(theme)}
              removeTooltip="Remove filter"
            />
          ))}
        </div>
      </div>
    );
  }

  MANAGED_TAG_SETS.forEach((managedTagSet) => {
    const tags = filterManagedTags(filters.ibmWideManagedTags, managedTagSet.prefix);
    if (tags.length > 0) {
      const items = createItemList(tags, managedTagSet);
      currentFilters.push(
        <div className={styles.filterType}>
          <span className={styles.filterTypeLabel}>{managedTagSet.label}:</span>
          <div className={styles.filterTypeTags}>
            {items.map((item) => (
              <Tag
                key={item.key}
                name={item.label}
                colour="#EEE"
                onRemove={() => removeFilterIBMManagedTag(item.key)}
                removeTooltip="Remove filter"
              />
            ))}
          </div>
        </div>
      );
    }
  });

  if (filters.approvedFrom && filters.approvedTo) {
    const dateRangeStr = formatDateRange(filters.approvedFrom, filters.approvedTo);

    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Approved Date:</span>
        <div className={styles.filterTypeTags}>
          <Tag
            name={dateRangeStr}
            colour="#EEE"
            onRemove={() => removeApprovedDate()}
            removeTooltip="Remove filter"
          />
        </div>
      </div>
    );
  }

  if (filters.ibmWideManagedTags.length > 0 && filters.ibmWideManagedTags.includes(FINAL_BIG_10_TAG)) {
    currentFilters.push(
      <div className={styles.filterType}>
        <span className={styles.filterTypeLabel}>Pilot:</span>
        <div className={styles.filterTypeTags}>
          <Tag
            key={FINAL_BIG_10_TAG}
            name={FINAL_BIG_10_TAG}
            colour="#EEE"
            onRemove={() => removeFilterIBMManagedTag(FINAL_BIG_10_TAG)}
            removeTooltip="Remove filter"
          />
        </div>
      </div>
    );
  }

  if (currentFilters.length === 0) return null;

  return (
    <div className={styles.IssueTrackerFilteringBy}>
      <span className={styles.filtersLabel}>Filters</span>
      <div className={styles.filters}>
        {currentFilters}
      </div>
    </div>
  );
}

export default IssueTrackerFilteringBy;
