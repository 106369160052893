import { Button, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, RadioButton, RadioButtonGroup, TextInput } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useGroupsContext } from '../../../context/GroupsContext';
import { useToastContext } from '../../../context/ToastContext';
import { NewTag } from '../../../models/tag';
import { addNewTagToIdeas, addTagToIdeas } from '../../../utils/api';
import ColourPicker from '../../ColourPicker/ColourPicker';
import Tag from '../../Tag/Tag';
import styles from './MultiAddTagDialog.module.css';

interface MultiAddTagDialogProps {
  selectedIdeaIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function MultiAddTagDialog({ selectedIdeaIds, isOpen, onClose, onSubmit }: MultiAddTagDialogProps) {
  const { addToast } = useToastContext();
  const { currentGroup, currentGroupTags, getNextTagColour, addGroupTag, fetchCurrentGroupTags } = useGroupsContext();

  const [ideaIds, setIdeaIds] = useState<string[]>([]);
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColour, setNewTagColour] = useState(getNextTagColour());

  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedTag = currentGroupTags.find((t) => t.id === selectedTagId);

  const newTag: NewTag = {
    name: newTagName,
    colour: newTagColour
  };

  useEffect(() => {
    if (isOpen) {
      // Reset selected tag ID and idea IDs when opening the dialog
      setSelectedTagId(null);
      setIdeaIds([...selectedIdeaIds]);

      // Reset new tag form
      setNewTagName('');
      setNewTagColour(getNextTagColour());
    }
  }, [isOpen]);

  const addTagToSelectedIdeas = async () => {
    if (!currentGroup || !selectedTagId) return;

    const tagToAdd = selectedTagId === 'new' ? newTag : selectedTag;
    if (!tagToAdd) return;

    setIsSubmitting(true);

    try {
      if (selectedTagId === 'new') {
        const { data } = await addNewTagToIdeas(currentGroup.id, ideaIds, tagToAdd);
        addGroupTag(data);
      } else {
        await addTagToIdeas(currentGroup.id, ideaIds, selectedTagId);
      }
      await fetchCurrentGroupTags();

      addToast({
        kind: 'success',
        title: <>Tag <em>{tagToAdd.name}</em> has been added to {ideaIds.length} {ideaIds.length === 1 ? 'idea' : 'ideas'}</>
      });
      onSubmit();
      onClose();
    } catch {
      addToast({
        kind: 'error',
        title: <>Failed to add tag <em>{tagToAdd.name}</em> to {ideaIds.length} {ideaIds.length === 1 ? 'idea' : 'ideas'}</>,
        message: 'Something went wrong, please try again later'
      });
    }

    setIsSubmitting(false);
  };

  if (!currentGroupTags) return null;

  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.multiAddTagDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title={`Add a tag to ${ideaIds.length} ${ideaIds.length === 1 ? 'idea' : 'ideas'}`} />
      <ModalBody>
        <RadioButtonGroup
          className={styles.radioButtonGroup}
          name="group-radio-group"
          onChange={(tagId: string) => setSelectedTagId(tagId)}
          orientation="vertical"
          valueSelected={selectedTagId || undefined}
        >
          {currentGroupTags.map((tag) => (
            <RadioButton
              key={tag.id}
              labelText={(
                <Tag name={tag.name} colour={tag.colour} onClick={() => setSelectedTagId(tag.id)} />
              )}
              value={tag.id}
              id={tag.id}
              onChange={() => setSelectedTagId(tag.id)}
            />
          ))}
          <RadioButton
            labelText={(
              <div className={styles.formRow}>
                <TextInput
                  id="new-tag-name"
                  className={styles.nameInput}
                  labelText=""
                  placeholder="New tag name"
                  autoComplete="off"
                  size="sm"
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  onClick={() => setSelectedTagId('new')}
                  disabled={isSubmitting}
                  maxLength={50}
                />
                <ColourPicker selectedColour={newTagColour} onChange={setNewTagColour} />
              </div>
            )}
            value="new"
            id="new-tag"
            onChange={() => setSelectedTagId('new')}
          />
        </RadioButtonGroup>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" disabled={isSubmitting} onClick={onClose}>Cancel</Button>
        <div className={styles.submitContainer}>
          {isSubmitting ? (
            <InlineLoading
              className={styles.submitLoading}
              description={ideaIds.length === 1 ? 'Adding tag to idea...' : `Adding tag to ${ideaIds.length} ideas...`}
            />
          ) : (
            <Button
              kind="primary"
              onClick={addTagToSelectedIdeas}
              disabled={selectedTagId === null}
            >
              {ideaIds.length === 1 ? 'Add tag to idea' : `Add tag to ${ideaIds.length} ideas`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>,
    document.body
  );
}

export default MultiAddTagDialog;
