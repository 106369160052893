import { useEffect, useState } from 'react';
import { IssueTrackerItem } from '../../models/issueTracker';
import { IdeaProduct } from '../../../../models/product';
import { getIssueTrackerItems, getIssueTrackerItemsProducts } from '../../../../utils/api';
import IssueTrackerFilteringBy from './IssueTrackerFilteringBy/IssueTrackerFilteringBy';
import IssueTrackerFilters from './IssueTrackerFilters/IssueTrackerFilters';
import IssueTrackerListingItems from './IssueTrackerListingItems';
import { useIssueTrackerFiltersContext } from '../../context/IssueTrackerFiltersContext';
import useHash from '../../../../hooks/useHash';
import { IssueTrackerInclusionState } from '../../../../utils/issueTrackerInclusionStates';

interface IssueTrackerListingProps {
  parentId: string;
  inclusionStatuses: IssueTrackerInclusionState[];
  hideBig10Filter?: boolean;
  hideApprovedDateFilter?: boolean;
}

function IssueTrackerListing({ parentId, inclusionStatuses, hideBig10Filter = false, hideApprovedDateFilter = false }: IssueTrackerListingProps) {
  const hash = useHash();
  const { filters } = useIssueTrackerFiltersContext();

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<IssueTrackerItem[]>([]);
  const [prods, setProds] = useState<IdeaProduct[]>([]);

  const getItems = () => {
    setIsLoading(true);
    const abortController = new AbortController();
    getIssueTrackerItems(parentId, inclusionStatuses, filters, abortController)
      .then(({ data }) => {
        setItems(data);
        setIsLoading(false);
      });
    return () => abortController.abort();
  };

  const getProducts = () => {
    const abortController = new AbortController();
    getIssueTrackerItemsProducts(parentId, abortController)
      .then(({ data }) => {
        setProds(data);
      });
    return () => abortController.abort();
  };

  const onItemChange = (itemId: string, updateItemFn: (prevItem: IssueTrackerItem) => IssueTrackerItem) => {
    // Update the item state for the changed itemId, leave all other items as they are
    setItems((prevItems) => prevItems.map((prevItem) => {
      if (prevItem.id === itemId) {
        return updateItemFn(prevItem);
      }
      return prevItem;
    }));
  };

  useEffect(getProducts, [parentId, inclusionStatuses, filters]);
  useEffect(getItems, [parentId, inclusionStatuses, filters]);

  // Scroll to card if hash changes or items change
  useEffect(() => {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView();
    }
  }, [hash, items]);

  return (
    <>
      <IssueTrackerFilters
        products={prods}
        hideBig10Filter={hideBig10Filter}
        hideApprovedDateFilter={hideApprovedDateFilter}
      />
      <IssueTrackerFilteringBy products={prods} />
      <IssueTrackerListingItems
        isLoading={isLoading}
        items={items}
        onRefresh={() => getItems()}
        onItemChange={onItemChange}
      />
    </>
  );
}

export default IssueTrackerListing;
