// Basic features that every editor should enable.
import { Clipboard } from '@ckeditor/ckeditor5-clipboard';
import { Enter, ShiftEnter } from '@ckeditor/ckeditor5-enter';
import { Typing } from '@ckeditor/ckeditor5-typing';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { UndoEditing } from '@ckeditor/ckeditor5-undo';

// Basic features associated with the edited content.
import { BoldEditing, ItalicEditing, UnderlineEditing, Strikethrough } from '@ckeditor/ckeditor5-basic-styles';

import { List, TodoList } from '@ckeditor/ckeditor5-list';
import { Font } from '@ckeditor/ckeditor5-font';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { Link, AutoLink } from '@ckeditor/ckeditor5-link';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';

import { useEffect, useRef, useState } from 'react';
import { Editor } from '@ckeditor/ckeditor5-core';
import { Button, OverflowMenu, TextInput } from 'carbon-components-react';
import { TextBold, TextItalic, TextUnderline, TextStrikethrough, ListNumbered, ListBulleted, CheckboxChecked,
  TextAlignLeft, TextAlignCenter, TextAlignRight, TextAlignJustify, Code, Link as LinkIcon } from '@carbon/icons-react';
import CarbonEditor from './CarbonEditor';

import styles from './RichTextEditor.module.css';

interface ToolbarButtonProps {
  name: string;
  icon: any;
  disabled: boolean;
  isActive: boolean;
  onClick: () => void;
  label?: string;
}

function ToolbarButton({ name, icon, disabled, isActive, onClick, label }: ToolbarButtonProps) {
  const buttonClasses = `${isActive ? styles.active : ''} ${label ? styles.groupButton : ''} ${label ? 'bx--overflow-menu-options__btn' : ''}`;
  return (
    <Button
      hasIconOnly
      renderIcon={icon}
      iconDescription={name}
      tooltipAlignment="center"
      tooltipPosition="top"
      size="field"
      kind="ghost"
      className={buttonClasses}
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClick}
      disabled={disabled}
    >
      {label && (<p>{label}</p>)}
    </Button>
  );
}

interface RichTextEditorProps {
  className?: string;
  data: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (newData: string) => void;
}

function RichTextEditor({ className, data, placeholder = '', disabled = false, onChange }: RichTextEditorProps) {
  const editorEl = useRef<HTMLDivElement>(null);
  const editorUiEl = useRef<HTMLDivElement>(null);

  const isInitialised = useRef(false);
  const [editor, setEditor] = useState<Editor>();
  const [tick, setTick] = useState(0);
  const [linkURL, setLinkURL] = useState('');

  const onChangeRef = useRef<(() => void) | null>(null);

  const loadingLock = Symbol('loading-lock');

  useEffect(() => {
    if (!isInitialised.current && editorEl.current && editorUiEl.current) {
      isInitialised.current = true;

      CarbonEditor.create(editorEl.current, {
        plugins: [
          Clipboard, Enter, ShiftEnter, Typing, Paragraph,
          BoldEditing, ItalicEditing, UnderlineEditing, UndoEditing,
          Strikethrough, Font, List, TodoList, Indent, IndentBlock, Alignment,
          CodeBlock, Link, AutoLink, GeneralHtmlSupport,
        ],
        htmlSupport: {
          allow: [{ name: /^(table|tbody|tr|td|th|thead)$/ }],
        },
        uiElement: editorUiEl.current,
        placeholder
      }).then((instance) => {
        instance.data.set(data);
        instance.model.document.on('change', () => setTick(Date.now()));
        setEditor(instance);
      });
    }
  }, [editorEl, editorUiEl]);

  useEffect(() => {
    if (editor && (editor.data.get() !== data)) {
      editor.data.set(data);
    }
  }, [data]);

  useEffect(() => {
    if (editor) {
      // Unregister the last onChange function (if one exists)
      if (onChangeRef.current) {
        editor.model.document.off('change:data', onChangeRef.current);
      }

      // Register the new onChange function
      onChangeRef.current = () => onChange(editor.data.get());
      editor.model.document.on('change:data', onChangeRef.current);
    }
  }, [editor, onChange]);

  const getCommand = (commandName: string) => editor?.commands.get(commandName);

  useEffect(() => {
    if (editor) {
      (editor as any).enableReadOnlyMode(loadingLock);
      setTick(Date.now());
    }
  }, [disabled]);

  return (
    <div className={className}>
      <div ref={editorUiEl} data-tick={tick} className={styles.editorUI}>
        <ToolbarButton
          name="Bold"
          icon={TextBold}
          isActive={getCommand('bold')?.value as boolean}
          onClick={() => editor?.execute('bold')}
          disabled={!getCommand('bold')?.isEnabled}
        />
        <ToolbarButton
          name="Italic"
          icon={TextItalic}
          isActive={getCommand('italic')?.value as boolean}
          onClick={() => editor?.execute('italic')}
          disabled={!getCommand('italic')?.isEnabled}
        />
        <ToolbarButton
          name="Underline"
          icon={TextUnderline}
          isActive={getCommand('underline')?.value as boolean}
          onClick={() => editor?.execute('underline')}
          disabled={!getCommand('underline')?.isEnabled}
        />
        <ToolbarButton
          name="Strike through"
          icon={TextStrikethrough}
          isActive={getCommand('strikethrough')?.value as boolean}
          onClick={() => editor?.execute('strikethrough')}
          disabled={!getCommand('strikethrough')?.isEnabled}
        />
        <ToolbarButton
          name="Code Block"
          icon={Code}
          isActive={getCommand('codeBlock')?.value as boolean}
          onClick={() => editor?.execute('codeBlock')}
          disabled={!getCommand('codeBlock')?.isEnabled}
        />
        <OverflowMenu
          ariaLabel="List options"
          className="bx--tooltip__trigger bx--tooltip--icon__top"
          renderIcon={() => <ListBulleted />}
          iconDescription="List options"
          size="md"
        >
          <ToolbarButton
            name="Numbered List"
            label="Numbered List"
            icon={ListNumbered}
            isActive={getCommand('numberedList')?.value as boolean}
            onClick={() => editor?.execute('numberedList')}
            disabled={!getCommand('numberedList')?.isEnabled}
          />
          <ToolbarButton
            name="Bulleted List"
            label="Bulleted List"
            icon={ListBulleted}
            isActive={getCommand('bulletedList')?.value as boolean}
            onClick={() => editor?.execute('bulletedList')}
            disabled={!getCommand('bulletedList')?.isEnabled}
          />
          <ToolbarButton
            name="Check Box"
            label="Check Box"
            icon={CheckboxChecked}
            isActive={getCommand('todoList')?.value as boolean}
            onClick={() => editor?.execute('todoList')}
            disabled={!getCommand('todoList')?.isEnabled}
          />
        </OverflowMenu>
        <OverflowMenu
          ariaLabel="Alignment options"
          className="bx--tooltip__trigger bx--tooltip--icon__top"
          renderIcon={() => <TextAlignLeft />}
          iconDescription="Alignment options"
          size="md"
        >
          <ToolbarButton
            name="Align Left"
            label="Align Left"
            icon={TextAlignLeft}
            isActive={getCommand('alignment')?.value === 'left'}
            onClick={() => editor?.execute('alignment', { value: 'left' })}
            disabled={!getCommand('alignment')?.isEnabled}
          />
          <ToolbarButton
            name="Align Center"
            label="Align Center"
            icon={TextAlignCenter}
            isActive={getCommand('alignment')?.value === 'center'}
            onClick={() => editor?.execute('alignment', { value: 'center' })}
            disabled={!getCommand('alignment')?.isEnabled}
          />
          <ToolbarButton
            name="Align Right"
            label="Align Right"
            icon={TextAlignRight}
            isActive={getCommand('alignment')?.value === 'right'}
            onClick={() => editor?.execute('alignment', { value: 'right' })}
            disabled={!getCommand('alignment')?.isEnabled}
          />
          <ToolbarButton
            name="Justify"
            label="Justify"
            icon={TextAlignJustify}
            isActive={getCommand('alignment')?.value === 'justify'}
            onClick={() => editor?.execute('alignment', { value: 'justify' })}
            disabled={!getCommand('alignment')?.isEnabled}
          />
        </OverflowMenu>
        <OverflowMenu
          ariaLabel="Link"
          className="bx--tooltip__trigger bx--tooltip--icon__top"
          renderIcon={() => <LinkIcon />}
          iconDescription="Link"
          size="md"
        >
          <TextInput
            id="linkInput"
            name="linkInput"
            labelText=""
            placeholder="Enter URL"
            value={linkURL}
            onChange={(e) => { setLinkURL(e.target.value); }}
          />
          <ToolbarButton
            name="Link"
            label="Link"
            icon={LinkIcon}
            isActive={getCommand('link')?.value as boolean}
            onClick={() => editor?.execute('link', linkURL, { addTargetToExternalLinks: true })}
            disabled={!getCommand('link')?.isEnabled || linkURL === ''}
          />
          <ToolbarButton
            name="Unlink"
            label="Unlink"
            icon={LinkIcon}
            isActive={getCommand('unlink')?.value as boolean}
            onClick={() => editor?.execute('unlink')}
            disabled={!getCommand('unlink')?.isEnabled}
          />
        </OverflowMenu>
      </div>
      <div className={`bx--text-area ${styles.textArea}`}>
        <div ref={editorEl} />
      </div>
    </div>
  );
}

export default RichTextEditor;
