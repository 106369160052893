import { DotcomShell } from '@carbon/ibmdotcom-react';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import styles from './Shell.module.css';

interface ShellProps {
  children: React.ReactNode
}

const IBM_IDEAS_TITLE = 'IBM Ideas';
const ISSUE_TRACKER_TITLE = 'Product Scout Issue Tracker';

function Shell({ children }: ShellProps) {
  const location = useLocation();
  const { isIBMEmployee } = useUserContext();

  const selectedMenuItem = location.pathname.startsWith('/issue-tracker') ? ISSUE_TRACKER_TITLE : IBM_IDEAS_TITLE;

  const navigation = [
    {
      title: IBM_IDEAS_TITLE,
      titleEnglish: IBM_IDEAS_TITLE,
      url: '/'
    },
  ];

  if (isIBMEmployee && (process.env.REACT_APP_ENABLE_ISSUE_TRACKER === 'true')) {
    navigation.push({
      title: ISSUE_TRACKER_TITLE,
      titleEnglish: ISSUE_TRACKER_TITLE,
      url: '/issue-tracker'
    });
  }

  return (
    <div className={styles.shell}>
      <DotcomShell
        mastheadProps={{
          selectedMenuItem,
          navigation
        }}
        footerProps={{
          disableLocaleButton: true,
          type: 'micro'
        }}
      >
        <div className={styles.content}>
          {children}
        </div>
      </DotcomShell>
    </div>
  );
}

export default Shell;
