import { useState } from 'react';
import { voteForIdea, unvoteForIdea } from '../utils/api';
import { useUserContext } from '../context/UserContext';
import { useToastContext } from '../context/ToastContext';
import { Idea } from '../models/idea';

interface UseVotesProps {
  idea: Idea;
}

function useVotes({ idea }: UseVotesProps) {
  const { addToast } = useToastContext();
  const [voteCount, setVoteCount] = useState(idea.vote_count);
  const [ibmVoteCount, setIBMVoteCount] = useState(idea.ibm_vote_count);
  const [nonIBMVoteCount, setNonIBMVoteCount] = useState(idea.non_ibm_vote_count);
  const [isVoted, setIsVoted] = useState(idea.is_requester_endorsed);
  const [isVoting, setIsVoting] = useState(false);
  const [voters, setVoters] = useState(idea.idea_votes || []);
  const { user, isIBMEmployee } = useUserContext();
  const initialVotes = idea.initial_votes;
  const userVoteDetails = {
    user_email: user?.email || '',
    user_name: user?.full_name || '',
    vote_created_at: new Date().toISOString(),
    id: '0'
  };

  const updateVoteCount = (difference: number) => {
    setVoteCount((prevCount: number) => prevCount + difference);
    if (isIBMEmployee) {
      setIBMVoteCount((prevCount: number) => prevCount + difference);
    } else {
      setNonIBMVoteCount((prevCount: number) => prevCount + difference);
    }
  };

  const addVote = () => {
    setIsVoted(true);
    updateVoteCount(1);
    setVoters([...voters, userVoteDetails]);
  };

  const removeVote = () => {
    setIsVoted(false);
    updateVoteCount(-1);
    setVoters(voters.filter((voter) => voter.user_email !== userVoteDetails.user_email));
  };

  const onVoteClick = async () => {
    setIsVoting(true);
    addVote();
    try {
      await voteForIdea(idea.id);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to vote for idea',
        message: 'Something went wrong, please try again later',
      });
      removeVote();
    }
    setIsVoting(false);
  };

  const onUnvoteClick = async () => {
    setIsVoting(true);
    removeVote();
    try {
      await unvoteForIdea(idea.id);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove your vote for the idea',
        message: 'Something went wrong, please try again later',
      });
      addVote();
    }
    setIsVoting(false);
  };
  return { onVoteClick, onUnvoteClick, voters, voteCount, ibmVoteCount, nonIBMVoteCount, initialVotes, isVoted, isVoting };
}

export default useVotes;
