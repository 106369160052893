import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useHash() {
  const location = useLocation();
  const hash = useMemo(() => location.hash.slice(1), [location]);
  return hash;
}

export default useHash;
