export enum WorkflowState {
  NOT_UNDER_CONSIDERATION = 'Not under consideration',
  IS_A_DEFECT = 'Is a defect',
  DELIVERED = 'Delivered',
  FUNCTIONALITY_ALREADY_EXISTS = 'Functionality already exists'
}

export const REJECTED_BY_PRODUCT_TEAM_STATES = [
  WorkflowState.NOT_UNDER_CONSIDERATION,
  WorkflowState.IS_A_DEFECT,
];

export const RESOLVED_IDEAS_STATES = [
  WorkflowState.DELIVERED,
  WorkflowState.FUNCTIONALITY_ALREADY_EXISTS,
];

export const WORKFLOW_TOOLTIP_TEXTS: { [key: string]: string } = {
  // eslint-disable-next-line quote-props
  'Submitted': 'This idea is yet to be reviewed by IBM. The requester will receive an update within 30 days regarding the next step.',
  'Under review': 'This idea is being reviewed by IBM and should be completed within 90 days from submission. Further information for this idea may be requested.',
  'Needs more information': 'Additional details are required for this idea. The requester will have been notified. If more information is not provided within 30 days after being placed in this state, the idea may be closed.',
  'Future consideration': 'This idea has been reviewed by IBM and is a possible candidate for a future release of the product, though implementation cannot be committed to at this time.',
  'Planned for future release': 'This idea is a candidate for inclusion in a future release of the product.',
  // eslint-disable-next-line quote-props
  'Delivered': 'This idea has been delivered and closed with this state.',
  'Not under consideration': 'This idea is not considered a candidate for implementation at this time.',
  'Functionality already exists': 'The functionality or capability requested in this idea is already available.',
  'Is a defect': 'This idea has been identified as a defect that needs to be fixed in the product.'
};
