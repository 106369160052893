import { useState, useRef, useEffect } from 'react';
import { Button } from 'carbon-components-react';
import { Add } from '@carbon/icons-react';
import { getCompanyGuess } from '../../utils/api';
import { Company, CompanyIn, IBMCompany } from '../../models/company';
import { useUserContext } from '../../context/UserContext';
import CompanyGuess from './CompanyGuess/CompanyGuess';
import CompanyInputRow from './CompanyInputRow/CompanyInputRow';

interface CompanyInputProps {
  labelText: React.ReactNode,
  onChange: (companies: CompanyIn[]) => void,
  isInvalid: boolean,
}

function CompanyInput({ labelText, onChange, isInvalid }: CompanyInputProps) {
  const { user, isIBMEmployee } = useUserContext();
  const lastCompanyId = useRef(0);

  const getNextCompanyId = () => {
    lastCompanyId.current += 1;
    return lastCompanyId.current;
  };

  const [companyGuess, setCompanyGuess] = useState<IBMCompany | null>(null);
  const [companyItems, setCompanyItems] = useState<{ id: number, company: Company | null }[]>([{ id: getNextCompanyId(), company: null }]);
  const inputIsDirty = useRef(false);

  useEffect(() => {
    const fetchCompanyGuess = async () => {
      const { data } = await getCompanyGuess();
      setCompanyGuess(data);
    };

    if (user) {
      if (user.company) {
        // If the user has an associated company, insert it into the dropdown list and select it
        setCompanyItems([{ id: getNextCompanyId(), company: user.company }]);
      } else if (!isIBMEmployee) {
        // Otherwise, if this is not an IBMer, fetch a company guess
        fetchCompanyGuess();
      }
    }
  }, [user]);

  useEffect(() => {
    const associatedCompanies = companyItems.map((item) => item.company);
    const realAssociatedCompanies = associatedCompanies.filter((company): company is Company => company !== null);

    if (realAssociatedCompanies.length > 0) {
      inputIsDirty.current = true;
    }

    if (inputIsDirty.current) {
      onChange(realAssociatedCompanies);
    }
  }, [companyItems]);

  const removeCompanyItem = (id: number) => {
    setCompanyItems((prevCompanyItems) => {
      const filteredItems = prevCompanyItems.filter((thisCompanyItem) => thisCompanyItem.id !== id);
      if (filteredItems.length === 0) {
        filteredItems.push({ id: getNextCompanyId(), company: null });
      }
      return filteredItems;
    });
  };

  return (
    <>
      <label htmlFor="company" className="bx--label">{labelText}</label>
      {companyGuess ? (
        <CompanyGuess
          guess={companyGuess}
          onYes={() => { setCompanyGuess(null); setCompanyItems([{ id: getNextCompanyId(), company: companyGuess }]); }}
          onNo={() => { setCompanyGuess(null); setCompanyItems([{ id: getNextCompanyId(), company: null }]); }}
        />
      ) : (
        <>
          <div>
            {companyItems.map((companyItem) => (
              <CompanyInputRow
                key={companyItem.id}
                company={companyItem.company}
                onChange={(newValue) => setCompanyItems((prevCompanyItems) => prevCompanyItems.map((c) => (c.id === companyItem.id ? { id: c.id, company: newValue } : c)))}
                onRemove={() => removeCompanyItem(companyItem.id)}
                isInvalid={isInvalid}
                isRemoveDisabled={companyItems.length === 1 && companyItems[0].company === null}
              />
            ))}
          </div>
          {isIBMEmployee && (
            <div>
              <Button
                kind="tertiary"
                size="sm"
                renderIcon={Add}
                onClick={() => setCompanyItems((prevCompanyItems) => [...prevCompanyItems, { id: getNextCompanyId(), company: null }])}
                disabled={companyItems.length === 0 || !companyItems[companyItems.length - 1].company}
              >
                Add another company
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CompanyInput;
