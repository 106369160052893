import ReactDOM from 'react-dom';
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, TextArea } from 'carbon-components-react';
import styles from './RejectReasonDialog.module.css';

interface RejectReasonDialogProps {
  isOpen: boolean;
  rejectionReason: string;
  onRejectionReasonChange: (rejectionReason: string) => void;
  onClose: () => void;
  onSubmit: (rejectionReason: string) => void;
}

function RejectReasonDialog({ isOpen, onClose, onSubmit, rejectionReason, onRejectionReasonChange }: RejectReasonDialogProps) {
  return ReactDOM.createPortal(
    <ComposedModal
      className={styles.RejectReasonDialog}
      open={isOpen}
      onClose={() => onClose()}
      size="sm"
    >
      <ModalHeader title="Reason for rejecting this issue tracker idea" />
      <ModalBody>
        <TextArea
          labelText="Rejection Reason:"
          value={rejectionReason}
          onChange={(e) => onRejectionReasonChange(e.target.value)}
        />
      </ModalBody>
      <ModalFooter
        primaryButtonText="Reject this idea"
        secondaryButtonText="Cancel"
        onRequestSubmit={() => { onClose(); onSubmit(rejectionReason); }}
      />
    </ComposedModal>,
    document.body
  );
}

export default RejectReasonDialog;
