import { View } from '@ckeditor/ckeditor5-engine';
import { EditorUIView, InlineEditableUIView } from '@ckeditor/ckeditor5-ui';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class CarbonEditorUIView extends EditorUIView {
  public readonly editable: InlineEditableUIView;

  constructor(locale: Locale, editingView: View, uiElement: HTMLElement) {
    super(locale);
    this.element = uiElement;
    this.editable = new InlineEditableUIView(locale, editingView);
  }
}
