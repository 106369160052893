import { useEffect, useState } from 'react';
import { InlineNotification, TooltipIcon } from 'carbon-components-react';
import { Close } from '@carbon/icons-react';
import { Company } from '../../../models/company';
import styles from './CompanyInputRow.module.css';
import CompanyInputText from './CompanyInputText';
import CompanyInputComboBox from './CompanyInputComboBox';

interface RemoveBtnProps {
  onClick: () => void,
  disabled: boolean,
}

function RemoveBtn({ onClick, disabled }: RemoveBtnProps) {
  return (
    <TooltipIcon
      className={styles.removeBtn}
      tooltipText="Remove company"
      direction="right"
      onClick={() => onClick()}
      disabled={disabled}
    >
      <Close />
    </TooltipIcon>
  );
}

interface CompanyInputRowProps {
  company: Company | null,
  onChange: (company: Company | null) => void,
  onRemove: () => void,
  isRemoveDisabled: boolean,
  isInvalid: boolean,
}

function CompanyInputRow({ company, onChange, onRemove, isRemoveDisabled, isInvalid }: CompanyInputRowProps) {
  const [selectedCompanyItem, setSelectedCompanyItem] = useState<Company | null>(company);
  const [companySearchError, setCompanySearchError] = useState(false);

  const companyIsText = !!(company && !('ibm_company_id' in company));
  const [showTextbox, setShowTextbox] = useState<boolean>(companyIsText);

  useEffect(() => {
    if (companySearchError) {
      setShowTextbox(true);
    }
  }, [companySearchError]);

  const onCompanyTextChange = (companyStr: string) => {
    if (companyStr) {
      onChange({ company_name: companyStr });
    } else {
      onChange(null);
    }
  };

  const onCompanyComboBoxChange = (c: Company | null) => {
    setSelectedCompanyItem(c);
    if (c) {
      onChange(c);
    } else {
      onChange(null);
    }
  };

  return (
    <div className={styles.CompanyInputRow}>
      <div className={styles.inputRow}>
        {(showTextbox) ? (
          <CompanyInputText
            companyText={company ? company.company_name : ''}
            onChange={onCompanyTextChange}
            isInvalid={isInvalid}
          />
        ) : (
          <CompanyInputComboBox
            company={company || null}
            onChange={onCompanyComboBoxChange}
            isInvalid={isInvalid}
            setError={setCompanySearchError}
          />
        )}
        <RemoveBtn onClick={onRemove} disabled={isRemoveDisabled} />
      </div>
      {companySearchError && (
      <InlineNotification
        className={styles.warningMessage}
        kind="warning"
        title="Failed to find company"
        subtitle="Something went wrong, please enter company name manually"
        lowContrast
      />
      )}
      {(!showTextbox && !selectedCompanyItem) && (
        <button
          type="button"
          className={`bx--link ${styles.canNotFindCompanyLink}`}
          onClick={() => { setShowTextbox(true); onChange(null); }}
        >
          Can&apos;t find your company? Input it manually.
        </button>
      )}
    </div>
  );
}

export default CompanyInputRow;
