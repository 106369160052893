import React, { useEffect, useRef, useState } from 'react';
import { TAG_COLOURS } from '../../context/GroupsContext';
import styles from './ColourPicker.module.css';

interface ColourPickerProps {
  selectedColour: string;
  onChange: (colour: string) => void;
}

function ColourPicker({ selectedColour, onChange }: ColourPickerProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const colourPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onBodyClick = (e: MouseEvent) => {
      // Close the dropdown if clicking outside of the dropdown
      if ((e.target instanceof Element) && colourPickerRef && !colourPickerRef.current?.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={styles.colourPicker} ref={colourPickerRef}>
      <button
        type="button"
        className={styles.colourPickerBtn}
        aria-label="Choose colour"
        style={{ backgroundColor: selectedColour }}
        onClick={() => setIsDropdownOpen((prevOpen) => !prevOpen)}
      />
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          {TAG_COLOURS.map((colour) => (
            <button
              key={colour}
              type="button"
              className={`${styles.colourPickerBtn} ${colour === selectedColour ? styles.selected : ''}`}
              aria-label={colour}
              style={{ backgroundColor: colour }}
              onClick={() => {
                onChange(colour);
                setIsDropdownOpen(false);
              }}
            />
          ))}
        </div>
      )}

    </div>
  );
}

export default ColourPicker;
