import { useEffect } from 'react';
import { InlineLoading } from 'carbon-components-react';
import { useProductsContext } from '../../context/ProductsContext';
import styles from './FollowedProductsConfig.module.css';
import { IdeaProduct } from '../../models/product';
import useAPI from '../../hooks/useAPI';
import { getRecommendedProducts } from '../../utils/api';
import FollowButton from '../../components/FollowButton/FollowButton';

function FollowedProductsConfig() {
  const { followedProductsMenu, isLoadingFollowedProducts, isFollowingProduct } = useProductsContext();
  const raisedProductsAPI = useAPI(getRecommendedProducts);

  useEffect(() => {
    const abortController = new AbortController();

    raisedProductsAPI.execute({
      interaction: 'raised'
    }, abortController);

    return () => abortController.abort();
  }, []);

  const recommendedProducts: IdeaProduct[] = raisedProductsAPI.data ? raisedProductsAPI.data.filter((product) => !isFollowingProduct(product.id)) : [];

  return (
    <div className={styles.followedProducts}>
      <h2>Manage Followed Products</h2>
      <p className={styles.info}>
        <strong>Keep track of new ideas and conversations around your favorite IBM products</strong><br />
        Products that you follow will be added to your sidebar.<br />
        Use the search bar at the top of the page to find and follow products.<br />
      </p>
      <section className={styles.section}>
        <h4>Products you are Following</h4>
        {isLoadingFollowedProducts ? (
          <InlineLoading description="Loading followed products..." />
        ) : (
          followedProductsMenu.length === 0 ? (
            <p>You are not yet following any products.</p>
          ) : (
            <table className={styles.followedProductsTable}>
              {followedProductsMenu.map((product) => (
                <tr key={`${product.id}-${product.idea_category?.id}`} className={product.idea_category ? styles.categoryRow : styles.productRow}>
                  <td>{product.idea_category ? product.idea_category.name : product.name}</td>
                  <td>
                    <FollowButton product={product} />
                  </td>
                </tr>
              ))}
            </table>
          )
        )}
      </section>
      {raisedProductsAPI.state === 'success' && recommendedProducts.length > 0 && (
        <section className={styles.section}>
          <h4>Recommended Products</h4>
          <p>Here is a list of products that you have previously interacted with. Follow them to add them to your sidebar.</p>
          <table className={styles.followedProductsTable}>
            {recommendedProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  <FollowButton product={{ id: product.id, name: product.name, idea_category: null }} />
                </td>
              </tr>
            ))}
          </table>
        </section>
      )}
    </div>
  );
}

export default FollowedProductsConfig;
