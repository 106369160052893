import { Loading } from 'carbon-components-react';
import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
  text?: string;
}

function LoadingSpinner({ text }: LoadingSpinnerProps) {
  return (
    <div className={styles.loading}>
      <Loading className={styles.spinner} withOverlay={false} />
      {text && (
        <div className={styles.text}>{text}</div>
      )}
    </div>
  );
}

export default LoadingSpinner;
