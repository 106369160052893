import { Favorite, FavoriteFilled } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useProductsContext } from '../../context/ProductsContext';
import { FollowProduct } from '../../models/product';
import styles from './FollowButton.module.css';

interface FollowButtonProps {
  product: FollowProduct
}

function FollowButton({ product }: FollowButtonProps) {
  const { isFollowingProduct, followProduct, unfollowProduct } = useProductsContext();
  const isFollowing = product.id ? isFollowingProduct(product.id, product.idea_category?.id || null) : false;

  const onClickFollow = () => {
    if (isFollowing) {
      unfollowProduct(product.id, product.idea_category?.id || null);
    } else {
      followProduct(product);
    }
  };

  return (
    <Button
      className={`${styles.FollowButton} ${isFollowing ? styles.isFollowing : styles.isNotFollowing}`}
      renderIcon={isFollowing ? FavoriteFilled : Favorite}
      size="sm"
      kind={isFollowing ? 'primary' : 'tertiary'}
      onClick={(e) => { e.preventDefault(); onClickFollow(); }}
      aria-label="Unfollow"
    >
      {isFollowing ? 'Following' : 'Follow'}
    </Button>
  );
}

export default FollowButton;
