import { Button, ButtonKind } from 'carbon-components-react';
import { Add, CarbonIconType } from '@carbon/icons-react';
import { NavLink, To, useLocation } from 'react-router-dom';
import { useGroupsContext } from '../../context/GroupsContext';
import styles from './Sidebar.module.css';
import { useProductsContext } from '../../context/ProductsContext';
import { useUserContext } from '../../context/UserContext';

interface NavButtonProps {
  children: React.ReactNode;
  to: To;
  end?: boolean;
  icon?: CarbonIconType;
  kind?: ButtonKind;
  className?: string;
}

export function NavButton({ children, to, end = true, icon, kind = 'ghost', className }: NavButtonProps) {
  return (
    <Button as={NavLink} to={to} end={end} renderIcon={icon} size="sm" kind={kind} className={className}>
      {children}
    </Button>
  );
}

function Sidebar() {
  const { isIBMEmployee } = useUserContext();
  const { groups } = useGroupsContext();
  const location = useLocation();

  const productId = location.pathname.match(/\/products\/(\d+)/);
  const categoryId = location.pathname.match(/\/categories\/(\d+)/);
  const params = new URLSearchParams();
  if (productId) {
    params.append('product', productId[1]);
    if (categoryId) {
      params.append('category', categoryId[1]);
    }
  }
  const newIdeaBtnTo = `/new-idea?${params.toString()}`;

  const { followedProductsMenu } = useProductsContext();

  return (
    <aside aria-label="Sidebar" className={styles.sidebar}>
      <div className={styles.sidebarSection}>
        <NavButton to={newIdeaBtnTo} icon={Add} kind="primary">
          Add new idea
        </NavButton>
      </div>

      <div className={`${styles.sidebarSection} ${styles.interactionSection}`}>
        <ul>
          <li><NavButton to="/">My Ideas</NavButton></li>
          <li><NavButton to="/votes">My Votes</NavButton></li>
          <li><NavButton to="/comments">My Comments</NavButton></li>
          <li><NavButton to="/subscriptions">My Subscriptions</NavButton></li>
          <li><NavButton to="/products">My Followed Products</NavButton></li>
          {followedProductsMenu.map((product) => (
            product.idea_category ? (
              <li key={`${product.id}-${product.idea_category.id}`}>
                <NavButton to={`/products/${product.id}/categories/${product.idea_category.id}`} className={`${styles.groupButton} ${styles.categoryButton}`}>
                  <span className={styles.groupButtonText} title={`${product.name} » ${product.idea_category.name}`}>
                    {product.idea_category.name}
                  </span>
                </NavButton>
              </li>
            ) : (
              <li key={product.id}>
                <NavButton to={`/products/${product.id}`} className={styles.groupButton}>
                  <span className={styles.groupButtonText} title={product.name}>
                    {product.name}
                  </span>
                </NavButton>
              </li>
            )
          ))}
          <li><NavButton to="/groups">My Shared Groups</NavButton></li>
          {groups.map((group) => (
            <li key={group.id}>
              <NavButton to={`/groups/${group.id}`} end={false} className={styles.groupButton}>
                <span className={styles.groupButtonText} title={group.name}>{group.name}</span>
              </NavButton>
            </li>
          ))}
          <li><NavButton to="/search">Search</NavButton></li>
          {isIBMEmployee && (
            <li><NavButton to="/register-companies">Register Companies in Aha!</NavButton></li>
          )}
        </ul>
      </div>

    </aside>
  );
}

export default Sidebar;
